define("careshare/components/discharge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    init: function init() {//this.set('model', this.get('model'));
      //this._super(...arguments);
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    actions: {
      selectValue: function selectValue(gender) {
        this.get('model').set(Ember.$(event.target).attr('model-attr'), gender);
      }
    }
  });

  _exports.default = _default;
});
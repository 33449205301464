define("careshare/routes/after-login/adverse-events/resident-edit", ["exports", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      this.set('redirectToNotifications', transition.queryParams.redirectToNotifications);
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('adverse-event', params.id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
        Ember.$('.ui-tooltip').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.adverse-events.resident-edit').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
        Ember.$('.ui-tooltip').addClass('hidden');
      });
    },
    validate: function validate() {
      var type = Ember.$('.event-type');
      var residents = Ember.$('.resident-select');
      var dateField = Ember.$('.date-picker');
      var timeFields = Ember.$('.time-picker');
      var severityField = Ember.$('.severity');
      var locationField = Ember.$('.location');
      var valid = true;

      if (Ember.isEmpty(type.val())) {
        type.addClass('is-invalid');
        valid = false;
        return;
      } else {
        type.removeClass('is-invalid');
        valid = true;
      }

      residents.each(function (index, el) {
        if (!$(this).val().length) {
          $(this).next().addClass('is-invalid');
          valid = false;
          return;
        } else {
          $(this).next().removeClass('is-invalid');
          valid = true;
        }
      });

      if (Ember.isEmpty(dateField.val())) {
        dateField.parent().addClass('is-invalid');
        valid = false;
        return;
      } else {
        dateField.parent().removeClass('is-invalid');
        valid = true;
      }

      timeFields.each(function (index, el) {
        if (Ember.isEmpty($(this).val())) {
          $(this).addClass('is-invalid');
          valid = false;
          return;
        } else {
          $(this).removeClass('is-invalid');
          valid = true;
        }
      });

      if (Ember.isEmpty(severityField.val())) {
        severityField.addClass('is-invalid');
        valid = false;
        return;
      } else {
        severityField.removeClass('is-invalid');
        valid = true;
      }

      if (Ember.isEmpty(locationField.val())) {
        locationField.addClass('is-invalid');
        valid = false;
        return;
      } else {
        locationField.removeClass('is-invalid');
        valid = true;
      }

      return valid;
    },
    actions: {
      saveEvent: function saveEvent(resident) {
        var self = this;
        var model = this.modelFor(this.routeName);

        if (this.validate()) {
          var formatedRequestData = JSON.stringify(model.serialize());
          var requestOptions = {
            url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/adverse-events/' + model.get('id'),
            dataType: 'JSON',
            method: 'PUT',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            },
            data: formatedRequestData
          };
          var saveDetails = Ember.$.ajax(requestOptions);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(saveDetails).done(function (response) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.transitionTo('after-login.adverse-events.resident-index', resident, {
              queryParams: {
                resident_id: resident
              }
            });
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
            Ember.$('.modal-window.success').removeClass('hidden');
          });
        }
      },
      back: function back(resident) {
        var url = 'after-login.adverse-events.resident-'; //if (+this.get('redirectToNotifications')) {
        //    url = 'after-login.notifications';
        //}

        this.transitionTo('after-login.adverse-events.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      print: function print() {
        var model = this.modelFor(this.routeName);
        $('.forPrint').printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h1>Adverse Event Report for " + model.get('resident') + ", " + Ember.$('.date-picker').val() + "</h1>"
        });
      }
    }
  });

  _exports.default = _default;
});
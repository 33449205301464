define("careshare/models/residentlite", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    full_name: Ember.computed('firstname', 'lastname', function () {
      return "".concat(this.get('firstname'), " ").concat(this.get('lastname'));
    }),
    gender: _emberData.default.attr('string'),
    dob: _emberData.default.attr('date-format'),
    unit: _emberData.default.belongsTo('unit'),
    unit_id: _emberData.default.attr('number'),
    unit_lookup: Ember.computed('unit_id', function () {
      return "".concat(this.get('unit_id'));
    }),
    room: _emberData.default.attr('string'),
    doa: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    discharged: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    admission_type: _emberData.default.attr('string'),
    admitted_from: _emberData.default.attr('string'),
    main_contact: _emberData.default.attr('string'),
    main_contact_phone: _emberData.default.attr('string'),
    key_details_valid: _emberData.default.attr('number'),
    additional_details_valid: _emberData.default.attr('number'),
    contact_details_valid: _emberData.default.attr('number'),
    contact_family_details_valid: _emberData.default.attr('number'),
    communication_details_valid: _emberData.default.attr('number'),
    eol_details_valid: _emberData.default.attr('number'),
    medical_details_valid: _emberData.default.attr('number'),
    communication_eol_medical_details_valid: _emberData.default.attr('number'),
    mobility_details_valid: _emberData.default.attr('number'),
    nutrition_details_valid: _emberData.default.attr('number'),
    reminiscence_details_valid: _emberData.default.attr('number'),
    mobility_nutrition_details_valid: _emberData.default.attr('number'),
    sleeping_details_valid: _emberData.default.attr('number'),
    percare_details_valid: _emberData.default.attr('number'),
    preferences_details_valid: _emberData.default.attr('number'),
    continence_details_valid: _emberData.default.attr('number'),
    avatar: _emberData.default.attr('string'),
    sleeping_percare_preferences_continence_details_valid: _emberData.default.attr('number'),
    skin_details_valid: _emberData.default.attr('number'),
    allergies_details_valid: _emberData.default.attr('number'),
    skin_allergies_discharge_details_valid: _emberData.default.attr('number'),
    submitted: _emberData.default.attr('number'),
    mood: _emberData.default.attr('string'),
    weights: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    weights_dates: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    moods: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    moods_dates: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    weightChartData: Ember.computed('weights', function () {
      return {
        labels: this.get('weights_dates'),
        datasets: [{
          label: 'weight',
          data: this.get('weights'),
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    }),
    moodChartData: Ember.computed('moods', function () {
      return {
        labels: this.get('moods_dates'),
        datasets: [{
          label: 'mood',
          data: this.get('moods'),
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    }),
    chartOptions: {
      responsive: true,
      scaleShowLabels: false,
      legend: {
        display: false,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 1,
          fontSize: 12,
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false
          },
          ticks: {
            display: true,
            beginAtZero: false
          }
        }]
      }
    },
    moodChartOptions: {
      responsive: true,
      scaleShowLabels: false,
      legend: {
        display: false,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 1,
          fontSize: 12,
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false
          },
          ticks: {
            callback: function callback(value) {
              if (value === 1) return 'Angry';else if (value === 2) return 'Frown';else if (value === 3) return 'Sad';else if (value === 4) return 'Meh';else if (value === 5) return 'Happy';else if (value === 6) return 'Laughing';else return '';
            }
          }
        }]
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/shifts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('shift');
    },
    actions: {
      saveShift: function saveShift() {
        var shift = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        shift.set('name', shift.get('name'));
        shift.set('description', shift.get('description'));
        shift.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          Ember.$('.modal-window.success').removeClass('hidden');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          self.transitionTo('after-login.shifts.index');
        }, function (error) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/percare-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    init: function init() {
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('model.personal_care').get('independent')) {
        Ember.$('#independent-group-options').hide();
      } else {
        Ember.$('#independent-group-options').show();
      }

      if (this.get('model.personal_care').get('oral_assistance') == "" || this.get('model.personal_care').get('oral_assistance') == "Independent") {
        Ember.$('#oral_assistance').hide();
      } else {
        Ember.$('#oral_assistance').show();
      }

      if (this.get('model.personal_care').get('bathing_assistance') == "" || this.get('model.personal_care').get('bathing_assistance') == "Independent") {
        Ember.$('#bathing_assistance').hide();
      } else {
        Ember.$('#bathing_assistance').show();
      }

      if (this.get('model.personal_care').get('arising_assistance') == "" || this.get('model.personal_care').get('arising_assistance') == "Independent") {
        Ember.$('#arising_assistance').hide();
      } else {
        Ember.$('#arising_assistance').show();
      }

      if (this.get('model.personal_care').get('dressing_assistance') == "" || this.get('model.personal_care').get('dressing_assistance') == "Independent") {
        Ember.$('#dressing_assistance').hide();
      } else {
        Ember.$('#dressing_assistance').show();
      }

      if (this.get('model.personal_care').get('shaving_assistance') == "" || this.get('model.personal_care').get('shaving_assistance') == "Independent" || this.get('model.personal_care').get('shaving_assistance') == "None") {
        Ember.$('#shaving_assistance').hide();
      } else {
        Ember.$('#shaving_assistance').show();
      }

      if (this.get('model.personal_care').get('toileting_day_assistance') == "" || this.get('model.personal_care').get('toileting_day_assistance') == "Independent") {
        Ember.$('#toileting_day_assistance').hide();
      } else {
        Ember.$('#toileting_day_assistance').show();
      }

      if (this.get('model.personal_care').get('toileting_night_assistance') == "" || this.get('model.personal_care').get('toileting_night_assistance') == "Independent") {
        Ember.$('#toileting_night_assistance').hide();
      } else {
        Ember.$('#toileting_night_assistance').show();
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#percare-details').offset().top - 58);
      });
    },
    modelWasChanged: Ember.observer('personalCareWasChanged', 'independentChanged', 'oralStatusChanged', 'bathingStatusChanged', 'arisingStatusChanged', 'dressingStatusChanged', 'shavingStatusChanged', 'toiletingDayStatusChanged', 'toiletingNightStatusChanged', function () {
      if (this.get('personalCareWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('independentChanged')) {
        Ember.$('#independent-group-options').hide();
      } else {
        Ember.$('#independent-group-options').show();
      }

      if (this.get('oralStatusChanged') == "" || this.get('oralStatusChanged') == "Independent") {
        Ember.$('#oral_assistance').hide();
      } else {
        Ember.$('#oral_assistance').show();
      }

      if (this.get('bathingStatusChanged') == "" || this.get('bathingStatusChanged') == "Independent") {
        Ember.$('#bathing_assistance').hide();
      } else {
        Ember.$('#bathing_assistance').show();
      }

      if (this.get('arisingStatusChanged') == "" || this.get('arisingStatusChanged') == "Independent") {
        Ember.$('#arising_assistance').hide();
      } else {
        Ember.$('#arising_assistance').show();
      }

      if (this.get('dressingStatusChanged') == "" || this.get('dressingStatusChanged') == "Independent") {
        Ember.$('#dressing_assistance').hide();
      } else {
        Ember.$('#dressing_assistance').show();
      }

      if (this.get('shavingStatusChanged') == "" || this.get('shavingStatusChanged') == "Independent" || this.get('shavingStatusChanged') == "None") {
        Ember.$('#shaving_assistance').hide();
      } else {
        Ember.$('#shaving_assistance').show();
      }

      if (this.get('toiletingDayStatusChanged') == "" || this.get('toiletingDayStatusChanged') == "Independent") {
        Ember.$('#toileting_day_assistance').hide();
      } else {
        Ember.$('#toileting_day_assistance').show();
      }

      if (this.get('toiletingNightStatusChanged') == "" || this.get('toiletingNightStatusChanged') == "Independent") {
        Ember.$('#toileting_night_assistance').hide();
      } else {
        Ember.$('#toileting_night_assistance').show();
      }
    }).on('init'),
    oralStatusChanged: Ember.computed('model.personal_care.oral_status', function () {
      return this.get('model.personal_care.oral_status');
    }),
    bathingStatusChanged: Ember.computed('model.personal_care.bathing_status', function () {
      return this.get('model.personal_care.bathing_status');
    }),
    arisingStatusChanged: Ember.computed('model.personal_care.arising_status', function () {
      return this.get('model.personal_care.arising_status');
    }),
    dressingStatusChanged: Ember.computed('model.personal_care.dressing_status', function () {
      return this.get('model.personal_care.dressing_status');
    }),
    shavingStatusChanged: Ember.computed('model.personal_care.shaving_status', function () {
      return this.get('model.personal_care.shaving_status');
    }),
    toiletingDayStatusChanged: Ember.computed('model.personal_care.toileting_day_status', function () {
      return this.get('model.personal_care.toileting_day_status');
    }),
    toiletingNightStatusChanged: Ember.computed('model.personal_care.toileting_night_status', function () {
      return this.get('model.personal_care.toileting_night_status');
    }),
    independentChanged: Ember.computed('model.personal_care.independent', function () {
      return this.get('model.personal_care.independent');
    }),
    personalCareWasChanged: Ember.computed('model.personal_care.hasDirtyAttributes', function () {
      return this.get('model.personal_care.hasDirtyAttributes');
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.personal_care').set(Ember.$(event.target).attr('model-attr'), value);
      },
      selectValue2: function selectValue2(value) {
        this.get('model.continence_details').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
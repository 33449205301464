define("careshare/components/admission-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InventoryListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    yearRange: '',
    init: function init() {
      this.set('model', this.get('model'));

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
define("careshare/serializers/resident", ["exports", "careshare/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var typeKey = primaryModelClass.modelName;
      var newPayload = {};
      newPayload[typeKey] = payload;
      return this._normalizeResponse(store, primaryModelClass, newPayload, id, requestType, true);
    },
    keyForRelationship: function keyForRelationship(key, relationship, method) {
      return Ember.String.underscore(key) + '_id';
    }
  });

  _exports.default = _default;
});
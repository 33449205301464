define("careshare/routes/after-login/residents/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        backgroundReload: false,
        adapterOptions: {
          expand: 'inventory'
        }
      });
    },
    willTransition: function willTransition() {// implement the behavior you want.
      // you can for example abort the transition if a condition is not fullfiled
      // by calling transition.abort().
    },
    // it seems to me the deactivate hook is not always called when leaving a route
    // Try use will transition
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      saveResident: function saveResident() {
        var resident = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.save().then(function () {
          resident.get('inventory').filterBy('id', null).forEach(function (item) {
            item.deleteRecord();
          });
          self.transitionTo('after-login.residents.contact-details', resident.get('id'));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        });
      }
    }
  });

  _exports.default = _default;
});
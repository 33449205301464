define("careshare/controllers/after-login/adverse-events/resident-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["resident_id"],
    resident_id: '',
    additionalWeights: Ember.computed('weights.[]', function () {
      var weights = _.rest(this.get('weights'));

      if (this.get('weights').length > 0) {
        return weights;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
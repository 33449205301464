define("careshare/templates/after-login/residents/admission-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LyNdThMb",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"admission-details\",null,[[\"model\",\"goTo\",\"changeUnit\"],[[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"setBelongsTo\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/admission-details.hbs"
    }
  });

  _exports.default = _default;
});
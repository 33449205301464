define("careshare/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service('session'),
    actions: {
      error: function error(_error) {
        if (_error && !Ember.isEmpty(_error.status) && (_error.status !== 422 || _error.status !== 500)) {
          this.get('session').invalidate();
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});
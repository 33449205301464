define("careshare/routes/after-login/residents/mobility-nutrition-details", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data"], function (_exports, _environment, _serializeData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_serializeData.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        adapterOptions: {
          expand: 'inventory'
        }
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/mobility-nutrition-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        self.serializeNestedRecordFor(model, 'mobility', data[0]['data']);
        self.serializeNestedRecordFor(model, 'nutrition', data[0]['data']);
        self.serializeNestedRecordFor(model, 'reminiscence', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'restraint-types', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'nutritional-diagnosis', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'type-of-diet', data[0]['data']);
        self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_restraint_types', model.get('restraint_types').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_nutritional_diagnosis', model.get('nutritional_diagnosis').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_type_of_diet', model.get('type_of_diet').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    saveData: function saveData() {
      var self = this;
      var resident = this.modelFor(this.routeName);
      var formattedData = {};
      formattedData['mobility'] = resident.get('mobility');
      formattedData['nutrition'] = resident.get('nutrition');
      formattedData['reminiscence'] = resident.get('reminiscence');
      formattedData['restraint_types'] = resident.get('restraint_types');
      formattedData['nutritional_diagnosis'] = resident.get('nutritional_diagnosis');
      formattedData['type_of_diet'] = resident.get('type_of_diet');
      this.controllerFor('after-login.residents.mobility-nutrition-details').set('errors', {});
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/mobility-nutrition-details/update/' + resident.get('id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: JSON.stringify(formattedData)
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      save: function save(modelSaved) {
        var _this = this;

        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          return false;
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(this.saveData()).done(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          resident.set('mobility_nutrition_details_valid', 1);
          resident.get('mobility').save();
          resident.get('nutrition').save();
          resident.get('reminiscence').save();
          self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_restraint_types', resident.get('restraint_types').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_nutritional_diagnosis', resident.get('nutritional_diagnosis').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.mobility-nutrition-details').set('original_type_of_diet', resident.get('type_of_diet').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });

          _this.controllerFor('after-login.residents.mobility-nutrition-details').set('errors', response.responseJSON);

          resident.set('mobility_nutrition_details_valid', 0);
        });
      },
      back: function back(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.communication-eol-medical-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      next: function next(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.sleeping-percare-preferences-continence-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      goTo: function goTo(transition, modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo(transition, resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/contact-family-details-view", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    init: function init() {
      this.set('model', this.get('model'));

      this._super.apply(this, arguments);
    },
    sortDefinition: ['order:desc'],
    sortedFamilyContacts: Ember.computed.sort('model.contact_family_details', 'sortDefinition'),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#contact-family-details').offset().top - 58);
      });
    }
  });

  _exports.default = _default;
});
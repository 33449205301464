define("careshare/components/shift-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header'
  });

  _exports.default = _default;
});
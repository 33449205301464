define("careshare/services/dialog", ["exports", "ember-dialog/services/dialog"], function (_exports, _dialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dialog.default.extend({});

  _exports.default = _default;
});
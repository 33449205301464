define("careshare/adapters/role", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'roles';
    },
    togglePermission: function togglePermission(data) {
      var url = this.buildURL(this.pathForType()) + '/toggle-permission';
      return this.ajax(url, "POST", {
        data: data
      }).then(function () {});
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/users/new", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('user');
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      saveUser: function saveUser() {
        var user = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        user.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
            self.transitionTo('after-login.users');
          });
          alert('Successfully saved!');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/adverse-events/new", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        var residents = Ember.$('.resident-select');
        residents.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).next().removeClass('is-invalid');
            }
          });
        });
      });
    },
    model: function model() {
      return this.store.createRecord('adverse-event');
    },
    afterModel: function afterModel(model) {
      var note = this.store.createRecord('adverse-event-note');
      note.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('adverse_notes').pushObject(note);
    },
    actions: {
      saveEvent: function saveEvent() {
        var _this = this;

        var model = this.modelFor(this.routeName);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        model.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });

          _this.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');

          Ember.$('.modal-window.success').removeClass('hidden');

          _this.transitionTo('after-login.adverse-events');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        this.transitionTo('after-login.adverse-events');
      }
    }
  });

  _exports.default = _default;
});
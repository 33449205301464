define("careshare/controllers/after-login/news/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["page", "perPage", "expand"],
    usersWithCount: Ember.computed.map('model', function (data, index) {
      return Ember.Object.create({
        data: data,
        count: index + 1
      });
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    })
  });

  _exports.default = _default;
});
define("careshare/models/mobility", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    assistance_transfer: _emberData.default.attr('string'),
    mobile_aids: _emberData.default.attr('number'),
    one_walking_stick: _emberData.default.attr('string'),
    two_walking_stick: _emberData.default.attr('string'),
    walking_frame: _emberData.default.attr('string'),
    wheelchair: _emberData.default.attr('string'),
    pressure_mat: _emberData.default.attr('number'),
    restraint: _emberData.default.attr('number'),
    hoist: _emberData.default.attr('number'),
    consent_recvd: _emberData.default.attr('number'),
    risk_of_falling: _emberData.default.attr('number'),
    uses_call_system: _emberData.default.attr('number'),
    risk_of_leaving: _emberData.default.attr('number'),
    able_to_climb_stairs: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    plan_and_outcomes: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/components/monitoring-quick-add-menu", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    resident: null,
    dateTo: null,
    // Declare dateTo as a property
    init: function init() {
      this._super.apply(this, arguments);

      this.set('dateTo', (0, _moment.default)().add(1, 'd').format('DD.MM.YYYY'));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.setupTooltips();
    },
    setupTooltips: function setupTooltips() {
      Ember.run.schedule('afterRender', this, function () {
        Ember.$(document).tooltip({
          content: function content() {
            return Ember.$(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    }
  });

  _exports.default = _default;
});
define("careshare/components/resident-edit", ["exports", "careshare/mixins/checkboxes-behavior"], function (_exports, _checkboxesBehavior) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InventoryListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header',
    yearRange: '',
    selectedInventoryItems: Ember.A(),
    inventory_items: [InventoryListItem.create({
      item_description: "Reading Glasses",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Glasses",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Hearing Aid",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Walking Stick",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Walking Frame",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Dentures",
      item_type: "predefined"
    })],
    idsErrors: [],
    init: function init() {
      this.initCheckboxesList('inventory');
      this.set('range', new Date().getFullYear() - 118 + ':' + (new Date().getFullYear() - 18));
      this.set('model', this.get('model'));

      this._super.apply(this, arguments);
    },
    actions: {
      selectValue: function selectValue(gender) {
        this.get('model').set(Ember.$(event.target).attr('model-attr'), gender);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
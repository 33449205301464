define("careshare/routes/after-login/residents/key-details", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/set-belongs-to", "careshare/mixins/admission-validate"], function (_exports, _environment, _serializeData, _setBelongsTo, _admissionValidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_serializeData.default, _admissionValidate.default, _setBelongsTo.default), {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    queryParams: {
      view: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('residentlite', params.id, {
        backgroundReload: true
      });
    },
    afterModel: function afterModel(model) {
      var store = this.store;
      var self = this;
      store.findAll('user');
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/inventory?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        model.set('weights', data[0]['weights']);
        model.set('weights_dates', data[0]['dates']);
        model.set('moods', data[2]['moods']);
        model.set('moods_dates', data[2]['dates']);
        self.serializeNestedRecordsFor(model, 'inventory', data[1]['data']);
        self.controllerFor('after-login.residents.key-details').set('original_inventory', model.get('inventory').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        model.save();
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    actions: {
      save: function save(modelSaved) {
        var resident = this.modelFor(this.routeName);
        var self = this;

        if (modelSaved) {
          return false;
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.save().then(function () {
          self.controllerFor('after-login.residents.key-details').set('original_inventory', resident.get('inventory').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
            Ember.$('.modal-window.success').removeClass('hidden');
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved');
          });

          if (resident.get('submitted') == 1) {
            self.transitionTo('after-login.residents.key-details', resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          }
        }).catch(function () {
          resident.set('key_details_valid', 0);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back(modelSaved) {
        var self = this;

        if (modelSaved) {
          self.transitionTo('after-login.residents');
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      commit: function commit(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          if (this.isAllTabsValid(resident)) {
            var requestOptions = {
              url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/residents/submit?resident_id=' + resident.get('id') + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
              dataType: 'JSON',
              method: 'PUT',
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
              }
            };
            Ember.$.ajax(requestOptions).then(function (data) {
              resident.set('submitted', data.submitted);
            });
            self.controllerFor('after-login').set('dialogMessage', this.registerResident());
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.residents');
          } else {
            self.controllerFor('after-login').set('dialogMessage', this.notValidMessage());
            Ember.$('.modal-window.error').removeClass('hidden');
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSectionFirst());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      next: function next(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.additional-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      willTransition: function willTransition(transition) {
        if (Ember.get(transition, 'targetName') == 'after-login.residents.index') {
          this.get('store').peekAll('resident').forEach(function (entry) {
            return entry.unloadRecord();
          });
        }
      },
      goTo: function goTo(transition, modelSaved, view) {
        var self = this;
        var resident = this.modelFor(this.routeName);
        if (this.routeName == transition) transition = "after-login.residents.admission-details";

        if (modelSaved) {
          if (view == 1) {
            self.transitionTo(transition, resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          } else {
            self.transitionTo(transition, resident.get('id'));
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      }
    }
  });

  _exports.default = _default;
});
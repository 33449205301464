define("careshare/components/medical-details", ["exports", "underscore", "careshare/mixins/checkboxes-behavior"], function (_exports, _underscore, _checkboxesBehavior) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    medical_conditions_items: [ListItem.create({
      item_description: "Alzheimer's",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Anemia",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Angina",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Arthritis",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Cancer",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Cataracts",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Cognitive Impairment",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Constipation",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Dementia",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Depression",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Diabetes Type 1",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Diabetes Type 2",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Dysphagia",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Eczema",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Enlarged prostrate",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Epilepsy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Glaucoma",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Heart disease",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hypertension",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Kidney disease",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Liver disease",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Lung disease",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Macular degeneration",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Malnutrition",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Mental Disorder",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Nutritional Condition",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Osteoporosis",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Parkinsons",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Respiratory disease",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Rheumatism",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Suicidal Tendancies",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('medical_conditions');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('has_admission_medications', 'medicalWasChanged', 'medicalConditionslWasChanged', function () {
      if (this.get('medicalWasChanged') || this.get('medicalConditionslWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('has_admission_medications')) {
        Ember.$('#admission-group').show();
      } else {
        Ember.$('#admission-group').hide();
      }
    }).on('init'),
    has_admission_medications: Ember.computed('model.medical.has_admission_medications', function () {
      return this.get('model.medical.has_admission_medications');
    }),
    medicalConditionslWasChanged: Ember.computed('model.medical_conditions.[]', 'original_medical_conditions', function () {
      return !_underscore.default.isEqual(this.get('model').get('medical_conditions').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_medical_conditions').sort());
    }),
    medicalWasChanged: Ember.computed('model.medical.hasDirtyAttributes', function () {
      return this.get('model.medical.hasDirtyAttributes');
    }),
    didInsertElement: function didInsertElement() {
      if (this.get('model.medical').get('has_admission_medications')) {
        Ember.$('#admission-group').show();
      } else {
        Ember.$('#admission-group').hide();
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#medical-details').offset().top - 58);
      });
    },
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.communication').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
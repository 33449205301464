define("careshare/routes/after-login/case-notes/new", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "moment", "careshare/config/environment"], function (_exports, _toConsumableArray2, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.addObserver('controller.notes.[]', function () {
        this.initListeners();
      });
    },
    beforeModel: function beforeModel() {
      this.initListeners();
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.run(function () {
        return _this.get('store').unloadAll('case-note');
      });
      var store = this.store;
      var record = store.createRecord('case-note', {
        id: Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2),
        user_id: this.get('session.data.authenticated.user_id')
      });
      this.controllerFor('after-login.case-notes.new').set('notes', []);
      this.controllerFor('after-login.case-notes.new').get('notes').pushObject(record);

      if (transition.queryParams.resident_id) {
        this.controllerFor('after-login.case-notes.new').get('notes')[0].set('resident_id', transition.queryParams.resident_id);
      }

      if (transition.queryParams.note_type) {
        this.controllerFor('after-login.case-notes.new').get('notes')[0].set('notes_type', transition.queryParams.note_type);
      }

      if (transition.queryParams.note_type && transition.queryParams.resident_id) {
        var self = this;
        Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/current?id=' + transition.queryParams.resident_id + '&outcome_type=' + transition.queryParams.note_type, {
          method: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          }
        })]).then(function (data) {
          if (data && data.length && data[0].outcomes && data[0].outcomes.length && data[0].outcomes[0].notes) {
            Ember.$('.current-outcome').text(data[0].outcomes[0].notes);
          } else {
            Ember.$('.current-outcome').text('Current outcome does not exist');
          }

          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      }
    },
    initListeners: function initListeners() {
      var _this2 = this;

      Ember.run.schedule('afterRender', function () {
        var self = _this2;
        var textAreas = Ember.$('.report-textarea');
        var dates = Ember.$('.date-picker');
        var selects = Ember.$('.section-select');
        var residents = Ember.$('.resident-select');
        textAreas.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).text())) {
              $(this).removeClass('is-invalid');
            }
          });
        });
        dates.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).parent().removeClass('is-invalid');
            }
          });
        });
        selects.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
          $(this).on("change", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              var descriptionDiv = $(this).closest('.note-item').find('.current-outcome');
              var residentSelect = $(this).closest('.note-item').find('.resident-select');

              if (!Ember.isEmpty(residentSelect.val())) {
                self.getCurrentOutcome(residentSelect.val(), $(this).val(), descriptionDiv);
              }
            }
          });
        });
        residents.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
          $(this).on("change", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              var descriptionDiv = $(this).closest('.note-item').find('.current-outcome');
              var sectionSelect = $(this).closest('.note-item').find('.section-select');

              if (!Ember.isEmpty(sectionSelect.val())) {
                self.getCurrentOutcome($(this).val(), sectionSelect.val(), descriptionDiv);
              }
            }
          });
        });
      });
    },
    getCurrentOutcome: function getCurrentOutcome(resident, type, div) {
      var self = this;
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
      Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/current?id=' + resident + '&outcome_type=' + type, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (response) {
        if (response[0].outcomes[0]) {
          div.text(response[0].outcomes[0].notes);
        } else {
          div.text('Current outcome does not exist');
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('after-login.case-notes.index');
      },
      saveData: function saveData() {
        var self = this;
        var noteModels = this.controllerFor('after-login.case-notes.new').get('notes');
        var formatedRequestData = JSON.stringify(noteModels);
        var requestOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/case-notes',
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestData
        };
        var saveDetails = Ember.$.ajax(requestOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(saveDetails).done(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.case-notes');
        }).catch(function (response) {
          for (var i = 0; i < noteModels.length; i++) {
            var errors = response.responseJSON[i].errors;

            noteModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              noteModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.case-notes.new').set('notes', (0, _toConsumableArray2.default)(noteModels));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      addRecord: function addRecord() {
        var self = this;
        var store = this.store;

        var firstAddedNote = self.store.peekAll('case-note').content[0]._attributes;

        var firstAddedResidentId = firstAddedNote.resident_id;
        var firstAddedResident = firstAddedNote.resident;
        var firstAddedDate = firstAddedNote.date;

        if (Ember.isEmpty(firstAddedDate)) {
          firstAddedDate = (0, _moment.default)().format("DD.MM.YYYY");
        }

        var record = store.createRecord('case-note', {
          id: Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2),
          user_id: self.get('session.data.authenticated.user_id'),
          resident_id: firstAddedResidentId,
          resident: firstAddedResident,
          date: firstAddedDate
        });
        this.controllerFor('after-login.case-notes.new').get('notes').pushObject(record);
        Ember.$('.form-scroll').animate({
          scrollTop: $('.form-scroll').prop('scrollHeight')
        }, 1000);
      },
      removeRecord: function removeRecord(note) {
        this.controllerFor('after-login.case-notes.new').get('notes').removeObject(note);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/models/case-note", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    user_id: _emberData.default.attr('number'),
    date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    notes_type: _emberData.default.attr('string'),
    notes_title: _emberData.default.attr('string'),
    title: Ember.observer('notes_type', 'resident', function () {
      return this.set('notes_title', this.get('notes_type') + ' for ' + this.get('resident'));
    }),
    resident: _emberData.default.attr('string'),
    user: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    toJSON: function toJSON() {
      return this._super({
        includeId: true
      });
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/consent/edit", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    additionalConsents: Ember.computed('consents.[]', function () {
      var consents = _underscore.default.rest(this.get('consents'));

      if (this.get('consents').length > 0) {
        return consents;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
define("careshare/services/monitoring-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    types: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.loadTypes();
    },
    loadTypes: function loadTypes() {
      var _this = this;

      return this.get('store').findAll('monitoring').then(function (types) {
        _this.set('types', types);
      });
    }
  });

  _exports.default = _default;
});
define("careshare/models/skin-action", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    skin_grade: _emberData.default.attr('number'),
    time: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/shift-summary-report/index", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "careshare/config/environment"], function (_exports, _toConsumableArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),
    queryParams: {
      unit: {
        refreshModel: true
      },
      shift: {
        refreshModel: true
      },
      date: {
        refreshModel: true
      },
      resident_id: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
      Ember.run(function () {
        return _this.get('store').unloadAll('shift-summary');
      });
    },
    model: function model(params) {
      return this.get('store').query('shift-summary', {
        "date": params.date.split(".").reverse().join("-"),
        "shift": params['shift'],
        "unit": params['unit'],
        "resident_id": params['resident_id'],
        'user_id': this.get('session').get('data.authenticated.user_id'),
        'expand': 'resident,shift,unit'
      }, {
        reload: false
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');

        _this2.toggleSubmitButton();
      });
    },
    toggleSubmitButton: function toggleSubmitButton() {
      var $submitButton = $('#submit_report');
      var activeReports = this.modelFor(this.routeName).filter(function (report) {
        return report.get('is_locked') === 0;
      });
      var allFilled = true;
      activeReports.forEach(function (report) {
        if (report.get('notes').length === 0 || report.get('mood').length === 0) {
          allFilled = false;
        }
      });

      if (!allFilled || Ember.isEmpty(this.controller.get('unit')) || activeReports.length === 0) {
        $submitButton.addClass('disabled');
      } else {
        $submitButton.removeClass('disabled');
      }
    },
    sendRequest: function sendRequest(url, data) {
      var self = this;
      var requestOptions = {
        url: url,
        dataType: 'JSON',
        method: 'PUT',
        data: data,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      submit: function submit() {
        var _this3 = this;

        var self = this;
        var reportModels = this.controllerFor('after-login.shift-summary-report.index').get('sortedShiftElements');
        var reports = reportModels.map(function (reportModel) {
          reportModel.set('date', _this3.controller.get('date'));
          reportModel.set('is_locked', 1);
          return reportModel.toJSON();
        });
        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "YP+t7mSR",
          "block": "{\"symbols\":[],\"statements\":[[0,\"This Shift Summary will be locked for editing, do you want to continue?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          var url = (_environment.default.host !== undefined ? _environment.default.host : _this3.get('host')) + '/v1/shift-summary-report/submit';
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });

          _this3.sendRequest(url, {
            reports: reports
          }).then(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });

            _this3.refresh();

            Ember.$('.modal-window.success').removeClass('hidden');

            _this3.controllerFor('after-login').set('dialogMessage', 'Day\'s shifts successfully closed!');
          }).catch(function (response) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });

            for (var i = 0; i <= reportModels.get('length'); i++) {
              var errors = response.responseJSON[i].errors;
              reportModels[i].set('is_locked', 0);

              reportModels[i]._internalModel.clearErrorMessages();

              if (errors) {
                reportModels[i]._internalModel.adapterDidInvalidate(errors);
              }
            }

            self.controllerFor('after-login.shift-summary-report.index').set('sortedShiftElements', (0, _toConsumableArray2.default)(reportModels));
          });
        });
      },
      createPdf: function createPdf(unit, shift, resident_id) {
        var _this4 = this;

        var self = this;
        var reportModels = this.controllerFor('after-login.shift-summary-report.index').get('sortedShiftElements');
        var reports = reportModels.map(function (reportModel) {
          reportModel.set('date', _this4.controller.get('date'));
          return reportModel.toJSON();
        });
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        var url = (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/shift-summary-report/pdf';
        var data = {
          date: this.controller.get('date'),
          unit: unit,
          shift: shift,
          resident_id: resident_id,
          user_id: this.get('session').get('data.authenticated.user_id'),
          reports: reports
        };
        this.sendRequest(url, data).then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Document successfully saved to documents!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });

          for (var i = 0; i <= reportModels.get('length'); i++) {
            var errors = response.responseJSON[i].errors;

            reportModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              reportModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.shift-summary-report.index').set('sortedShiftElements', (0, _toConsumableArray2.default)(reportModels));
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/news/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EmrpVhEg",
    "block": "{\"symbols\":[\"news\"],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"News\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[20,[\"model\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"h2\"],[7],[1,[19,1,[\"date\"]],false],[8],[0,\"\\n\\t\\t\"],[6,\"p\"],[7],[1,[25,\"ntobr\",[[19,1,[\"content\"]]],null],true],[8],[0,\"\\n\\t\\t\"],[6,\"hr\"],[7],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/news/view.hbs"
    }
  });

  _exports.default = _default;
});
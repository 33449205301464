define("careshare/templates/components/logs-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YSOkKBrT",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"admin-header\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col text-muted\"],[7],[0,\"\\n            \"],[6,\"a\"],[9,\"class\",\"text-muted\"],[10,\"href\",[26,[\"/\",[18,\"back\"]]]],[7],[0,\"Home\"],[8],[0,\" / \"],[6,\"a\"],[9,\"class\",\"text-muted\"],[10,\"href\",[26,[\"/\",[18,\"back\"]]]],[7],[0,\"Logs\"],[8],[0,\" / \"],[1,[18,\"title\"],false],[0,\"\\n            \"],[6,\"a\"],[9,\"class\",\"back\"],[10,\"href\",[26,[[18,\"back\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-long-arrow-alt-left fa fa-fw\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"Back\"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"can\",[\"/v1/logs/index\"],null]],null,{\"statements\":[[0,\"                \"],[6,\"ul\"],[9,\"class\",\"admin-pages\"],[7],[0,\"\\n                    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[25,\"eq\",[[20,[\"active\"]],\"expenses\"],null],\"active\"],null]]]],[7],[0,\"\\n                        \"],[4,\"link-to\",[\"after-login.consent\"],[[\"class\"],[\"nav-link\"]],{\"statements\":[[0,\"Expenses\"]],\"parameters\":[]},null],[0,\"\\n                    \"],[8],[0,\"\\n                    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[25,\"eq\",[[20,[\"active\"]],\"complaints\"],null],\"active\"],null]]]],[7],[0,\"\\n                        \"],[4,\"link-to\",[\"after-login.consent\"],[[\"class\"],[\"nav-link\"]],{\"statements\":[[0,\"Complaints\"]],\"parameters\":[]},null],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/logs-header.hbs"
    }
  });

  _exports.default = _default;
});
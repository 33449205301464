define("careshare/templates/after-login/roles/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AaHLcN1j",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"New Role\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\\n\\t\"],[4,\"role-edit\",null,[[\"model\",\"isNew\",\"saveRole\"],[[20,[\"model\"]],true,[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveRole\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/roles/new.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/models/appointment-item-ext", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    appointment_id: _emberData.default.attr('number'),
    resident: _emberData.default.attr('string'),
    service: _emberData.default.attr('string'),
    professional: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    date: _emberData.default.attr('date-format'),
    time: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/logs/new", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "moment", "careshare/config/environment"], function (_exports, _toConsumableArray2, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.run(function () {
        return _this.get('store').unloadAll('consent');
      });
      var store = this.store;
      var record = store.createRecord('consent', {
        user_id: this.get('session.data.authenticated.user_id')
      });
      this.controllerFor('after-login.consent.new').set('consents', []);
      this.controllerFor('after-login.consent.new').get('consents').pushObject(record);

      if (transition.queryParams.resident_id) {
        this.controllerFor('after-login.consent.new').get('consents')[0].set('resident_id', transition.queryParams.resident_id);
      }
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('after-login.consent');
      },
      saveData: function saveData() {
        var self = this;
        var consentModels = this.controllerFor('after-login.consent.new').get('consents');
        var formatedRequestData = JSON.stringify(consentModels);
        var requestOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/consents',
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestData
        };
        var saveDetails = Ember.$.ajax(requestOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(saveDetails).done(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.consent');
        }).catch(function (response) {
          for (var i = 0; i < consentModels.length; i++) {
            var errors = response.responseJSON[i].errors;

            consentModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              consentModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.consent.new').set('consents', (0, _toConsumableArray2.default)(consentModels));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      addRecord: function addRecord() {
        var self = this;
        var store = this.store;

        var firstAddedConsent = self.store.peekAll('consent').content[0]._attributes;

        var firstAddedResidentId = firstAddedConsent.resident_id;
        var firstAddedResident = firstAddedConsent.resident;
        var firstAddedDate = firstAddedConsent.date;

        if (Ember.isEmpty(firstAddedDate)) {
          firstAddedDate = (0, _moment.default)().format("DD.MM.YYYY");
        }

        var record = store.createRecord('consent', {
          id: Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2),
          user_id: self.get('session.data.authenticated.user_id'),
          resident_id: firstAddedResidentId,
          resident: firstAddedResident,
          date: firstAddedDate
        });
        this.controllerFor('after-login.consent.new').get('consents').pushObject(record);
        Ember.$('.form-scroll').animate({
          scrollTop: $('.form-scroll').prop('scrollHeight')
        }, 1000);
      },
      removeRecord: function removeRecord(consent) {
        this.controllerFor('after-login.consent.new').get('consents').removeObject(consent);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/services/store", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Store.extend({
    didSaveRecord: function didSaveRecord(internalModel, dataArg) {
      if (internalModel.modelName === 'shift-summary' && internalModel.id && dataArg.data.id && internalModel.id !== dataArg.data.id) {
        internalModel.id = null;
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});
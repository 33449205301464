define("careshare/components/resident-search", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'input',
    classNames: 'form-control',
    type: 'all',
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;

      if (!Ember.isEmpty(this.get('resident_type'))) {
        this.set('type', this.get('resident_type'));
      }

      this.$().attr('placeholder', 'Select Resident');
      this.$().attr('name', 'resident_id');
      this.$().val(this.get('modelLabel'));

      this.$().autocomplete({
        source: function source(request, response) {
          Ember.$.ajax({
            url: (_environment.default.host !== undefined ? _environment.default.host : self.get('host')) + '/v1/resident-filter?type=' + self.get('type'),
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            },
            data: {
              term: request.term
            },
            success: function success(data) {
              response($.map(data, function (item) {
                return {
                  label: item.firstname + " " + item.lastname,
                  value: item.id,
                  unit: item.unit
                };
              }));
            }
          });
        },
        minLength: 2,
        focus: function focus(event, ui) {
          self.$().val(ui.item.label);
          return false;
        },
        select: function select(event, ui) {
          self.set('modelValue', parseInt(ui.item.value));
          self.set('residentName', ui.item.label);
          return false;
        }
      }).autocomplete("instance")._renderItem = function (ul, item) {
        return $("<li>").append("<div>" + item.label + "</div>").appendTo(ul);
      };

      this.$().keyup(function () {
        if ($(this).val() == '') {
          self.set('modelValue', 'all');
          self.set('residentName', '');
        }
      });
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/reports/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["page", "perPage", "category", "sortBy", "direction", "refresh"],
    page: 1,
    perPage: 10,
    category: null,
    sortBy: 'created_at',
    direction: 'DESC',
    mainController: Ember.inject.controller('after-login'),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    documentsUpdate: function (sender, key) {
      if (key === 'content.page') {
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
      }

      if (key === 'content.length') {
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      }
    }.observes('content.page', 'content.length'),
    categoryChanged: function (sender, key) {
      var self = this;
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('#category').val(this.get('category'));

        if (this.get('category') === 'RESIDENT DOCUMENTS') {
          Ember.$('#document-list-header').removeClass('document-select-left-two');
          Ember.$('#document-list-header').addClass('document-select-left');
        } else {
          Ember.$('#document-list-header').addClass('document-select-left-two');
          Ember.$('#document-list-header').removeClass('document-select-left');
          Ember.$('#search').val('');
          this.set('resident_id', '');
          Ember.$('.resident-select').val(this.get('resident_id')).trigger('chosen:updated');
        }
      });

      if (!Ember.isEmpty(this.get('category'))) {
        var types = this.get('categories').filterBy('name', this.get('category'));
        this.set('currentTypes', types[0].types);
        setTimeout(function () {
          Ember.$('#type').val(self.get('type'));
        }, 100);
      }
    }.observes('category'),
    actions: {}
  });

  _exports.default = _default;
});
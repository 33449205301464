define("careshare/routes/after-login/appointments/resident-index", ["exports", "moment", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin", "careshare/mixins/graph"], function (_exports, _moment, _environment, _routeMixin, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      service: {
        refreshModel: true
      },
      professional_id: {
        refreshModel: true
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      professional_name: {
        refreshModel: false
      },
      resident_name: {
        refreshModel: false
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('appointment-item-ext', params);
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.appointments.resident-index').get('sortBy');
        var direction = self.controllerFor('after-login.appointments.resident-index').get('direction') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.table-appointment').find('a').removeClass('top');
        Ember.$('.table-appointment').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.appointments.resident-index').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$(document).tooltip({
            content: function content() {
              return $(this).prop('title');
            },
            position: {
              my: "center bottom",
              at: "center top"
            }
          });
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    actions: {
      addAppointment: function addAppointment(resident) {
        this.transitionTo('after-login.appointments.resident-new', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      editAppointment: function editAppointment(id, resident) {
        this.transitionTo('after-login.appointments.resident-edit', id, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      print: function print() {
        var self = this;
        var resident_id = this.controllerFor('after-login.appointments.resident-index').get('resident_id');
        var type = this.controllerFor('after-login.appointments.resident-index').get('type');
        var sortBy = this.controllerFor('after-login.appointments.resident-index').get('sortBy');
        var direction = this.controllerFor('after-login.appointments.resident-index').get('direction');
        var dateFromFormatted = this.controllerFor('after-login.appointments.resident-index').get('date_from');
        var dateToFormatted = this.controllerFor('after-login.appointments.resident-index').get('date_to');
        var dateFrom = (0, _moment.default)(dateFromFormatted, 'DD.MM.YYYY').format('YYYY-MM-DD');
        var dateTo = (0, _moment.default)(dateFromFormatted, 'DD.MM.YYYY').format('YYYY-MM-DD');
        return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/appointments/print?resident_id=' + resident_id + '&type=' + type + '&sortBy=' + sortBy + '&direction=' + direction + '&date_from=' + dateFrom + '&date_to=' + dateTo, {
          method: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          }
        })]).then(function (data) {
          self.controllerFor('after-login.appointments.resident-index').set('appointmentsForPrint', data[0].appointments);
          $('.forPrint').printThis({
            importCSS: true,
            loadCSS: "",
            header: "<h1>Appointment list " + dateFromFormatted + "-" + dateToFormatted + "</h1>"
          });
          Ember.run.next(function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/transforms/date-format", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value ? (0, _moment.default)(value, 'DD.MM.YYYY').format('YYYY-MM-DD') : '';
    },
    deserialize: function deserialize(value) {
      return value ? (0, _moment.default)(value).format('DD.MM.YYYY') : '';
    }
  });

  _exports.default = _default;
});
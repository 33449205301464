define("careshare/templates/components/bs-datetimepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bwfiJzXA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,1]],null,{\"statements\":[[0,\"    \"],[11,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,\"input\",null,[[\"type\",\"class\",\"disabled\",\"name\",\"placeholder\"],[\"text\",\"form-control\",[20,[\"disabled\"]],[20,[\"textFieldName\"]],[20,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"unless\",[[20,[\"noIcon\"]]],null,{\"statements\":[[0,\"    \"],[6,\"span\"],[9,\"class\",\"input-group-addon\"],[7],[0,\"\\n  \"],[6,\"i\"],[9,\"class\",\"fa fa-calendar-alt\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/bs-datetimepicker.hbs"
    }
  });

  _exports.default = _default;
});
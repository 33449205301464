define("careshare/adapters/appointment", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    handleResponse: function handleResponse(status, headers, payload) {
      this.store.unloadAll('appointment-item-note');
      this.store.unloadAll('appointment-item');
      return this._super(status, headers, payload);
    }
  });

  _exports.default = _default;
});
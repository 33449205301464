define("careshare/models/monitoring-reading", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    userId: _emberData.default.attr('number'),
    monitoringId: _emberData.default.attr('number'),
    residentId: _emberData.default.belongsTo('resident'),
    resident: _emberData.default.belongsTo('resident'),
    date: _emberData.default.attr('date'),
    time: _emberData.default.attr('time'),
    value: _emberData.default.attr('number'),
    slug: _emberData.default.attr('string'),
    unit: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    abbeyPainScale: _emberData.default.hasOne('monitoring-abbey-pain-scale')
  });

  _exports.default = _default;
});
define("careshare/templates/components/outcome-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xa3OibYk",
    "block": "{\"symbols\":[],\"statements\":[[6,\"tr\"],[9,\"class\",\"content\"],[7],[0,\"\\n\\t\"],[6,\"td\"],[9,\"scope\",\"row\"],[7],[1,[20,[\"report_item\",\"date\"]],false],[8],[0,\"\\n\\t\"],[6,\"td\"],[9,\"class\",\"row-lg\"],[7],[1,[20,[\"report_item\",\"resident\",\"full_name\"]],false],[8],[0,\"\\n\\t\"],[6,\"td\"],[9,\"class\",\"row-lg\"],[7],[1,[20,[\"report_item\",\"outcome_type\"]],false],[8],[0,\"\\n\\t\"],[6,\"td\"],[9,\"class\",\"row-lg\"],[7],[1,[20,[\"report_item\",\"notes\"]],false],[6,\"span\"],[9,\"class\",\"print\"],[7],[1,[20,[\"report_item\",\"notes\"]],false],[8],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/outcome-item.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/admission-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rndawex7",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"details-search-header\",null,[[\"model\"],[[20,[\"model\"]]]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header details\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col resident-admission\"],[7],[0,\"\\n            \"],[1,[25,\"resident-header\",null,[[\"model\",\"weightChartData\",\"moodChartData\",\"chartOptions\",\"moodChartOptions\"],[[20,[\"model\"]],[20,[\"model\",\"weightChartData\"]],[20,[\"model\",\"moodChartData\"]],[20,[\"model\",\"chartOptions\"]],[20,[\"model\",\"moodChartOptions\"]]]]],false],[0,\"\\n            \"],[1,[25,\"details-header\",null,[[\"model\",\"active\"],[[20,[\"model\"]],\"details\"]]],false],[0,\"\\n            \"],[6,\"div\"],[7],[0,\"\\n                \"],[6,\"div\"],[9,\"class\",\"col-lg-12 form-inner-column admission-tabs\"],[7],[0,\"\\n                    \"],[1,[25,\"resident-detail-tabs\",null,[[\"showBottomNum\",\"resident\",\"goTo\",\"modelChanged\"],[1,[20,[\"model\"]],[20,[\"goTo\"]],[20,[\"modelChanged\"]]]]],false],[0,\"\\n                \"],[8],[0,\"\\n            \"],[8],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/admission-details.hbs"
    }
  });

  _exports.default = _default;
});
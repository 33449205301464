define("careshare/models/activity-resident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    time: _emberData.default.attr('time'),
    resident_id: _emberData.default.attr('number'),
    resident_details: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    mood: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
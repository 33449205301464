define("careshare/serializers/appointment-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    attrs: {
      "item_notes": {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});
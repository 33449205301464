define("careshare/controllers/after-login/skin/resident-edit", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentGrades: Ember.A(),
    gradesDisabled: true,
    constants: Ember.inject.service('constants'),
    queryParams: ["resident_id"],
    resident_id: '',
    isHealed: false,
    newAdded: false,
    additionalSkins: Ember.computed('skins.[]', function () {
      var skins = _underscore.default.rest(this.get('skins'));

      if (this.get('skins').length > 0) {
        return skins;
      } else {
        return Ember.A();
      }
    }),
    actionsSelected: function (sender, key) {
      Ember.$('#save').removeAttr('disabled');
      this.get('model').get('skinActions').forEach(function (action) {
        var act = action.get('action');

        if (!act || act === 'select action') {
          Ember.$('#save').attr('disabled', 'disabled');
        }
      });
    }.observes('model.skinActions.@each.action'),
    typeChanged: function (sender, key) {
      var self = this;
      var type = self.get('model').get('type');
      Ember.run.schedule("afterRender", this, function () {
        if (type === 'Other') {
          Ember.$('#other').show();
          Ember.$('#other').focus();
          Ember.$('#grade').hide();
        } else {
          Ember.$('#other').hide();
          Ember.$('#grade').show();
        }
      });

      if (!Ember.isEmpty(type) && type !== 'select type') {
        var typeGrades = this.get('constants').skin_types.filter(function (record) {
          return record.description === type;
        });

        if (typeof typeGrades !== 'undefined' && typeof typeGrades[0] !== 'undefined' && typeGrades[0].grades.length > 0) {
          var grades = typeGrades[0].grades;
          this.set('gradesDisabled', false);
          this.set('currentGrades', grades);
        } else {
          if (type === 'Other') {
            Ember.$('#other').show();
          } else {
            Ember.$('#other').hide();
          }

          this.set('currentGrades', Ember.A());
          this.set('gradesDisabled', true);
        }
      } else {
        this.set('currentGrades', Ember.A());
        this.set('gradesDisabled', true);
      }
    }.observes('model.type'),
    actions: {
      saveDataOnHealed: function saveDataOnHealed() {
        this.set('isHealed', true);
        this.send('saveData');
      }
    }
  });

  _exports.default = _default;
});
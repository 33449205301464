define("careshare/router", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('after-login', {
      path: '/'
    }, function () {
      this.route('residents', function () {
        this.route('index');
        this.route('list');
        this.route('removed');
        this.route('new');
        this.route('show', {
          path: ':slug'
        });
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('dashboard', {
          path: ':id/dashboard'
        });
        this.route('change-avatar', {
          path: ':id/change-avatar'
        });
        this.route('discharge', {
          path: ':id/discharge'
        });
        this.route('admission-details', {
          path: ':id/admission-details'
        });
        this.route('key-details', {
          path: ':id/key-details'
        });
        this.route('additional-details', {
          path: ':id/additional-details'
        });
        this.route('communication-details', {
          path: ':id/communication-details'
        });
        this.route('eol-details', {
          path: ':id/eol-details'
        });
        this.route('medical-details', {
          path: ':id/medical-details'
        });
        this.route('communication-eol-medical-details', {
          path: ':id/communication-eol-medical-details'
        });
        this.route('contact-details', {
          path: ':id/contact-details'
        });
        this.route('contact-family-details', {
          path: ':id/contact-family-details'
        });
        this.route('mobility-details', {
          path: ':id/mobility-details'
        });
        this.route('nutrition-details', {
          path: ':id/nutrition-details'
        });
        this.route('reminiscence-details', {
          path: ':id/reminiscence-details'
        });
        this.route('mobility-nutrition-details', {
          path: ':id/mobility-nutrition-details'
        });
        this.route('skin-details', {
          path: ':id/skin-details'
        });
        this.route('allergies-details', {
          path: ':id/allergies-details'
        });
        this.route('skin-allergies-discharge-details', {
          path: ':id/skin-allergies-discharge-details'
        });
        this.route('sleeping-details', {
          path: ':id/sleeping-details'
        });
        this.route('percare-details', {
          path: ':id/percare-details'
        });
        this.route('preferences-details', {
          path: ':id/preferences-details'
        });
        this.route('continence-details', {
          path: ':id/continence-details'
        });
        this.route('sleeping-percare-preferences-continence-details', {
          path: ':id/sleeping-percare-preferences-continence-details'
        });
        this.route('delete', {
          path: ':slug/delete'
        });
      });
      this.route('activities', function () {
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('users', function () {
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('shift-summary-report', function () {});
      this.route('admin', function () {});
      this.route('case-notes', function () {
        this.route('index');
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('outcomes', function () {
        this.route('index');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('weight', function () {
        this.route('index');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('monitoring', function () {
        this.route('index');
        this.route('resident-index', {
          path: ':id/resident-index'
        });
      });
      this.route('skin', function () {
        this.route('index');
        this.route('healed');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-healed', {
          path: ':id/resident-healed'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('consent', function () {
        this.route('index');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('logs', function () {
        this.route('index');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('infection', function () {
        this.route('index');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('news', function () {
        this.route('index');
        this.route('view');
        this.route('report');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('documents', function () {
        this.route('new');
      });
      this.route('reports', function () {
        this.route('index');
      });
      this.route('appointments', function () {
        this.route('index');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('adverse-events', function () {
        this.route('index');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('contacts', function () {
        this.route('index');
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
        this.route('resident-index', {
          path: ':id/resident-index'
        });
        this.route('resident-new', {
          path: ':id/resident-new'
        });
        this.route('resident-edit', {
          path: ':id/resident-edit'
        });
      });
      this.route('roles', function () {
        this.route('new');
        this.route('permissions');
        this.route('edit', {
          path: ':name/edit'
        });
      });
      this.route('shifts', function () {
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('units', function () {
        this.route('new');
        this.route('edit', {
          path: ':id/edit'
        });
      });
      this.route('notifications', function () {
        this.route('new');
      });
      this.route('messages', function () {
        this.route('inbox');
        this.route('sent');
        this.route('new');
        this.route('view', {
          path: ':id/view'
        });
      });
    });
    this.route('about');
    this.route('permissions');
  });
  var _default = Router;
  _exports.default = _default;
});
define("careshare/routes/after-login/messages/sent", ["exports", "ember-cli-pagination/remote/route-mixin", "careshare/mixins/messages-and-notifications"], function (_exports, _routeMixin, _messagesAndNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_routeMixin.default, _messagesAndNotifications.default), {
    session: Ember.inject.service('session'),
    page: 1,
    perPage: 20,
    beforeModel: function beforeModel() {
      this.initMessagesListUpdate();
    },
    model: function model(params) {
      params.expand = 'resident,from,to';
      params.type = 'sent';
      return this.findPaged('message', params);
    },
    actions: {
      view: function view(id) {
        this.transitionTo('after-login.messages.view', id);
      },
      openMessageModal: function openMessageModal(subject, body) {
        var afterLoginController = this.controllerFor('after-login');
        afterLoginController.set('messageModalTitle', 'Forward');
        afterLoginController.set('newMessageModal', true);
        afterLoginController.set('messageSubject', subject);
        afterLoginController.set('messageBody', body);
        afterLoginController.set('isForwardMessage', true);
        afterLoginController.set('recipientId', null);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'select',
    classNames: 'form-control time-picker',
    didInsertElement: function didInsertElement() {
      this.$().val(this.get('selected'));
    }
  });

  _exports.default = _default;
});
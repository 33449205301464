define("careshare/utils/zindex", ["exports", "ember-dialog/utils/zindex"], function (_exports, _zindex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "max", {
    enumerable: true,
    get: function get() {
      return _zindex.max;
    }
  });
});
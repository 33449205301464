define("careshare/models/additional-detail", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    middlename: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    maidenname: _emberData.default.attr('string'),
    full_name: Ember.computed('firstname', 'lastname', function () {
      return "".concat(this.get('firstname'), " ").concat(this.get('lastname'));
    }),
    practising: _emberData.default.attr('number'),
    dob: _emberData.default.attr('date-format'),
    gender: _emberData.default.attr('string'),
    sexuality: _emberData.default.attr('string'),
    nationality: _emberData.default.attr('string'),
    doa: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    admitted_from: _emberData.default.attr('string'),
    previous_address: _emberData.default.attr('string'),
    care_reason: _emberData.default.attr('string'),
    last_review_date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    next_plan_date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    status: _emberData.default.attr('string'),
    admission_type: _emberData.default.attr('string'),
    capacity: _emberData.default.attr('string'),
    s47: _emberData.default.attr('string'),
    guardian: _emberData.default.attr('number'),
    financial_poa: _emberData.default.attr('string'),
    welfare_poa: _emberData.default.attr('string'),
    financial_guardian: _emberData.default.attr('string'),
    welfare_guardian: _emberData.default.attr('string'),
    fund_managed: _emberData.default.attr('string'),
    pocket_money: _emberData.default.attr('string'),
    main_contact: _emberData.default.attr('string'),
    main_contact_phone: _emberData.default.attr('string'),
    unit: _emberData.default.belongsTo('unit'),
    unit_id: _emberData.default.attr('number'),
    room: _emberData.default.attr('string'),
    religion: _emberData.default.attr('string'),
    weight: _emberData.default.attr('string'),
    height: _emberData.default.attr('string'),
    eye_color: _emberData.default.attr('string'),
    hair_color: _emberData.default.attr('string'),
    inventory: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    contact_details: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    contact_family_details: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    communication: _emberData.default.attr(),
    communication_methods: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    communication_languages: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    communication_interactions: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    communication_aids: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    communication_impairments: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    end_of_life: _emberData.default.attr(),
    medical: _emberData.default.attr(),
    medical_conditions: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    mobility: _emberData.default.attr(),
    nutrition: _emberData.default.attr(),
    reminiscence: _emberData.default.attr(),
    type_of_diet: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    nutritional_diagnosis: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    nutritional_aids: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    nutritional_assistance: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    restraint_types: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    sleeping_details: _emberData.default.attr(),
    personal_care: _emberData.default.attr(),
    continence_details: _emberData.default.attr(),
    skin_details: _emberData.default.attr(),
    discharge: _emberData.default.attr(),
    discharge_reasons: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    allergies_details: _emberData.default.attr(),
    allergies: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    skin_integrity: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    bedroom_assistance: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    safety_equipments: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    night_times: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    key_details_valid: _emberData.default.attr('number'),
    additional_details_valid: _emberData.default.attr('number'),
    contact_details_valid: _emberData.default.attr('number'),
    contact_family_details_valid: _emberData.default.attr('number'),
    communication_details_valid: _emberData.default.attr('number'),
    eol_details_valid: _emberData.default.attr('number'),
    medical_details_valid: _emberData.default.attr('number'),
    communication_eol_medical_details_valid: _emberData.default.attr('number'),
    mobility_details_valid: _emberData.default.attr('number'),
    nutrition_details_valid: _emberData.default.attr('number'),
    reminiscence_details_valid: _emberData.default.attr('number'),
    mobility_nutrition_details_valid: _emberData.default.attr('number'),
    sleeping_details_valid: _emberData.default.attr('number'),
    percare_details_valid: _emberData.default.attr('number'),
    preferences_details_valid: _emberData.default.attr('number'),
    continence_details_valid: _emberData.default.attr('number'),
    sleeping_percare_preferences_continence_details_valid: _emberData.default.attr('number'),
    skin_details_valid: _emberData.default.attr('number'),
    allergies_details_valid: _emberData.default.attr('number'),
    skin_allergies_discharge_details_valid: _emberData.default.attr('number'),
    submitted: _emberData.default.attr('number'),
    oxygen: _emberData.default.attr('string'),
    nebuliser: _emberData.default.attr('string'),
    epipen: _emberData.default.attr('string'),
    cannula: _emberData.default.attr('string'),
    suction: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/components/nutrition-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    type_of_diet_items: [ListItem.create({
      item_description: "Dairy Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Food cut up",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Fortified drinks",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Fortified food",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Gluten Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Halal",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Fibre",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Kosher",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Low salt",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Normal",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "PEG feed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pureed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Soft",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Thickening fluids",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Vegan",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Vegetarian",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Weight Reduction",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Wheat Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    nutritional_diagnosis_items: [ListItem.create({
      item_description: "Allergy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Anaemia",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Celiac",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Constipation",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Diabetic",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Blood Pressure",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Cholestorol",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "IBS",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Lactose Intolerant",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Low Blood Pressure",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    nutritional_aids_items: [ListItem.create({
      item_description: "Special Cutlery",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Special Cup",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    nutritional_assistance_items: [ListItem.create({
      item_description: "Eating - Full",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Eating - Partial",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Drinking - Full",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Drinking - Partial",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('type_of_diet');
      this.initCheckboxesList('nutritional_diagnosis');
      this.initCheckboxesList('nutritional_aids');
      this.initCheckboxesList('nutritional_assistance');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('nutritionWasChanged', 'nutritionalDiagnosisWasChanged', 'nutritionalAidsWasChanged', 'nutritionalAssistanceWasChanged', 'typeOfDietWasChanged', function () {
      if (this.get('nutritionWasChanged') || this.get('nutritionalDiagnosisWasChanged') || this.get('nutritionalAidsWasChanged') || this.get('nutritionalAssistanceWasChanged') || this.get('typeOfDietWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    nutritionWasChanged: Ember.computed('model.nutrition.hasDirtyAttributes', function () {
      return this.get('model.nutrition.hasDirtyAttributes');
    }),
    nutritionalDiagnosisWasChanged: Ember.computed('model.nutritional_diagnosis.[]', 'original_nutritional_diagnosis', function () {
      return !_underscore.default.isEqual(this.get('model').get('nutritional_diagnosis').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_nutritional_diagnosis').sort());
    }),
    nutritionalAidsWasChanged: Ember.computed('model.nutritional_aids.[]', 'original_nutritional_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('nutritional_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_nutritional_aids').sort());
    }),
    nutritionalAssistanceWasChanged: Ember.computed('model.nutritional_assistance.[]', 'original_nutritional_assistance', function () {
      return !_underscore.default.isEqual(this.get('model').get('nutritional_assistance').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_nutritional_assistance').sort());
    }),
    typeOfDietWasChanged: Ember.computed('model.type_of_diet.[]', 'original_type_of_diet', function () {
      return !_underscore.default.isEqual(this.get('model').get('type_of_diet').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_type_of_diet').sort());
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#nutrition-details').offset().top - 58);
      });
    },
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.mobility').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
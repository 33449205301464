define("careshare/mixins/graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    weightData: function weightData(indata, inlabels) {
      return {
        labels: inlabels,
        datasets: [{
          label: 'weight',
          data: indata,
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    },
    moodData: function moodData(indata, inlabels) {
      return {
        labels: inlabels,
        datasets: [{
          label: 'mood',
          data: indata,
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/mobility-details", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/admission-validate"], function (_exports, _environment, _serializeData, _admissionValidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_serializeData.default, _admissionValidate.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    queryParams: {
      view: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        adapterOptions: {
          expand: 'inventory'
        }
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/mobility-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        self.serializeNestedRecordFor(model, 'mobility', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'restraint-types', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'hoist-types', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'assistance-transfers', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'bedroom-assistance', data[0]['data']);
        model.set('weights', data[1]['weights']);
        model.set('weights_dates', data[1]['dates']);
        model.set('moods', data[2]['moods']);
        model.set('moods_dates', data[2]['dates']);
        self.controllerFor('after-login.residents.mobility-details').set('original_bedroom_assistance', model.get('bedroom_assistance').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.mobility-details').set('original_restraint_types', model.get('restraint_types').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.mobility-details').set('original_hoist_types', model.get('hoist_types').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.mobility-details').set('original_assistance_transfers', model.get('assistance_transfers').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    saveData: function saveData() {
      var self = this;
      var resident = this.modelFor(this.routeName);
      var formattedData = {};
      formattedData['mobility'] = resident.get('mobility');
      formattedData['restraint_types'] = resident.get('restraint_types');
      formattedData['hoist_types'] = resident.get('hoist_types');
      formattedData['assistance_transfers'] = resident.get('assistance_transfers');
      formattedData['bedroom_assistance'] = resident.get('bedroom_assistance');
      formattedData['user_id'] = this.get('session.data.authenticated.user_id');
      this.controllerFor('after-login.residents.mobility-details').set('errors', {});
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/mobility-details/update/' + resident.get('id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: JSON.stringify(formattedData)
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      save: function save(modelSaved) {
        var _this = this;

        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          return false;
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(this.saveData()).done(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          resident.get('mobility').save();
          resident.reload();
          self.controllerFor('after-login.residents.mobility-details').set('original_bedroom_assistance', resident.get('bedroom_assistance').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.mobility-details').set('original_restraint_types', resident.get('restraint_types').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.mobility-details').set('original_hoist_types', resident.get('hoist_types').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.mobility-details').set('original_assistance_transfers', resident.get('assistance_transfers').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved');
          Ember.$('.modal-window.success').removeClass('hidden');

          if (resident.get('submitted') == 1) {
            self.transitionTo('after-login.residents.mobility-details', resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          }
        }).catch(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });

          _this.controllerFor('after-login.residents.mobility-details').set('errors', response.responseJSON);

          resident.set('mobility_details_valid', 0);
        });
      },
      back: function back(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.medical-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      commit: function commit(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          if (this.isAllTabsValid(resident)) {
            var requestOptions = {
              url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/residents/submit?resident_id=' + resident.get('id') + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
              dataType: 'JSON',
              method: 'PUT',
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
              }
            };
            Ember.$.ajax(requestOptions).then(function (data) {
              resident.set('submitted', data.submitted);
            });
            self.controllerFor('after-login').set('dialogMessage', this.registerResident());
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.residents');
          } else {
            self.controllerFor('after-login').set('dialogMessage', this.notValidMessage());
            Ember.$('.modal-window.error').removeClass('hidden');
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSectionFirst());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      next: function next(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.nutrition-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      goTo: function goTo(transition, modelSaved, view) {
        var self = this;
        var resident = this.modelFor(this.routeName);
        if (this.routeName == transition) transition = "after-login.residents.admission-details";

        if (modelSaved) {
          if (view == 1) {
            self.transitionTo(transition, resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          } else {
            self.transitionTo(transition, resident.get('id'));
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      }
    }
  });

  _exports.default = _default;
});
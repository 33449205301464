define("careshare/helpers/last", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    changed: Ember.observer('array.[]', function () {
      this.recompute();
    }),
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          array = _ref2[0];

      if (!Ember.isArray(array)) {
        return undefined;
      }

      this.set('array', array);
      return Ember.A(array).objectAt(Ember.A(array).get('length') - 1);
    }
  });

  _exports.default = _default;
});
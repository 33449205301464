define("careshare/controllers/after-login/outcomes/new", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["resident_id", "outcome_type"],
    resident_id: '',
    outcome_type: '',
    additionalOutcomes: Ember.computed('outcomes.[]', function () {
      var outcomes = _underscore.default.rest(this.get('outcomes'));

      if (this.get('outcomes').length > 0) {
        return outcomes;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/roles/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    dialog: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      name: {
        refreshModel: true
      },
      description: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.findPaged('role', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.roles.index').get('sortBy');
        var direction = self.controllerFor('after-login.roles.index').get('sortDirection') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.table-responsive').find('a').removeClass('top');
        Ember.$('.table-responsive').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    actions: {
      add: function add() {
        this.transitionTo('after-login.roles.new');
      },
      edit: function edit(name) {
        this.transitionTo('after-login.roles.edit', name);
      },
      delete: function _delete(name) {
        var _this = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        var self = this;
        promise.then(function () {
          Ember.run.later('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });

          _this.get('store').findRecord('role', name, {
            backgroundReload: false
          }).then(function (role) {
            role.destroyRecord().then(function () {
              self.refresh();
              Ember.run.later('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
            });
          });
        });
        promise.catch(function () {});
      }
    }
  });

  _exports.default = _default;
});
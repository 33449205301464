define("careshare/routes/after-login/reports/index", ["exports", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _routeMixin, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _fileSaver.default, {
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 10,
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.findPaged('document', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.$('.loader-wrapper').addClass('hidden');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('urlToUploads', _environment.default.host + '/uploads/');
      controller.categoryChanged(null, null);
    },
    fetchReportData: function fetchReportData() {
      var self = this;
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/residents/xls',
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      residentDetailsReport: function residentDetailsReport() {
        var self = this;
        Ember.$.when(self.fetchReportData()).done(function (raw) {
          var data = Array();

          if (raw.length > 0) {
            data.push(Object.keys(raw[0]));

            for (var i = 1; i < raw.length; i++) {
              data.push(Object.values(raw[i]));
            }
          }

          var worksheet = XLSX.utils.aoa_to_sheet(data);
          var workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'resident_details');
          var blob = new Blob([XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array'
          })], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
          self.saveFileAs('resident_details.xlsx', blob, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        });
      }
    }
  });

  _exports.default = _default;
});
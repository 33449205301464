define("careshare/components/document-edit", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header',
    uploader: null,
    selectedFiles: [],
    selectedCategory: null,
    currentTypes: null,
    selectedType: null,
    selectedResident: null,
    categories: [{
      name: 'CI DOCUMENTS',
      types: ['CI Reports', 'Recommendations', 'Requirements', 'Condition Notices', 'Improvement Notices', 'Enforcements', 'Emergency Condition Notices', 'Action Plans', 'Complaints', 'Miscellaneous']
    }, {
      name: 'ORGANISATION INFO',
      types: ['Organisational Chart', 'Newsletters', 'Minutes of  Resident Meetings', 'Questionniares', 'Fire Evacuation Plan', 'Safety Certificates', 'Menus', 'Miscellaneous']
    }, {
      name: 'STAFF DOCUMENTS',
      types: ['Staff Handbook', 'Internal Procedures', 'Minutes of Staff Meetings', 'Sector Guidelines', 'Health & Safety', 'Maintenance', 'Staff Rotas', 'Staff News', 'Audits', 'Miscellaneous', 'Shift Summaries']
    }, {
      name: 'RESIDENT DOCUMENTS',
      types: ['Care Plans', 'Risk Assessments', 'Admission Form', 'Occupational Health', 'SSKINS Plan', 'DNACPR', 'AWI', 'Consent to refuse hospital admission ', 'Consent to Restraints', 'PRN Purpose & Outcome Protocol', 'Nutritional Monitoring', 'Legal Authority', 'Personal Expenses Agreement', 'Expenditure/Funds Record', 'Duty of Candour']
    }],
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      var uploader = this.$('#inputId').fileupload({
        dataType: 'text',
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/documents',
        singleFileUploads: false,
        formData: function formData() {
          return self.$('#myform').serializeArray();
        },
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        complete: function complete() {
          var previousResident = self.get('previousResident') === null ? '' : self.get('previousResident');
          self.get('router').transitionTo('after-login.documents.index', {
            queryParams: {
              page: 1,
              perPage: 10,
              category: self.get('previousCategory'),
              type: self.get('previousType'),
              resident_id: previousResident,
              sortBy: 'created_at',
              direction: '',
              refresh: ''
            }
          });
        },
        done: function done(e, data) {
          self.sendAction('uploaded', data.result);
        },
        fail: function fail(e, data) {
          console.log('fail, e: ', e);
          console.log('fail, data: ', data);
          self.sendAction('failed', data.result);
        },
        fileuploadprocessfail: function fileuploadprocessfail(e, data) {
          console.log('fileuploadprocessfail');
        },
        add: function add(e, data) {
          var types = /(\.|\/)(pdf)$/i;
          var files = data.files;

          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var categoryVal = self.$('#category').val();
            var typeVal = self.$('#type').val();
            var residentIdVal = self.$('#resident_id').val();

            if (types.test(file.type) || types.test(file.name) && !Ember.isEmpty(categoryVal) && !Ember.isEmpty(typeVal) && !Ember.isEmpty(residentIdVal)) {
              self.set('selectedFiles', files);
              $('.btn-pink').off('click').on('click', function () {
                data.process().done(function () {
                  data.submit();
                });
              });
            } else {
              self.send('warning', "Unfortunately, we don't support that file type. Try again with a PDF!");
              return;
            }
          }
        },
        submit: function submit(e, data) {}
      });
      this.set('uploader', uploader);
      this.$('.resident-field').hide();
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
    },
    modelWasChanged: Ember.observer('selectedCategory', 'selectedType', 'selectedResident', 'selectedFiles', function () {
      var selectedCategory = this.get('selectedCategory');
      var selectedType = this.get('selectedType');
      var selectedFiles = this.get('selectedFiles');

      if (!Ember.isEmpty(selectedCategory) && !Ember.isEmpty(selectedType) && !Ember.isEmpty(selectedFiles)) {
        Ember.$('.btn-pink').removeClass('disabled');
      } else {
        Ember.$('.btn-pink').addClass('disabled');
      }
    }),
    actions: {
      selectCategory: function selectCategory(value) {
        if (!Ember.isEmpty(value)) {
          if (value === 'RESIDENT DOCUMENTS') {
            Ember.$('.resident-field').slideDown();
          } else {
            Ember.$('.resident-field').slideUp();
          }

          this.set('selectedCategory', value);
          var types = this.get('categories').filterBy('name', value);
          this.set('currentTypes', types[0].types);
        } else {
          this.set('selectedType', '');
          this.set('currentTypes', []);
        }
      },
      selectType: function selectType(value) {
        if (!Ember.isEmpty(value)) {
          this.set('selectedType', value);
        } else {
          this.set('selectedType', '');
        }
      },
      warning: function warning(text) {
        this.sendAction('warning', text);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/residents/skin-allergies-discharge-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8YjdVjN/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"skin-allergies-discharge-details\",null,[[\"errors\",\"model\",\"original_skin_integrity\",\"original_allergies\",\"original_discharge_reasons\",\"goTo\",\"back\",\"commit\",\"save\"],[[20,[\"errors\"]],[20,[\"model\"]],[20,[\"original_skin_integrity\"]],[20,[\"original_allergies\"]],[20,[\"original_discharge_reasons\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"commit\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"save\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/skin-allergies-discharge-details.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/components/date-picker", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent; padding: 0',
    didInsertElement: function didInsertElement() {
      var self = this;
      this.$("input").datepicker({
        changeMonth: true,
        changeYear: true,
        yearRange: self.get('yearRange'),
        dateFormat: "dd.mm.yy",
        defaultDate: (0, _moment.default)().format('DD.MM.YYYY'),
        onSelect: function onSelect(date) {
          self.set('value', date);
        }
      }).keydown(function (e) {
        if (e.keyCode == 8 || e.keyCode == 46) {
          self.$("input").datepicker('setDate', '');
          self.set('value', '');
        }
      });

      if (this.name && this.name.indexOf('_from') !== -1) {
        self.$("input").datepicker('setDate', (0, _moment.default)().subtract(12, 'years').format('DD.MM.YYYY'));
        self.set('value', (0, _moment.default)().subtract(12, 'years').format('DD.MM.YYYY'));
      } else {
        this.$("input").datepicker('setDate', this.get('value'));
      }

      this.$("input").attr("autocomplete", "off");
      this.$('span.fa').click(function (e) {
        e.preventDefault();
        self.$("input").datepicker("show");
      });
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/monitoring/index", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-cli-pagination/remote/route-mixin", "moment", "careshare/config/environment", "careshare/utils/convert-time-to-number", "careshare/utils/convert-number-to-time"], function (_exports, _toConsumableArray2, _routeMixin, _moment, _environment, _convertTimeToNumber, _convertNumberToTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    page: 1,
    perPage: 10,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      slug: {
        refreshModel: true
      },
      perPage: {
        refreshModel: true
      },
      orderBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      },
      showForm: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        Ember.$('.ui-tooltip').addClass('hidden');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('slug', this.paramsFor(this.routeName).slug);
      controller.set('showAddForm', this.paramsFor(this.routeName).showForm === 'true'); // Convert time values back to HH:mm if the slug is 'positioning'

      if (controller.get('slug') === 'positioning') {
        model.forEach(function (item) {
          var value = item.get('value');

          if (Number.isInteger(value)) {
            item.set('value', (0, _convertNumberToTime.default)(value));
          }
        });
      }
    },
    model: function model(params) {
      var datePattern = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

      if (datePattern.test(params.date_from)) {
        params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }

      if (datePattern.test(params.date_to)) {
        params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');
      }

      if (!params.date_to) {
        params.date_to = (0, _moment.default)().format('YYYY-MM-DD');
      }

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
        return false;
      }

      return this.findPaged('monitoring', params);
    },
    allRequred: function allRequred(self) {
      self.controllerFor('after-login').set('dialogMessage', 'All fields are required!');
      Ember.$('.modal-window.error').removeClass('hidden');
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return;
    },
    afterModel: function afterModel(model, transition) {
      var store = this.store;
      var record = store.createRecord('monitoring', {
        user_id: this.get('session.data.authenticated.user_id')
      });
      this.controllerFor('after-login.monitoring.index').set('monitorings', []);
      this.controllerFor('after-login.monitoring.index').get('monitorings').pushObject(record);

      if (transition.queryParams.resident_id) {
        this.controllerFor('after-login.monitoring.index').get('monitorings')[0].set('resident_id', transition.queryParams.resident_id);
      }

      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      saveItemToRoute: function saveItemToRoute(newItem) {
        var self = this;
        var user_id = self.get('session.data.authenticated.user_id');
        var token = self.get('session').get('data.authenticated.access_token');
        var slug = self.controller.get('slug') || self.controller.get('selectedType');

        if (!user_id || !token) {
          self.controllerFor('after-login').set('dialogMessage', 'Not logged in!');
          Ember.$('.modal-window.success').removeClass('hidden');
          return;
        }

        if (slug !== 'positioning') {
          if (!newItem.time) {
            this.allRequred(self);
            return;
          }
        } else {
          if (!newItem.value) {
            this.allRequred(self);
            return;
          }
        } // Construct request data including Abbey Pain Scale for 'pain' slug


        var data = {
          user_id: user_id,
          resident_id: newItem.resident,
          date: (0, _moment.default)(newItem.date, 'DD.MM.YYYY').format('YYYY-MM-DD') + ' 00:00:00',
          time: newItem.time,
          value: newItem.value ? newItem.value : newItem.valueText ? newItem.valueText : newItem.valueSelect ? newItem.valueSelect : null,
          slug: slug
        }; // Include Abbey Pain Scale data if the slug is 'pain'

        if (slug === 'pain') {
          data.abbey_pain_scale = {
            vocalisation: newItem.abbey_pain_scale.vocalisation,
            facial_expression: newItem.abbey_pain_scale.facial_expression,
            body_language: newItem.abbey_pain_scale.body_language,
            behavioural_change: newItem.abbey_pain_scale.behavioural_change,
            physiological_change: newItem.abbey_pain_scale.physiological_change,
            physical_changes: newItem.abbey_pain_scale.physical_changes
          };
        } // Directly make the AJAX request without creating and saving an Ember Data record


        this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/monitorings/create', {
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify(data),
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(function () {
          self.set('showAddForm', false);
          self.set('selectedType', null);
          self.send('refreshRoute');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function (error) {
          self.controllerFor('after-login').set('dialogMessage', 'All fields are required!');
          Ember.$('.modal-window.error').removeClass('hidden');
          Ember.run.next(function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      addUpdate: function addUpdate() {
        var resident = this.controllerFor('after-login.monitoring.index').get('resident_id');
        this.transitionTo('after-login.monitoring.new', {
          queryParams: {
            resident_id: resident
          }
        });
      },
      editMonitoring: function editMonitoring(id) {
        this.transitionTo('after-login.monitoring.edit', id);
      },
      createPdf: function createPdf() {
        var self = this;
        var resident = this.controllerFor('after-login.monitoring.index').get('resident_id');
        var dateFrom = this.controllerFor('after-login.monitoring.index').get('date_from');
        var dateTo = this.controllerFor('after-login.monitoring.index').get('date_to');
        dateFrom = new Date(dateFrom.split(".").reverse().join("-")).getTime() / 1000;
        dateTo = new Date(dateTo.split(".").reverse().join("-")).getTime() / 1000;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        var requestOptions = {
          url: "".concat(_environment.default.host, "/v1/monitorings/pdf?resident_id=").concat(resident, "&date_from=").concat(dateFrom, "&date_to=").concat(dateTo, "&user_id=").concat(self.get('session').get('data.authenticated.user_id')),
          dataType: 'JSON',
          method: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(self.get('session').get('data.authenticated.access_token')));
          }
        };
        Ember.$.ajax(requestOptions).done(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Document successfully saved to documents!');
          Ember.$('.modal-window.success').removeClass('hidden');
        });
      },
      print: function print() {
        var self = this;
        this.controllerFor('after-login.monitoring.index').set('printView', true);
        var slug = this.controllerFor('after-login.monitoring.index').get('slug');
        var capitalizedSlug = '';

        if (slug) {
          var firstLetter = slug.charAt(0).toUpperCase();
          var remainingLetters = slug.slice(1);
          capitalizedSlug = firstLetter + remainingLetters;
        }

        var resident = this.controllerFor('after-login.monitoring.index').get('resident');
        var resident_id = this.controllerFor('after-login.monitoring.index').get('resident_id');
        var date_from = this.controllerFor('after-login.monitoring.index').get('date_from');
        var date_to = this.controllerFor('after-login.monitoring.index').get('date_to');
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        this.get('ajax').request("".concat(_environment.default.host, "/v1/monitorings?resident_id=").concat(resident_id, "&date_from=").concat(date_from, "&date_to=").concat(date_to, "&per_page=all"), {
          method: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(self.get('session').get('data.authenticated.access_token')));
          }
        }).then(function (data) {
          self.controllerFor('after-login.monitoring.index').set('monitoringsForPrint', data.monitorings);
          $('.forPrint').printThis({
            importCSS: true,
            loadCSS: "",
            header: "<h1>".concat(capitalizedSlug.length > 0 ? capitalizedSlug : 'Monitoring', " Log Report  ").concat(resident ? 'for ' + resident : '', " ").concat(date_from, " - ").concat((0, _moment.default)(date_to, 'YYYY-MM-DD').format('DD.MM.YYYY'), "</h1>")
          });
          Ember.run.next(function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        }).catch(function (error) {
          console.error('Error fetching data for print:', error);
          Ember.run.next(function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      remove: function remove(id) {
        var _this = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          var self = _this;

          var monitoringModels = _this.controllerFor('after-login.monitoring.index').get('monitorings');

          var requestOptions = {
            url: "".concat(_environment.default.host, "/v1/monitorings/").concat(id),
            method: 'DELETE',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', "Bearer ".concat(self.get('session').get('data.authenticated.access_token')));
            }
          };
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.ajax(requestOptions).done(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.refresh();
          }).catch(function (response) {
            for (var i = 0; i < monitoringModels.length; i++) {
              var errors = response.responseJSON[i].errors;

              monitoringModels[i]._internalModel.clearErrorMessages();

              if (errors) {
                monitoringModels[i]._internalModel.adapterDidInvalidate(errors);
              }
            }

            self.controllerFor('after-login.monitoring.index').set('monitorings', (0, _toConsumableArray2.default)(monitoringModels));
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').removeClass('hidden');
            });
          });
        });
        promise.catch(function () {});
      },
      saveData: function saveData() {
        var self = this;
        var monitoringModels = this.controllerFor('after-login.monitoring.index').get('monitorings');
        var slug = this.controllerFor('after-login.monitoring.index').get('slug'); // Construct request data including Abbey Pain Scale for 'pain' slug

        var requestData = monitoringModels.map(function (model) {
          var data = {
            id: model.get('id'),
            user_id: model.get('user_id'),
            monitoring_id: model.get('monitoring_id'),
            resident_id: model.get('resident_id'),
            date: model.get('date'),
            value: model.get('value'),
            slug: model.get('slug')
          }; // Include Abbey Pain Scale data if the slug is 'pain'

          if (slug === 'pain') {
            data.abbey_pain_scale = {
              vocalisation: model.get('vocalisation'),
              facialExpression: model.get('facialExpression'),
              bodyLanguage: model.get('bodyLanguage'),
              behaviouralChange: model.get('behaviouralChange'),
              physiologicalChange: model.get('physiologicalChange'),
              physicalChanges: model.get('physicalChanges')
            }; // Calculate total pain score and set it as value

            var totalPainScore = model.get('vocalisation') + model.get('facialExpression') + model.get('bodyLanguage') + model.get('behaviouralChange') + model.get('physiologicalChange') + model.get('physicalChanges');
            data.value = totalPainScore;
          }

          return data;
        });
        var requestOptions = {
          url: "".concat(_environment.default.host, "/v1/monitorings"),
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', "Bearer ".concat(self.get('session').get('data.authenticated.access_token')));
          },
          data: JSON.stringify(requestData)
        };
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.ajax(requestOptions).done(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.refresh();
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function (response) {
          for (var i = 0; i < monitoringModels.length; i++) {
            var errors = response.responseJSON[i].errors;

            monitoringModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              monitoringModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.monitoring.index').set('monitorings', (0, _toConsumableArray2.default)(monitoringModels));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
        });
      },
      showPainScaleRoute: function showPainScaleRoute(itemId) {
        var controller = this.controllerFor('after-login.monitoring.index');
        controller.set('isLoadingPainScale', true);
        controller.set('showPainScaleModal', true);
        return this.get('ajax').request("".concat(_environment.default.host, "/v1/monitorings/").concat(itemId, "/pain-scale"), {
          method: 'GET',
          headers: {
            'Authorization': "Bearer ".concat(this.get('session.data.authenticated.access_token'))
          }
        }).then(function (response) {
          controller.set('selectedPainScale', response.abbey_pain_scale);
          controller.set('isLoadingPainScale', false);
        }).catch(function (error) {
          console.error('Error fetching pain scale:', error);
          controller.set('isLoadingPainScale', false); // Optionally, set an error message on the controller

          controller.set('painScaleError', 'Failed to load pain scale data');
        });
      },
      closePainScaleModalRoute: function closePainScaleModalRoute() {
        var controller = this.controllerFor('after-login.monitoring.index');
        controller.set('showPainScaleModal', false);
        controller.set('selectedPainScale', null);
        controller.set('painScaleError', null);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/adverse-event-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tagName: 'div',
    classNames: 'screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header',
    locations: ["Bath", "Bathroom", "Bedroom", "Conservatory", "Corridor", "Dining room", "Ensuite", "Hall/Lobby", "Library", "Lift", "Lounge", "Off Site", "Outside", "Reception", "Seating Area", "Shower", "Stairs", "Toilet", "Other"],
    severities: ["Minor", "Minimal", "Moderate", "Major", "Serious", "Other"],
    notifications: [],
    oneSecondInMs: 1000,
    init: function init() {
      this._super.apply(this, arguments);

      var model = this.get('model');
      this.set('isCiDisabled', model.get('ci'));
      this.set('isDcDisabled', model.get('dc'));
      this.set('isGpDisabled', model.get('gp'));
      this.set('isRaDisabled', model.get('ra'));
    },
    createNote: function createNote(model) {
      var note = this.get('store').createRecord('adverse-event-note');
      note.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('adverse_notes').pushObject(note);
    },
    createStaff: function createStaff(model) {
      var staff = this.get('store').createRecord('adverse-event-user');
      staff.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('adverse_users').pushObject(staff);
    },
    actions: {
      addNote: function addNote(model) {
        this.createNote(model);
        Ember.$('.scroll-col1').animate({
          scrollTop: $('.scroll-col1').prop('scrollHeight')
        }, this.get('oneSecondInMs'));
      },
      removeNote: function removeNote(model, note) {
        model.get('adverse_notes').removeObject(note);
        this.get('store').deleteRecord(note);
      },
      addStaff: function addStaff(model) {
        this.createStaff(model);
        Ember.$('.scroll-col2').animate({
          scrollTop: $('.scroll-col2').prop('scrollHeight')
        }, this.get('oneSecondInMs'));
      },
      removeStaff: function removeStaff(model, user) {
        model.get('adverse_users').removeObject(user);
        this.get('store').deleteRecord(user);
      },
      setNotification: function setNotification(model, type) {
        var notifications = this.get('notifications');

        if (notifications.includes(type)) {
          notifications = notifications.without(type);
        } else {
          notifications.push(type);
        }

        model.set('notifications', notifications);
      }
    }
  });

  _exports.default = _default;
});
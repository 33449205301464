define("careshare/models/infection-resident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    resident_details: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    date_infected: _emberData.default.attr('date-format'),
    date_recovered: _emberData.default.attr('date-format'),
    date_deceased: _emberData.default.attr('date-format'),
    recovered: _emberData.default.attr('number'),
    deceased: _emberData.default.attr('number')
  });

  _exports.default = _default;
});
define("careshare/components/resident-activity-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hours: Ember.computed(function () {
      return new Array(24).fill().map(function (_, index) {
        return index;
      });
    }),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header',
    residentList: Ember.A(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    createResident: function createResident(model) {
      var resident = this.get('store').createRecord('activity-resident');
      resident.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('activityResidents').pushObject(resident);
    },
    disablePastEndTimes: function disablePastEndTimes(startTime, $options) {
      $options.each(function (key, option) {
        var optionValue = $(option).val();
        optionValue = parseInt(optionValue.replace(':', ''));
        $(option).attr('disabled', optionValue <= startTime);
      });
    },
    actions: {
      addResident: function addResident(model) {
        this.createResident(model);
        Ember.$('.scroll-cell').animate({
          scrollTop: $('.scroll-cell').prop('scrollHeight')
        }, 1000);
        Ember.run.later('afterRender', function () {
          Ember.$('.mood-label').find('i').removeClass('fa').addClass('fas');
        });
      },
      removeResident: function removeResident(model, resident) {
        model.get('activityResidents').removeObject(resident);
        this.get('store').deleteRecord(resident);
      },
      deleteResident: function deleteResident(id) {
        var elem = this.get('model').get('activityResidents').findBy('id', id);
        this.get('model').get('activityResidents').removeObject(elem);
      },
      warning: function warning(text) {
        this.sendAction('warning', text);
      },
      print: function print() {
        var self = this;
        $('.forPrint').printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h1>Activity</h1>"
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/roles/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "asz1bWB+",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"admin-header\",null,[[\"title\",\"back\",\"active\"],[\"Permissions\",\"/admin\",\"perm\"]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"Permissions\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[6,\"form\"],[9,\"class\",\"form-scroll form-scroll_footer user-edit-content2\"],[9,\"novalidate\",\"\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"row form-wrapper\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"fixed-header-table\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"form-col-wrapper\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\"],[4,\"role-permissions-edit\",null,[[\"model\"],[[20,[\"model\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/roles/permissions.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/services/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    skin_types: Ember.A([{
      description: "Leg Ulcer",
      grades: [{
        description: "C1"
      }, {
        description: "C2A"
      }, {
        description: "C2S"
      }, {
        description: "C3"
      }, {
        description: "C4"
      }, {
        description: "C5"
      }, {
        description: "C6"
      }]
    }, {
      description: "Surgical Wound",
      grades: [{
        description: "Class 1"
      }, {
        description: "Class 2"
      }, {
        description: "Class 3"
      }, {
        description: "Class 4"
      }]
    }, {
      description: "Diabetic Ulcer",
      grades: [{
        description: "Grade 1"
      }, {
        description: "Grade 2"
      }, {
        description: "Grade 3"
      }, {
        description: "Grade 4"
      }, {
        description: "Grade 5"
      }]
    }, {
      description: "Pressure Ulcer",
      grades: [{
        description: "Blanching Erythema"
      }, {
        description: "Grade 1"
      }, {
        description: "Grade 2"
      }, {
        description: "Grade 3"
      }, {
        description: "Grade 4"
      }, {
        description: "Deep Tissue"
      }, {
        description: "Ungradable"
      }, {
        description: "Combination Lesions"
      }]
    }, {
      description: "Abrasion",
      grades: []
    }, {
      description: "Arterial Stasis",
      grades: []
    }, {
      description: "Bruise",
      grades: []
    }, {
      description: "Cyanosis",
      grades: []
    }, {
      description: "Deep Tissue Injury",
      grades: []
    }, {
      description: "Ecchymosis",
      grades: []
    }, {
      description: "Haematoma",
      grades: []
    }, {
      description: "Incision",
      grades: []
    }, {
      description: "Jaundice",
      grades: []
    }, {
      description: "Laceration",
      grades: []
    }, {
      description: "Oedema",
      grades: []
    }, {
      description: "Rash",
      grades: []
    }, {
      description: "Scab",
      grades: []
    }, {
      description: "Skin Tear",
      grades: []
    }, {
      description: "Venus Stasis",
      grades: []
    }, {
      description: "Other",
      grades: []
    }]),
    sections: Ember.A([{
      item_description: "Allergies"
    }, {
      item_description: "Communication"
    }, {
      item_description: "Continence"
    }, {
      item_description: "Duty of Candour"
    }, {
      item_description: "End of Life/ACP"
    }, {
      item_description: "Health Conditions"
    }, {
      item_description: "Mobility"
    }, {
      item_description: "Nutrition"
    }, {
      item_description: "Personal Care"
    }, {
      item_description: "Preferences"
    }, {
      item_description: "Reminiscence"
    }, {
      item_description: "Reviews"
    }, {
      item_description: "Risk Assessment"
    }, {
      item_description: "Skin Integrity"
    }, {
      item_description: "Sleeping"
    }, {
      item_description: "Wellbeing"
    }]),
    services: Ember.A([{
      item_description: "Audiologist"
    }, {
      item_description: "Community Team"
    }, {
      item_description: "Consultant"
    }, {
      item_description: "Dentist"
    }, {
      item_description: "Dietician"
    }, {
      item_description: "Doctor"
    }, {
      item_description: "Hairdresser"
    }, {
      item_description: "Hospital"
    }, {
      item_description: "Mental Health Officer"
    }, {
      item_description: "Nutritionist"
    }, {
      item_description: "Occupational Health"
    }, {
      item_description: "Opticians"
    }, {
      item_description: "Physiotherapist"
    }, {
      item_description: "Podiatrist"
    }, {
      item_description: "Public Guardian Review"
    }, {
      item_description: "Review"
    }, {
      item_description: "Social Worker"
    }, {
      item_description: "Specialist Nurse"
    }, {
      item_description: "Speech Therapist"
    }]),
    event_types: Ember.A({
      accident: "Accident",
      fall: "Fall",
      incident: "Incident",
      dc: "DC"
    }),
    notification_reference: Ember.A({
      review: 'Reviews',
      accident: 'Accidents Register',
      fall: 'Falls Register',
      incident: 'Incidents Register'
    }),
    user_positions: Ember.A([{
      item_description: "Activities Co-ordinator"
    }, {
      item_description: "Admin Assistant"
    }, {
      item_description: "Administrator"
    }, {
      item_description: "Care Assistant"
    }, {
      item_description: "Carer"
    }, {
      item_description: "Catering Assistant"
    }, {
      item_description: "Chef/Cook"
    }, {
      item_description: "Clinical Lead"
    }, {
      item_description: "Clinical Services Manager"
    }, {
      item_description: "Deputy Manager"
    }, {
      item_description: "Domiciliary Care Assistant"
    }, {
      item_description: "Healthcare Assistant"
    }, {
      item_description: "Housekeeper"
    }, {
      item_description: "Kitchen Assistant"
    }, {
      item_description: "Maintenance"
    }, {
      item_description: "Manager"
    }, {
      item_description: "Nursing Assistant"
    }, {
      item_description: "Receptionist"
    }, {
      item_description: "Registered General Nurse"
    }, {
      item_description: "Registered Nurse"
    }, {
      item_description: "Secretary"
    }, {
      item_description: "Senior Care Assistant"
    }, {
      item_description: "Senior Care Practioner"
    }, {
      item_description: "Staff Nurse"
    }, {
      item_description: "Unit Manager"
    }, {
      item_description: "Wellbeing Co-ordinator"
    }, {
      item_description: "Other"
    }]),
    skin_actions: Ember.A([{
      item_description: "Changed Dressing"
    }, {
      item_description: "Checked Wound"
    }, {
      item_description: "Cleaned Wound"
    }, {
      item_description: "Turned"
    }, {
      item_description: "Applied Cream"
    }, {
      item_description: "Other"
    }])
  });

  _exports.default = _default;
});
define("careshare/models/skin-grades-detail", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    skin: _emberData.default.belongsTo('skin'),
    type: _emberData.default.attr('string'),
    type_other: _emberData.default.attr('string'),
    grade: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    skinActions: _emberData.default.hasMany('skin-action', {
      async: false,
      embedded: 'always',
      polymorphic: false
    })
  });

  _exports.default = _default;
});
define("careshare/components/contact-source-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent',
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contact-source-filter', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (sources) {
        var $contactSourceSelect = self.$('.contact-source');

        if (self.get('sourceList').length == 0) {
          sources.forEach(function (source) {
            self.get('sourceList').pushObject(source);
            $contactSourceSelect.append(Ember.$("<option />").val(source.id).text(source.name));
          });
        } else {
          self.get('sourceList').forEach(function (source) {
            $contactSourceSelect.append(Ember.$("<option />").val(source.id).text(source.name));
          });
        }

        Ember.run.schedule('afterRender', function () {
          $contactSourceSelect.val(self.get('source'));
          $contactSourceSelect.change(function (e) {
            if (!Ember.isEmpty($(this).val())) {
              self.set('source', $(this).find('option:selected').text());
            } else {
              self.set('source', '');
            }

            var el = $contactSourceSelect;
            var index = self.get('sourceList').findIndex(function (p) {
              return p.name == $contactSourceSelect.find('option:selected').text();
            });

            if (index == -1) {
              self.get('sourceList').pushObject({
                'name': el.find('option:selected').text()
              });
              Ember.run.next(function () {
                var list = Ember.$('.contact-source');
                list.each(function (index, item) {
                  var select = $contactSourceSelect;

                  if (select.attr('id') !== el.attr('id')) {
                    select.append(Ember.$("<option />").val(el.find('option:selected').text()).text(el.find('option:selected').text()));
                    select.trigger('chosen:updated');
                  }
                });
              });
            }
          });
          $contactSourceSelect.chosen({
            width: '110%',
            placeholder_text_single: 'Select Source',
            allow_single_deselect: true,
            create_option: self.get('addOption') ? true : false,
            persistent_create_option: true,
            skip_no_results: true
          });
          var select = $contactSourceSelect;
          select.find('option').each(function () {
            if ($(this).text() === self.get('source')) {
              $(this).prop('selected', true);
              return false;
            }
          });
          select.trigger('chosen:updated');
        });
      });
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/fancy-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zJ6tyt1l",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"type\",\"text\"],[9,\"class\",\"form-control\"],[10,\"value\",[18,\"value\"],null],[7],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/fancy-time-picker.hbs"
    }
  });

  _exports.default = _default;
});
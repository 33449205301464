define("careshare/helpers/calculate-bmi", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateBmi = calculateBmi;
  _exports.default = void 0;

  function calculateBmi(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        weight = _ref2[0],
        height = _ref2[1];

    if (!weight || !height) {
      return '';
    } // Convert height from cm to meters


    var heightInMeters = height / 100;
    var bmi = weight / (heightInMeters * heightInMeters); // Round BMI to one decimal place

    return bmi.toFixed(1);
  }

  var _default = Ember.Helper.helper(calculateBmi);

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/new", ["exports", "careshare/mixins/set-belongs-to"], function (_exports, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setBelongsTo.default, {
    session: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('residentlite');
    },
    afterModel: function afterModel(model) {
      model.inventory = [];
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      saveResident: function saveResident() {
        if (Ember.$('.btn').hasClass('disabled')) {
          return false;
        }

        var resident = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.transitionTo('after-login.residents');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        var self = this;
        self.transitionTo('after-login.residents');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/models/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    permissions: _emberData.default.hasMany('permission'),
    togglePermission: function togglePermission(data) {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      return adapter.togglePermission(data);
    }
  });

  _exports.default = _default;
});
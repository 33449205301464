define("careshare/templates/after-login/notifications/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UkT98sOg",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"form\"],[9,\"class\",\"form-scroll form-scroll_footer\"],[9,\"novalidate\",\"\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"row form-wrapper\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"col-lg-12 form-inner-column\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"form-col-wrapper\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\"],[1,[25,\"new-notification\",null,[[\"afterLoginController\"],[[20,[\"afterLoginController\"]]]]],false],[0,\"\\n\\t\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/notifications/new.hbs"
    }
  });

  _exports.default = _default;
});
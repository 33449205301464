define("careshare/components/shift-summary-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header',
    saveButton: null,
    filledInStatuses: [],
    saveButtonDisabled: false,
    init: function init() {
      var _this = this;

      this.set('model', this.get('model'));
      this.model.forEach(function (model) {
        if (!isNaN(model.id) && model.get('notes').length > 0) {
          _this.filledInStatuses.push(model.id);
        }
      });
      this.disabledCheck();

      this._super.apply(this, arguments);
    },
    initFieldsOnFiltering: function () {
      var _this2 = this;

      this.filledInStatuses.splice(0, this.filledInStatuses.length);
      this.model.forEach(function (model) {
        if (!isNaN(model.id) && model.get('notes').length > 0 && typeof model.get('mood') !== 'undefined' && model.get('mood').length > 0) {
          _this2.filledInStatuses.push(model.id);
        }
      });
      this.disabledCheck();
    }.on('didUpdate'),
    disabledCheck: function disabledCheck() {
      var enabledStatus = true;

      if (this.filledInStatuses.length !== this.get('model').length) {
        enabledStatus = false;
      }

      var lockedAmount = 0;
      this.model.forEach(function (model) {
        if (model.get('is_locked') === 1) {
          lockedAmount += 1;
        }
      });

      if (lockedAmount === this.model.length) {
        enabledStatus = false;
      }

      this.set('saveButtonDisabled', !enabledStatus);
      var disabled = this.get('saveButtonDisabled');

      if (disabled) {
        $('button#submit_report').addClass('disabled');
      } else {
        $('button#submit_report').removeClass('disabled');
      }
    },
    actions: {
      isButtonDisabled: function isButtonDisabled() {
        this.disabledCheck();
      },
      setDate: function setDate(date) {
        this.set('action', 'updateDate');
        this.sendAction('action', date);
      },
      save: function save() {
        this.sendAction('submit');
        this.set('model', this.get('model'));
      },
      print: function print() {
        var table = $('.table-striped').clone();
        table.find('.notes').each(function () {
          $(this).find('.form-group').replaceWith($(this).find('textarea').val());
        });
        table.find('.mood').each(function () {
          $(this).find('.option').each(function () {
            var rad = $(this).find('input[type=radio]');

            if (!rad.prop("checked")) {
              $(this).replaceWith("");
            } else {
              rad.replaceWith("");
            }
          });
        });
        table.printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h1>" + this.get('title') + "</h1>",
          removeInline: true
        });
      },
      createPdf: function createPdf() {
        this.sendAction('createPdf', this.get('unit'), this.get('shift'), this.get('resident_id'));
      },
      success: function success(text) {
        this.sendAction('success', text);
      },
      sort: function sort(field) {
        this.sendAction('sort', field);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/case-notes/resident-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["resident_id", "note_type"],
    resident_id: '',
    note_type: '',
    additionalNotes: Ember.computed('notes.[]', function () {
      var notes = _.rest(this.get('notes'));

      if (this.get('notes').length > 0) {
        return notes;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
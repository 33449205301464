define("careshare/templates/layouts/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taJHAV2R",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",[26,[\"ember-dialog-dialog \",[18,\"className\"],\" \",[25,\"if\",[[20,[\"substrate\"]],\"substrate\"],null]]]],[7],[0,\"\\n  \"],[6,\"div\"],[9,\"class\",\"dialog-content\"],[9,\"tabindex\",\"-1\"],[7],[0,\"\\n\\n    \"],[6,\"div\"],[9,\"class\",\"dialog-header\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"dialog-close\"],[3,\"action\",[[19,0,[]],\"accept\"]],[7],[8],[0,\"\\n      \"],[6,\"div\"],[9,\"class\",\"dialog-title\"],[7],[1,[18,\"title\"],false],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n\"],[4,\"if\",[[20,[\"templateName\"]]],null,{\"statements\":[[0,\"        \"],[6,\"div\"],[9,\"class\",\"dialog-body\"],[7],[12,[20,[\"templateName\"]],[]],[8],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"component\",[\"dialog-body\"],[[\"layout\",\"contextObject\",\"context\",\"class\"],[[20,[\"template\"]],[20,[\"contextObject\"]],[20,[\"context\"]],\"dialog-body\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"dialog-footer\"],[7],[0,\"\\n      \"],[6,\"button\"],[9,\"class\",\"btn btn__accept\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"accept\"]],[7],[0,\"OK\"],[8],[0,\"\\n    \"],[8],[0,\"\\n\\n  \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":true}",
    "meta": {
      "moduleName": "careshare/templates/layouts/alert.hbs"
    }
  });

  _exports.default = _default;
});
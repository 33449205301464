define("careshare/routes/after-login/messages/inbox", ["exports", "ember-cli-pagination/remote/route-mixin", "careshare/mixins/messages-and-notifications"], function (_exports, _routeMixin, _messagesAndNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_routeMixin.default, _messagesAndNotifications.default), {
    session: Ember.inject.service('session'),
    page: 1,
    perPage: 20,
    beforeModel: function beforeModel() {
      this.initMessagesListUpdate();
    },
    model: function model(params) {
      params.expand = 'resident,to,from';
      params.type = 'inbox';
      return this.findPaged('message', params);
    },
    actions: {
      view: function view(message) {
        this.transitionTo('after-login.messages.view', message.id);
      },
      setRead: function setRead(message) {
        var _this = this;

        message.setRead().then(function (response) {
          message.set('is_read', response.is_read);

          if (response.is_read) {
            _this.decreaseMessageCounter();
          } else {
            _this.increaseMessageCounter();
          }
        });
      },
      openMessageModal: function openMessageModal(message) {
        var afterLoginController = this.controllerFor('after-login');
        afterLoginController.set('newMessageModal', true);
        afterLoginController.set('messageModalTitle', 'Reply');
        afterLoginController.set('recipientId', message.get('from_id'));
      }
    }
  });

  _exports.default = _default;
});
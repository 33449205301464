define("careshare/routes/after-login/documents/index", ["exports", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _routeMixin, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _fileSaver.default, {
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 10,
    queryParams: {
      category: {
        refreshModel: false
      },
      type: {
        refreshModel: false
      },
      resident_id: {
        refreshModel: false
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      },
      refresh: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      this.controllerFor('after-login').set('selectedDocumentCategory', params['category']);

      if (params.category !== 'RESIDENT DOCUMENTS') {
        params.resident_id = '';
      }

      params.sortBy = 'id';
      return this.findPaged('document', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.documents.index').get('sortBy');
        var direction = self.controllerFor('after-login.documents.index').get('direction') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.table-appointment').find('a').removeClass('top');
        Ember.$('.table-appointment').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.table-appointment').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('urlToUploads', _environment.default.host + '/uploads/');
      controller.categoryChanged(null, null);
    },
    actions: {
      addDocument: function addDocument() {
        this.transitionTo('after-login.documents.new');
      },
      downloadDocument: function downloadDocument(id) {
        var _this = this;

        var self = this;
        fetch((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/documents/pdf/' + id, {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + self.get('session').get('data.authenticated.access_token')
          }
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          var base64data = data.pdf;
          var binaryData = atob(base64data);
          var arrayBuffer = new ArrayBuffer(binaryData.length);
          var uint8Array = new Uint8Array(arrayBuffer);

          for (var i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }

          var blob = new Blob([uint8Array], {
            type: 'application/pdf'
          });
          var url = URL.createObjectURL(blob);
          window.open(url);
        }).catch(function (error) {
          _this.get('dialog').alert(Ember.HTMLBars.template({
            "id": "RouDFCzR",
            "block": "{\"symbols\":[],\"statements\":[[0,\"File not found or has been deleted\"]],\"hasEval\":false}",
            "meta": {}
          }));
        });
      },
      removeDocument: function removeDocument(id) {
        var _this2 = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          Ember.run.later('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });

          _this2.get('store').findRecord('document', id, {
            backgroundReload: false
          }).then(function (document) {
            document.destroyRecord();
          });
        });
        promise.catch(function () {});
      },
      reload: function reload() {
        this.refresh();
      },
      willTransition: function willTransition(transition) {
        if (transition.targetName !== 'after-login.documents.new' && transition.targetName !== 'after-login.documents.index') {
          this.controller.set('category', '');
          Ember.run.next(function () {
            Ember.$('#documents_item').css('display', 'none');
            Ember.$('#documents_item').prev('a').find('i').addClass("fa-angle-down");
            Ember.$('#documents_item').prev('a').find('i').removeClass("fa-angle-up");
          });
        }
      }
    }
  });

  _exports.default = _default;
});
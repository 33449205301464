define("careshare/templates/components/multiselect-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "37qAz9ik",
    "block": "{\"symbols\":[\"checkbox\",\"index\",\"&default\"],\"statements\":[[4,\"each\",[[20,[\"checkboxes\"]]],null,{\"statements\":[[4,\"if\",[[22,3]],null,{\"statements\":[[0,\"    \"],[11,3,[[19,1,[\"option\"]],[19,1,[\"isSelected\"]],[19,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[6,\"li\"],[7],[0,\"\\n      \"],[6,\"label\"],[7],[0,\"\\n        \"],[1,[25,\"input\",null,[[\"type\",\"checked\",\"disabled\"],[\"checkbox\",[19,1,[\"isSelected\"]],[20,[\"disabled\"]]]]],false],[0,\"\\n          \"],[6,\"span\"],[7],[1,[19,1,[\"label\"]],false],[8],[0,\"\\n      \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/multiselect-checkboxes.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/contact-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g1lbRGzF",
    "block": "{\"symbols\":[],\"statements\":[[6,\"td\"],[9,\"class\",\"outcome-date\"],[7],[1,[20,[\"item\",\"date\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"outcome-type\"],[7],[1,[20,[\"item\",\"resident\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[7],[1,[20,[\"item\",\"type\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[7],[1,[20,[\"item\",\"source\"]],false],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"notes\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"form-group\"],[7],[0,\"\\n        \"],[6,\"textarea\"],[9,\"name\",\"details\"],[9,\"class\",\"form-control report-textarea\"],[10,\"oninput\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"item\",\"details\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[7],[1,[20,[\"item\",\"details\"]],false],[8],[0,\"\\n        \"],[6,\"span\"],[9,\"class\",\"print\"],[7],[0,\"\\n            \"],[1,[20,[\"item\",\"details\"]],false],[0,\"\\n        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8],[0,\"\\n\"],[6,\"td\"],[9,\"class\",\"edit-column\"],[7],[0,\"\\n\\t\"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"edit-button\"],[3,\"action\",[[19,0,[]],\"edit\",[20,[\"item\",\"id\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-edit fa-fw\"],[9,\"aria-hidden\",\"true\"],[7],[8],[8],[0,\"\\n\\t\"],[6,\"button\"],[9,\"type\",\"button\"],[9,\"class\",\"edit-button\"],[3,\"action\",[[19,0,[]],[20,[\"delete\"]],[20,[\"item\",\"id\"]]]],[7],[6,\"i\"],[9,\"class\",\"fa fa-trash fa-fw\"],[9,\"aria-hidden\",\"true\"],[7],[8],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/contact-item.hbs"
    }
  });

  _exports.default = _default;
});
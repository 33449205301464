define("careshare/components/mobility-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    bedroom_assistance_items: [ListItem.create({
      item_description: "Requires mobility assistance at night",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Requires assistance to move in bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Confined to bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pressure Mattress Required",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Position Monitoring - Day",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Position Monitoring - Night",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    }) //ListItem.create({ item_description: "Can use Alarm", item_type: "predefined" }),
    //ListItem.create({ item_description: "Can move alone in bed", item_type: "predefined" }),
    //ListItem.create({ item_description: "Needs full care in bed", item_type: "predefined" }),
    ],
    assistance_transfers_items: [ListItem.create({
      item_description: "One Person",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Two Persons",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Other",
      item_type: "predefined"
    })],
    restraint_types_items: [ListItem.create({
      item_description: "Bed rail",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pressure Mat - Bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pressure Mat - Chair",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Wheelchair",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Table",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hoist",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    hoist_types_items: [ListItem.create({
      item_description: "Bathing",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Chair",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('restraint_types');
      this.initCheckboxesList('hoist_types');
      this.initCheckboxesList('assistance_transfers');
      this.initCheckboxesList('bedroom_assistance');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('model.mobility').get('mobile_aids')) {
        Ember.$('#mobile-aids-group-options').show();
      } else {
        Ember.$('#mobile-aids-group-options').hide();
      }

      if (this.get('model.mobility').get('restraint')) {
        Ember.$('#restraint-group').show();
      } else {
        Ember.$('#restraint-group').hide();
      }

      if (this.get('model.mobility').get('hoist')) {
        Ember.$('#hoist-group').show();
      } else {
        Ember.$('#hoist-group').hide();
      }

      if (this.get('model.mobility').get('status') == "Assistance required") {
        Ember.$('#mobile-aids-assistance-transfer').show();
      } else {
        Ember.$('#mobile-aids-assistance-transfer').hide();
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#mobility-details').offset().top - 58);
      });
    },
    modelWasChanged: Ember.observer('mobileAids', 'status', 'restraint', 'hoist', 'medicalWasChanged', 'hoistTypesWasChanged', 'restraintTypesWasChanged', 'assistanceTransfersWasChanged', 'bedroomAssistanceWasChanged', function () {
      if (this.get('medicalWasChanged') || this.get('hoistTypesWasChanged') || this.get('restraintTypesWasChanged') || this.get('assistanceTransfersWasChanged') || this.get('bedroomAssistanceWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('mobileAids')) {
        Ember.$('#mobile-aids-group-options').show();
      } else {
        Ember.$('#mobile-aids-group-options').hide();
      }

      if (this.get('restraint')) {
        Ember.$('#restraint-group').show();
      } else {
        Ember.$('#restraint-group').hide();
      }

      if (this.get('hoist')) {
        Ember.$('#hoist-group').show();
      } else {
        Ember.$('#hoist-group').hide();
      }

      if (this.get('status') == "Assistance required") {
        Ember.$('#mobile-aids-assistance-transfer').show();
      } else {
        Ember.$('#mobile-aids-assistance-transfer').hide();
      }
    }).on('init'),
    status: Ember.computed('model.mobility.status', function () {
      return this.get('model.mobility.status');
    }),
    restraint: Ember.computed('model.mobility.restraint', function () {
      return this.get('model.mobility.restraint');
    }),
    hoist: Ember.computed('model.mobility.hoist', function () {
      return this.get('model.mobility.hoist');
    }),
    mobileAids: Ember.computed('model.mobility.mobile_aids', function () {
      return this.get('model.mobility.mobile_aids');
    }),
    medicalWasChanged: Ember.computed('model.mobility.hasDirtyAttributes', function () {
      return this.get('model.mobility.hasDirtyAttributes');
    }),
    bedroomAssistanceWasChanged: Ember.computed('model.bedroom_assistance.[]', 'original_bedroom_assistance', function () {
      return !_underscore.default.isEqual(this.get('model').get('bedroom_assistance').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_bedroom_assistance').sort());
    }),
    assistanceTransfersWasChanged: Ember.computed('model.assistance_transfers.[]', 'original_assistance_transfers', function () {
      return !_underscore.default.isEqual(this.get('model').get('assistance_transfers').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_assistance_transfers').sort());
    }),
    restraintTypesWasChanged: Ember.computed('model.restraint_types.[]', 'original_restraint_types', function () {
      return !_underscore.default.isEqual(this.get('model').get('restraint_types').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_restraint_types').sort());
    }),
    hoistTypesWasChanged: Ember.computed('model.hoist_types.[]', 'original_hoist_types', function () {
      return !_underscore.default.isEqual(this.get('model').get('hoist_types').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_hoist_types').sort());
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.mobility').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
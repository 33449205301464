define("careshare/components/contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'div',
    init: function init() {
      this.set('model', this.get('model'));

      this._super.apply(this, arguments);
    },
    sortDefinition: ['order:desc'],
    sortedContacts: Ember.computed.sort('model.contact_details', 'sortDefinition'),
    modelWasChanged: Ember.observer('contactsWasChanged', 'itemsWasChanged', function () {
      if (this.get('contactsWasChanged') || this.get('itemsWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    didInsertElement: function didInsertElement() {
      Ember.$('#new_contact_type_other').hide();
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#contact-details').offset().top - 58);
      });
    },
    contactsWasChanged: Ember.computed('model.contact_details.@each.hasDirtyAttributes', function () {
      return this.get('model.contact_details').filterBy('hasDirtyAttributes', true).length > 0;
    }),
    itemsWasChanged: Ember.computed('model.contact_details.[]', 'originalContacts', function () {
      if (this.get('model').get('contact_details') != undefined) {
        return this.get('model').get('contact_details').length != this.get('originalContacts').length;
      }

      return false;
    }),
    actions: {
      onChangeContacts: function onChangeContacts() {
        var contact = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var event = arguments.length > 1 ? arguments[1] : undefined;
        console.log('test12345');
        var value = event.target.value;

        if (contact) {
          contact.contact_type = value;
          this.set('contactsWasChanged', true);
        } else {
          if (value == "Other") {
            Ember.$('#new_contact_type_other').show();
          } else {
            Ember.$('#new_contact_type_other').hide();
          }
        }
      },
      addContact: function addContact() {
        var contactType = this.$('#new_contact_type').val();
        var contactTypeOther = this.$('#new_contact_type_other').val();
        var contactName = this.$('#new_contact_name').val();
        var contactNok = this.$('#new_contact_nok').prop('checked');
        var contactDayToDay = this.$('#new_contact_day_to_day').prop('checked');
        var contactFinancialPOA = this.$('#new_contact_financial_poa').prop('checked');
        var contactWelfarePOA = this.$('#new_contact_welfare_poa').prop('checked');
        var contactFinancialGuardian = this.$('#new_contact_financial_guardian').prop('checked');
        var contactWelfareGuardian = this.$('#new_contact_welfare_guardian').prop('checked');
        var contactTime = this.$('input[name="new_contact_time"]:checked').val();
        var contactPhone = this.$('#new_contact_phone').val();
        var contactEmail = this.$('#new_contact_email').val();

        if (!contactType) {
          this.send('warning', 'Please, specify a contact type!');
          return;
        }

        var topOrderedItem = 0;

        if (this.get('sortedContacts').length > 0) {
          topOrderedItem = this.get('sortedContacts')[0].get('order');
        }

        var item = this.get('store').createRecord('contact-detail', {
          id: Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2),
          resident_id: this.get('model').get('id'),
          //contact_type: contactType + '_' + (parseInt(topOrderedItem) + 1),
          contact_type: contactType,
          contact_type_other: contactTypeOther,
          contact_description: contactType,
          contact_name: contactName,
          contact_phone: contactPhone,
          contact_email: contactEmail,
          contact_nok: contactNok,
          contact_day_to_day: contactDayToDay,
          contact_financial_poa: contactFinancialPOA,
          contact_welfare_poa: contactWelfarePOA,
          contact_financial_guardian: contactFinancialGuardian,
          contact_welfare_guardian: contactWelfareGuardian,
          contact_time: contactTime,
          order: topOrderedItem + 1
        }); //item.save();

        this.get('model').get('contact_details').pushObject(item);
        this.$('#new_contact_type').val('');
        this.$('#new_contact_type_other').val('');
        this.$('#new_contact_name').val('');
        this.$('#new_contact_nok').prop('checked', false);
        this.$('#new_contact_poa').prop('checked', false);
        this.$('#new_contact_day_to_day').prop('checked', false);
        this.$('#new_contact_financial_poa').prop('checked', false);
        this.$('#new_contact_welfare_poa').prop('checked', false);
        this.$('#new_contact_financial_guardian').prop('checked', false);
        this.$('#new_contact_welfare_guardian').prop('checked', false);
        this.$('input[name="new_contact_time"]').prop('checked', false);
        this.$('#new_contact_phone').val('');
        this.$('#new_contact_email').val('');
      },
      removeContact: function removeContact(contact) {
        contact.deleteRecord();
        contact.save();
        this.get('model').get('contact_details').removeObject(contact);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/documents/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    setupController: function setupController() {
      this.controllerFor('after-login.documents.new').set('previousCategory', this.controllerFor('after-login.documents.index').get('category'));
      this.controllerFor('after-login.documents.new').set('previousType', this.controllerFor('after-login.documents.index').get('type'));
      this.controllerFor('after-login.documents.new').set('previousResident', this.controllerFor('after-login.documents.index').get('resident_id'));
    },
    actions: {
      cancel: function cancel() {
        this.transitionTo('after-login.documents');
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      },
      willTransition: function willTransition(transition) {
        if (transition.targetName !== 'after-login.documents.index') {
          Ember.run.next(function () {
            Ember.$('#documents_item').css('display', 'none');
            Ember.$('#documents_item').prev('a').find('i').addClass("fa-angle-down");
            Ember.$('#documents_item').prev('a').find('i').removeClass("fa-angle-up");
          });
        }
      }
    }
  });

  _exports.default = _default;
});
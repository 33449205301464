define("careshare/helpers/convert-time", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "careshare/utils/convert-number-to-time"], function (_exports, _slicedToArray2, _convertNumberToTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertTime = convertTime;
  _exports.default = void 0;

  function convertTime(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        value = _ref2[0],
        slug = _ref2[1];

    if (slug === 'positioning' && Number.isInteger(value)) {
      return (0, _convertNumberToTime.default)(value);
    }

    return value;
  }

  var _default = Ember.Helper.helper(convertTime);

  _exports.default = _default;
});
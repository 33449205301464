define("careshare/models/consent", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    resident: _emberData.default.attr('string'),
    date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    type: _emberData.default.attr('string'),
    consented_by: _emberData.default.attr('string'),
    doc_uploaded: _emberData.default.attr('string'),
    actual_doc: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/components/sleeping-percare-preferences-continence-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header',
    idsErrors: [],
    bedroom_assistance_items: [ListItem.create({
      item_description: "Can move alone in bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Requires assistance to move in bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Needs full care in bed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Requires mobility assistance at night",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    night_times_items: [ListItem.create({
      item_description: "Independent",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Requires Assistance",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    safety_equipments_items: [ListItem.create({
      item_description: "Pressure pad",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bed rail",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hoist",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Alarm",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pressure mattress",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Chair",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Table",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('bedroom_assistance');
      this.initCheckboxesList('safety_equipments');
      this.initCheckboxesList('night_times');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {
          Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      $('#get_up_time').timepicker({
        change: function change(time) {
          var element = $(this),
              text;
          var timepicker = element.timepicker();
          text = timepicker.format(time);
          self.get('model.sleeping_details').set('get_up_time', text);
        }
      });
      $('#bed_time').timepicker({
        change: function change(time) {
          var element = $(this),
              text;
          var timepicker = element.timepicker();
          timepicker.format(time);
          self.get('model.sleeping_details').set('bed_time', text);
        }
      });
    },
    modelWasChanged: Ember.observer('sleepingDetailsWasChanged', 'personalCareWasChanged', 'continenceDetailsWasChanged', 'bedroomAssistanceWasChanged', 'safetyEquipmentsWasChanged', 'nightTimesWasChanged', function () {
      if (this.get('sleepingDetailsWasChanged') || this.get('personalCareWasChanged') || this.get('continenceDetailsWasChanged') || this.get('bedroomAssistanceWasChanged') || this.get('safetyEquipmentsWasChanged') || this.get('nightTimesWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    sleepingDetailsWasChanged: Ember.computed('model.sleeping_details.hasDirtyAttributes', function () {
      return this.get('model.sleeping_details.hasDirtyAttributes');
    }),
    personalCareWasChanged: Ember.computed('model.personal_care.hasDirtyAttributes', function () {
      return this.get('model.personal_care.hasDirtyAttributes');
    }),
    continenceDetailsWasChanged: Ember.computed('model.continence_details.hasDirtyAttributes', function () {
      return this.get('model.continence_details.hasDirtyAttributes');
    }),
    bedroomAssistanceWasChanged: Ember.computed('model.bedroom_assistance.[]', 'original_bedroom_assistance', function () {
      return !_underscore.default.isEqual(this.get('model').get('bedroom_assistance').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_bedroom_assistance').sort());
    }),
    safetyEquipmentsWasChanged: Ember.computed('model.safety_equipments.[]', 'original_safety_equipments', function () {
      return !_underscore.default.isEqual(this.get('model').get('safety_equipments').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_safety_equipments').sort());
    }),
    nightTimesWasChanged: Ember.computed('model.night_times.[]', 'original_night_times', function () {
      return !_underscore.default.isEqual(this.get('model').get('night_times').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_night_times').sort());
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.personal_care').set(Ember.$(event.target).attr('model-attr'), value);
      },
      selectValue2: function selectValue2(value) {
        this.get('model.continence_details').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
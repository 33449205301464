define("careshare/routes/after-login/infection/new", ["exports", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin"], function (_exports, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('infection');
    },
    afterModel: function afterModel(model) {
      this.createResident(model);
    },
    createResident: function createResident(model) {
      var resident = this.store.createRecord('infection-resident');
      resident.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('infectionResidents').pushObject(resident);
    },
    actions: {
      saveInfection: function saveInfection() {
        var self = this;
        var infection = this.modelFor(this.routeName);
        var date_start = moment(infection.get('date_start'), 'DD.MM.YYYY').format('YYYY-MM-DD');
        var date_end = moment(infection.get('date_end'), 'DD.MM.YYYY').format('YYYY-MM-DD');

        if (date_start > date_end) {
          this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          var formatedRequestData = JSON.stringify(infection.serialize());
          var requestOptions = {
            url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/infections',
            dataType: 'JSON',
            method: 'POST',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            },
            data: formatedRequestData
          };
          var saveDetails = Ember.$.ajax(requestOptions);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(saveDetails).done(function (response) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.infection');
          }).catch(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      },
      back: function back() {
        this.transitionTo('after-login.infection');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/monitoring/resident-index", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var formConfigurations = {
    '': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'type',
      type: 'icon-select',
      label: 'Type'
    }, {
      name: 'valueSelect',
      type: 'select',
      label: 'Volume (ml)',
      options: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
      hidden: true
    }, {
      name: 'valueText',
      type: 'text',
      label: 'Amount',
      hidden: true
    }, {
      name: 'valueTime',
      type: 'time-picker',
      label: 'Time',
      hidden: true
    }, {
      name: 'vocalisation',
      type: 'radio',
      label: 'Vocalisation',
      tooltip: 'e.g., whimpering, groaning, crying',
      options: [0, 1, 2, 3],
      hidden: true
    }, {
      name: 'facial_expression',
      type: 'radio',
      label: 'Facial expression',
      tooltip: 'e.g., looking tense, frowning, grimacing, looking frightened',
      options: [0, 1, 2, 3],
      hidden: true
    }, {
      name: 'body_language',
      type: 'radio',
      label: 'Change in body language',
      tooltip: 'e.g., fidgeting, rocking, guarding part of body, withdrawn',
      options: [0, 1, 2, 3],
      hidden: true
    }, {
      name: 'behavioural_change',
      type: 'radio',
      label: 'Behavioural change',
      tooltip: 'e.g., increased confusion, refusing to eat, alteration in usual patterns',
      options: [0, 1, 2, 3],
      hidden: true
    }, {
      name: 'physiological_change',
      type: 'radio',
      label: 'Physiological change',
      tooltip: 'e.g., temperature, pulse or blood pressure outside normal limits',
      options: [0, 1, 2, 3],
      hidden: true
    }, {
      name: 'physical_changes',
      type: 'radio',
      label: 'Physical changes',
      tooltip: 'e.g., skin tears, pressure areas, arthritis, contractures, previous injuries',
      options: [0, 1, 2, 3],
      hidden: true
    }],
    'weight': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'text',
      label: 'Weight (kg)'
    }],
    'fluid': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'select',
      label: 'Volume (ml)',
      options: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500]
    }],
    'food': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'text',
      label: 'Percent (%)'
    }],
    'urine': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'select',
      label: 'Volume (ml)',
      options: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500]
    }],
    'positioning': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'value',
      type: 'text',
      label: 'Notes'
    }],
    'bowel': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'text',
      label: 'Notes'
    }],
    'pain': [{
      name: 'date',
      type: 'date',
      label: 'Date'
    }, {
      name: 'time',
      type: 'time-picker',
      label: 'Time'
    }, {
      name: 'value',
      type: 'text',
      label: 'Pain Level',
      hidden: true
    }, // The total score
    {
      name: 'vocalisation',
      type: 'radio',
      label: 'Vocalisation',
      tooltip: 'eg: whimpering, groaning, crying',
      options: [0, 1, 2, 3]
    }, {
      name: 'facial_expression',
      type: 'radio',
      label: 'Facial expression',
      tooltip: 'eg: looking tense, frowning, grimacing, looking frightened)',
      options: [0, 1, 2, 3]
    }, {
      name: 'body_language',
      type: 'radio',
      label: 'Change in body language',
      tooltip: 'eg: fidgeting, rocking, guarding part of body, withdrawn',
      options: [0, 1, 2, 3]
    }, {
      name: 'behavioural_change',
      type: 'radio',
      label: 'Behavioural change',
      tooltip: 'eg: increased confusion, refusing to eat, alteration in usual patterns',
      options: [0, 1, 2, 3]
    }, {
      name: 'physiological_change',
      type: 'radio',
      label: 'Physiological change',
      tooltip: 'eg: temperature, pulse or blood pressure outside normal limits',
      options: [0, 1, 2, 3]
    }, {
      name: 'physical_changes',
      type: 'radio',
      label: 'Physical changes ',
      tooltip: 'eg: skin tears, pressure areas, arthritis, contractures, previous injuries',
      options: [0, 1, 2, 3]
    }]
  };

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    queryParams: ["page", "perPage", "resident_id", "date_from", "date_to", "slug", "sortBy", "direction"],
    page: 1,
    perPage: 10,
    resident_id: '',
    date_from: (0, _moment.default)().subtract(90, 'd').format('YYYY-MM-DD'),
    date_to: (0, _moment.default)().format('YYYY-MM-DD'),
    slug: null,
    sortBy: 'date',
    direction: 'asc',
    showAddForm: false,
    selectedType: null,
    printView: false,
    showPainScaleModal: false,
    selectedPainScale: null,
    isLoadingPainScale: false,
    painScaleError: null,
    columnTitles: {
      '': 'Reading',
      'bowel': 'Notes',
      'weight': 'Weight',
      'fluid': 'Fluid Intake',
      'food': 'Percent',
      'urine': 'Urine Output',
      'positioning': 'Position',
      'pain': 'Pain Score'
    },
    slugObserver: Ember.observer('slug', function () {
      this.set('showAddForm', false);
      this.transitionToRoute({
        queryParams: {
          showForm: false
        }
      });
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', 'printView', function () {
      return this.get('content.meta.pageCount') > 1 && !this.get('printView');
    }),
    formFields: Ember.computed('slug', 'selectedType', function () {
      var _this = this;

      var fields = formConfigurations[this.slug] || formConfigurations[''];

      if ((this.slug === null || this.slug === '') && this.selectedType) {
        fields = fields.map(function (field) {
          if (field.name === 'valueSelect') {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, field), {}, {
              hidden: !(_this.selectedType === 'fluid' || _this.selectedType === 'urine')
            });
          } else if (field.name === 'valueText') {
            var newField = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, field), {}, {
              hidden: _this.selectedType === 'fluid' || _this.selectedType === 'urine' || _this.selectedType === 'pain'
            });

            if (_this.selectedType === 'food') {
              Ember.set(newField, 'label', 'Percent (%)');
            }

            return newField;
          } else if (['vocalisation', 'facial_expression', 'body_language', 'behavioural_change', 'physiological_change', 'physical_changes'].includes(field.name)) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, field), {}, {
              hidden: _this.selectedType !== 'pain'
            });
          }

          return field;
        });
      }

      return fields;
    }),
    formTitle: Ember.computed('slug', function () {
      var titles = {
        'weight': 'Adding a New Weight Record',
        'fluid': 'Adding a New Fluid Record',
        'food': 'Adding a New Food Record',
        'urine': 'Adding a New Urine Record',
        'positioning': 'Adding a New Positioning Record',
        'bowel': 'Adding a New Bowel Record',
        'pain': 'Adding a New Pain Record'
      };
      return titles[this.slug] || 'Adding a New Record';
    }),
    actions: {
      toggleAddForm: function toggleAddForm() {
        this.toggleProperty('showAddForm');

        if (!this.showAddForm) {
          this.set('selectedType', null);
        }

        this.transitionToRoute({
          queryParams: {
            showForm: this.showAddForm
          }
        }); // Initialize formData for pain

        if (this.get('slug') === 'pain' || this.selectedType === 'pain') {
          this.set('formData', {
            date: '',
            time: '',
            resident: '',
            vocalisation: 0,
            facial_expression: 0,
            body_language: 0,
            behavioural_change: 0,
            physiological_change: 0,
            physical_changes: 0
          });
        }
      },
      updateQueryParams: function updateQueryParams(showForm) {
        this.set('showAddForm', showForm);
        this.transitionToRoute({
          queryParams: {
            showForm: showForm ? 'true' : 'false'
          }
        });
      },
      saveItem: function saveItem(newItem) {
        if (this.get('slug') === 'pain' || this.selectedType === 'pain') {
          console.log(newItem);
          var abbeyPainScale = {
            vocalisation: newItem.vocalisation,
            facial_expression: newItem.facial_expression,
            body_language: newItem.body_language,
            behavioural_change: newItem.behavioural_change,
            physiological_change: newItem.physiological_change,
            physical_changes: newItem.physical_changes
          };
          var totalPainScore = Object.values(abbeyPainScale).reduce(function (sum, value) {
            return sum + parseInt(value, 10);
          }, 0);
          newItem.value = totalPainScore;
          newItem.abbey_pain_scale = abbeyPainScale;
        }

        this.send('saveItemToRoute', newItem);
      },
      selectType: function selectType(type) {
        this.set('selectedType', type); // Trigger recomputation of formFields to update visibility

        this.notifyPropertyChange('formFields');
      },
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      },
      showPainScale: function showPainScale(itemId) {
        this.send('showPainScaleRoute', itemId);
      },
      closePainScaleModal: function closePainScaleModal() {
        this.send('closePainScaleModalRoute');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/contacts/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZC6yP48x",
    "block": "{\"symbols\":[],\"statements\":[[4,\"contact-edit\",null,[[\"contacts\",\"additionalContacts\",\"contactTypeList\",\"sourceList\",\"addRecord\",\"removeRecord\",\"saveData\",\"cancel\",\"print\"],[[20,[\"contacts\"]],[20,[\"additionalContacts\"]],[20,[\"contactTypeList\"]],[20,[\"sourceListAll\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"addRecord\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"removeRecord\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveData\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"cancel\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"print\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/contacts/new.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/worker-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ppgQfbk8",
    "block": "{\"symbols\":[\"staff\"],\"statements\":[[6,\"option\"],[9,\"value\",\"\"],[7],[0,\"Please select\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"staffs\"]]],null,{\"statements\":[[0,\"\\t\"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"id\"]]]]],[10,\"selected\",[25,\"eq\",[[20,[\"selected\"]],[19,1,[\"id\"]]],null],null],[7],[1,[19,1,[\"firstname\"]],false],[0,\" \"],[1,[19,1,[\"lastname\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/worker-select.hbs"
    }
  });

  _exports.default = _default;
});
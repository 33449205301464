define("careshare/components/section-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent',
    didInsertElement: function didInsertElement() {
      var $residentSelect = this.$('.section-select');
      $residentSelect.chosen({
        width: '100%',
        placeholder_text_single: 'Select section',
        allow_single_deselect: true
      });
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/permission-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L8U6VOi2",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[20,[\"checked\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/permission-checkbox.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/residents/change-avatar", ["exports", "careshare/mixins/set-belongs-to"], function (_exports, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    _updateFileBlob: function _updateFileBlob(cropper) {
      var self = this;
      return cropper.getCroppedCanvas({
        maxWidth: 512,
        maxHeight: 512
      }).toBlob(function (blob) {
        var reader = new FileReader();
        var data = reader.readAsDataURL(blob);
        var imageData;

        reader.onload = function () {
          imageData = reader.result;
          self.get('model').avatar = imageData;
          self.set('cropped_image', imageData);
        };
      });
    },
    actions: {
      uploadFile: function uploadFile(event) {
        var self = this;
        var reader = new FileReader();
        var file = event.target.files[0];
        var imageData;

        reader.onload = function () {
          imageData = reader.result;
          self.set('image', imageData);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      },
      crop: function crop(cropper) {
        Ember.run.debounce(this, this._updateFileBlob, cropper, 100);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/helpers/bmi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bmi = bmi;
  _exports.default = void 0;

  function bmi(params) {
    return params.reduce(function (a, b) {
      return b > 0 ? Math.round(a / (b / 10 * b / 10) * 100 * 10) / 10 : 0;
    });
  }

  var _default = Ember.Helper.helper(bmi);

  _exports.default = _default;
});
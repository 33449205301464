define("careshare/components/resident-contact-item", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'tr',
    item: null,
    origin_details: '',
    initFields: function () {
      var self = this;
      this.$('textarea[name="details"]').attr('readonly', true);
      this.set('origin_details', this.get('item').get('details'));
      this.$('textarea[name="details"]').on("mousedown", function (e) {
        $(this).attr('readonly', false);
      });
      this.$('textarea[name="details"]').on("focusout", function (e) {
        if (Ember.isEmpty($(this).val()) && !Ember.isEmpty(self.get('origin_details')) || $(this).val() != self.get('origin_details')) {
          if (!Ember.isEmpty($(this).val())) {
            $(this).removeClass('is-invalid');
          }

          self.send('save', self.get('item'));
        }

        $(this).attr('readonly', true);
      });
    }.on('didInsertElement'),
    save: function save(item) {
      var formatedRequestData = JSON.stringify(Ember.A([item]));
      var self = this;
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contacts',
        dataType: 'JSON',
        method: 'POST',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: formatedRequestData
      };
      var save = Ember.$.ajax(requestOptions);
      Ember.$.when(save).done(function () {
        self.send('success', 'Successfully saved!');
      });
    },
    actions: {
      save: function save() {
        this.set('origin_details', this.get('item').get('details'));
        this.save(this.get('item'));
      },
      edit: function edit(id, resident) {
        this.get('router').transitionTo('after-login.contacts.resident-edit', id, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      selectValue: function selectValue(value) {
        this.get('item').set(Ember.$(event.target).attr('model-attr'), value);
      },
      success: function success(text) {
        this.sendAction('success', text);
      }
    }
  });

  _exports.default = _default;
});
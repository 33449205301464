define("careshare/mixins/pagination", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_emberData.default.EmbeddedRecordsMixin, {
    extractMeta: function extractMeta(store, type, payload) {
      if (payload && payload.hasOwnProperty('_meta')) {
        var meta = payload._meta;
        meta['total_pages'] = payload._meta.pageCount;
        delete payload._meta;
        return meta;
      }
    }
  });

  _exports.default = _default;
});
define("careshare/adapters/end-of-life", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    updateRecord: function updateRecord(store, type, snapshot) {
      return {
        id: snapshot.id
      };
    }
  });

  _exports.default = _default;
});
define("careshare/adapters/contact-family-detail", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'contact-family-details';
    },
    createRecord: function createRecord(store, type, snapshot) {
      return {
        id: snapshot.id
      };
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return {
        id: snapshot.id
      };
    },
    deleteRecord: function deleteRecord(store, type, snapshot) {
      return {
        id: snapshot.id
      };
    }
  });

  _exports.default = _default;
});
define("careshare/components/skin-allergies-discharge-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header',
    idsErrors: [],
    discharge_reasons_items: [ListItem.create({
      item_description: "Hospital",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hospice",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Other Care Home",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Own Home",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Family",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Holiday",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Deceased",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    skin_integrity_items: [ListItem.create({
      item_description: "Skin Healthy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin dry or fragile",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin breakdown",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin oedematous",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    allergies_items: [ListItem.create({
      item_description: "Alcohol",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Dairy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Dust",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Egg",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Fish",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Insects",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Medication/Drugs",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Milk",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Nuts - all",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Nuts - peanuts",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pets",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Shell Fish ",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin Contact Allergy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Soya",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Wheat",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('allergies');
      this.initCheckboxesList('skin_integrity');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {
          Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('skinDetailsWasChanged', 'allergiesDetailsWasChanged', 'dischargeWasChanged', 'skinIntegrityWasChanged', 'allergiesAssistanceWasChanged', 'dischargeReasonsWasChanged', function () {
      if (this.get('skinDetailsWasChanged') || this.get('allergiesDetailsWasChanged') || this.get('dischargeWasChanged') || this.get('skinIntegrityWasChanged') || this.get('allergiesAssistanceWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    skinDetailsWasChanged: Ember.computed('model.skin_details.hasDirtyAttributes', function () {
      return this.get('model.skin_details.hasDirtyAttributes');
    }),
    allergiesDetailsWasChanged: Ember.computed('model.allergies_details.hasDirtyAttributes', function () {
      return this.get('model.allergies_details.hasDirtyAttributes');
    }),
    dischargeWasChanged: Ember.computed('model.discharge.hasDirtyAttributes', function () {
      return this.get('model.discharge.hasDirtyAttributes');
    }),
    skinIntegrityWasChanged: Ember.computed('model.skin_integrity.[]', 'original_skin_integrity', function () {
      return !_underscore.default.isEqual(this.get('model').get('skin_integrity').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_skin_integrity').sort());
    }),
    allergiesAssistanceWasChanged: Ember.computed('model.allergies.[]', 'original_allergies', function () {
      return !_underscore.default.isEqual(this.get('model').get('allergies').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_allergies').sort());
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.discharge').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
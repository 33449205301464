define("careshare/adapters/notification", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'notifications';
    },
    setRead: function setRead(notificationId) {
      var url = this.buildURL(this.pathForType()) + '/set-read';
      return this.ajax(url, "POST", {
        data: {
          id: notificationId
        }
      });
    },
    setCompleted: function setCompleted(notificationId) {
      var url = this.buildURL(this.pathForType()) + '/set-completed';
      return this.ajax(url, "POST", {
        data: {
          id: notificationId
        }
      });
    }
  });

  _exports.default = _default;
});
define("careshare/components/user-position-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    attributeBindings: ['style'],
    style: "border: none !important; background: transparent; padding:0",
    constants: Ember.inject.service('constants'),
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.run.schedule('afterRender', function () {
        var $positionSelect = self.$('.position-select');
        $positionSelect.val(self.get('selected_position'));
        $positionSelect.chosen({
          width: '100%',
          placeholder_text_single: 'Choose Position',
          allow_single_deselect: true
        });
      });
    },
    actions: {
      change: function change() {
        var changeAction = this.get('action');
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var userPositions = this.get('constants').user_positions;
        var selectedValue = userPositions[selectedIndex].item_description;
        this.set('selectedValue', selectedValue);
        this.set('user.position', selectedValue);
        changeAction(selectedValue);
      }
    }
  });

  _exports.default = _default;
});
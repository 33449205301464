define("careshare/controllers/after-login", ["exports", "moment", "careshare/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var today = (0, _moment.default)().format('DD.MM.YYYY');
  var period = (0, _moment.default)().add(60, 'days').format('DD.MM.YYYY');

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    today: today,
    plus_60_days: period,
    resident_id: '',
    type: '',
    resident_name: '',
    professional_id: '',
    professional_name: '',
    actions: {
      logout: function logout() {
        var self = this;
        this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/users/invalidate-token', {
          method: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          success: function success(xhr) {
            self.get('session').invalidate();
          }
        });
      },
      toggle: function toggle() {
        var nav = document.getElementById('nav-sidebar');
        var style = window.getComputedStyle(document.getElementById('nav-expand'));
        if (style.display === 'none') return;

        if (nav.style.display != "block") {
          nav.setAttribute('style', 'display:block !important');
        } else {
          nav.setAttribute('style', 'display:none !important');
        }
      },
      slideIn: function slideIn() {
        var nav = document.getElementById('nav-sidebar');
        nav.className = nav.className.replace("slide-right", "slide-left");
        var mains = document.getElementsByClassName('main');

        if (mains.length) {
          for (var i = 0; i < mains.length; i++) {
            mains[i].className = mains[i].className.replace(" slide-right", "");
            mains[i].className = mains[i].className.replace("main", "main slide-left");
          }
        }
      },
      slideOut: function slideOut() {
        var nav = document.getElementById('nav-sidebar');
        nav.className = nav.className.replace("slide-left", "slide-right");
        var mains = document.getElementsByClassName('main');

        if (mains.length) {
          for (var i = 0; i < mains.length; i++) {
            mains[i].className = mains[i].className.replace(" slide-left", "");
            mains[i].className = mains[i].className.replace("main", "main slide-right");
          }
        }
      }
    }
  });

  _exports.default = _default;
});
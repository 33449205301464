define("careshare/models/monitoring-abbey-pain-scale", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    monitoringReading: _emberData.default.belongsTo('monitoring-reading'),
    vocalisation: _emberData.default.attr('number'),
    facialExpression: _emberData.default.attr('number'),
    bodyLanguage: _emberData.default.attr('number'),
    behaviouralChange: _emberData.default.attr('number'),
    physiologicalChange: _emberData.default.attr('number'),
    physicalChanges: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date')
  });

  _exports.default = _default;
});
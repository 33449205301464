define("careshare/utils/computed/search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = search;
  var computed = Ember.computed;

  function search(dependentKey, propertyKey, searchQueryKey, returnEmptyArray) {
    returnEmptyArray = typeof returnEmptyArray === "undefined" ? false : returnEmptyArray;
    return computed(dependentKey + ".@each." + propertyKey, searchQueryKey, function () {
      if (this.get(propertyKey) && this.get(propertyKey).length > 0) {
        this.set(searchQueryKey, this.get(propertyKey));
      }

      return this.get(dependentKey) || Ember.A([]);
    });
  }
});
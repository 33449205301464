define("careshare/templates/components/bs-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LF2FIoag",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",[[25,\"-input-type\",[[25,\"if\",[[20,[\"isMobile\"]],\"time\",\"text\"],null]],null]],[[\"name\",\"type\",\"value\",\"class\"],[[20,[\"name\"]],[25,\"if\",[[20,[\"isMobile\"]],\"time\",\"text\"],null],[20,[\"value\"]],\"form-control input-group date\"]]],false],[0,\"\\n\"],[4,\"if\",[[25,\"not\",[[20,[\"isMobile\"]]],null]],null,{\"statements\":[[0,\"\\t\"],[6,\"span\"],[9,\"class\",\"input-group-addon2\"],[7],[0,\"\\n\\t\\t\"],[6,\"i\"],[9,\"class\",\"fa fa-clock-o\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/bs-time-picker.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/contact-type-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "woth0KZu",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[6,\"select\"],[9,\"class\",\"chosen contact-type\"],[7],[0,\"\\n\\t\"],[6,\"option\"],[9,\"value\",\"\"],[7],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"typeList\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"id\"]]]]],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/contact-type-select.hbs"
    }
  });

  _exports.default = _default;
});
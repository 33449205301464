define("careshare/components/shift-summary-report-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'tr',
    report_item: null,
    origin_notes: '',
    allFilledIn: null,
    index: -1,
    initFields: function () {
      this.set('origin_notes', this.get('report_item').get('notes'));
      var reportItem = this.get('report_item');
      reportItem.set('user_id', this.get('session').get('data.authenticated.user_id'));
      reportItem.set('date', this.get('date'));
      var $notesTextarea = this.$('.notes').find('textarea');
      var isReportLocked = this.get('report_item.is_locked') === 0;
      $notesTextarea.attr('disabled', !isReportLocked);
      var $notesMood = this.$('.mood').find('input');
      $notesMood.attr('disabled', !isReportLocked);
      var self = this;
      $notesTextarea.on("keyup", function () {
        self.handleChange();
      });
      this.$('.mood').find('i').removeClass('fa').addClass('fas');
      $notesTextarea.on("mouseout", function () {
        if (!Ember.isEmpty($(this).val()) && $(this).is(":focus")) {
          self.get('report_item').set('date', self.get('date'));
          self.get('report_item').save();
        }
      });
      $notesMood.on("click", function () {
        self.get('report_item').set('mood', this.value);
        self.get('report_item').set('date', self.get('date'));
        self.get('report_item').save();
        var resident = self.get('report_item').get('resident');
        resident.mood = this.value;
        resident.save();
        self.handleChange();
      });
    }.on('didInsertElement'),
    actions: {
      save: function save() {
        this.set('origin_notes', this.get('report_item').get('notes'));
        this.save(this.get('report_item'));
      },
      selectValue: function selectValue(value) {
        this.get('report_item').set(Ember.$(event.target).attr('model-attr'), value);
      },
      success: function success(text) {
        this.sendAction('success', text);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/mixins/admission-validate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    isAllTabsValid: function isAllTabsValid(resident) {
      var $total = 0;
      $total += parseInt(resident.get('key_details_valid'));
      $total += parseInt(resident.get('additional_details_valid'));
      $total += parseInt(resident.get('contact_details_valid'));
      $total += parseInt(resident.get('contact_family_details_valid'));
      $total += parseInt(resident.get('communication_details_valid'));
      $total += parseInt(resident.get('eol_details_valid'));
      $total += parseInt(resident.get('medical_details_valid'));
      $total += parseInt(resident.get('mobility_details_valid'));
      $total += parseInt(resident.get('nutrition_details_valid'));
      $total += parseInt(resident.get('reminiscence_details_valid'));
      $total += parseInt(resident.get('sleeping_details_valid'));
      $total += parseInt(resident.get('percare_details_valid'));
      $total += parseInt(resident.get('preferences_details_valid'));
      $total += parseInt(resident.get('continence_details_valid'));
      $total += parseInt(resident.get('skin_details_valid'));
      $total += parseInt(resident.get('allergies_details_valid'));
      return $total >= 1600 ? 1 : 0;
    },
    notValidMessage: function notValidMessage() {
      return 'Please save data and complete all sections 100% before registering';
    },
    saveSectionFirst: function saveSectionFirst() {
      return 'Please save the data for this section';
    },
    saveSection: function saveSection() {
      return 'Save or reset the current section before opening another section';
    },
    registerResident: function registerResident() {
      return 'Resident successfully registered';
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/outcomes/resident-index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["page", "perPage", "resident_id", "outcome_type", "resident_name", "sortBy", "direction", "current"],
    resident_id: '',
    outcome_type: '',
    default_outcome_type: 'all',
    resident_name: '',
    sortBy: 'date',
    direction: 'DESC',
    current: 1,
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    actions: {
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      }
    }
  });

  _exports.default = _default;
});
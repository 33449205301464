define("careshare/components/drag-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    class: 'drag-box',
    attributeBindings: ['draggable'],
    draggable: true,
    x: 0,
    y: 0,
    positionChanged: Ember.observer('x', 'y', function () {
      this.$().css({
        left: "".concat(this.get('x'), "px"),
        top: "".concat(this.get('y'), "px")
      });
    }),
    dragStart: function dragStart(e) {
      var x = parseInt(this.$().css('left')) - e.originalEvent.clientX;
      var y = parseInt(this.$().css('top')) - e.originalEvent.clientY;
      e.originalEvent.dataTransfer.setData("position", "".concat(x, ",").concat(y));
    },
    windowDrop: function windowDrop(e) {
      var xy = e.dataTransfer.getData("position").split(',');
      this.setProperties({
        x: "".concat(e.originalEvent.clientX + parseInt(xy[0])),
        y: "".concat(e.originalEvent.clientY + parseInt(xy[1]))
      });
    },
    windowDragOver: function windowDragOver(e) {
      e.originalEvent.preventDefault();
    },
    didRender: function didRender() {
      var self = this;
      $(document).on('drop', $.proxy(self.windowDrop, self)).on('dragover', $.proxy(self.windowDragOver, self));
    },
    didDestroyElement: function didDestroyElement() {
      $(document).off('drop').off('dragover');
    }
  });

  _exports.default = _default;
});
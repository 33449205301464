define("careshare/helpers/get-label-for-pain-scale", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getLabelForPainScale(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        value = _ref2[0];

    switch (value) {
      case 0:
        return 'Absent';

      case 1:
        return 'Mild';

      case 2:
        return 'Moderate';

      case 3:
        return 'Severe';

      default:
        return '';
    }
  }

  var _default = Ember.Helper.helper(getLabelForPainScale);

  _exports.default = _default;
});
define("careshare/components/communication-details", ["exports", "underscore", "careshare/mixins/checkboxes-behavior"], function (_exports, _underscore, _checkboxesBehavior) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    communication_languages_items: Ember.A([{
      item_description: "English - 1st",
      item_type: "predefined"
    }, {
      item_description: "English - 2nd",
      item_type: "predefined"
    }, {
      item_description: "Gaelic",
      item_type: "predefined"
    }, {
      item_description: "Welsh",
      item_type: "predefined"
    }]),
    communication_methods_items: [ListItem.create({
      item_description: "Speech",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Sign Language",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Gesture",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Lip Reading",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    communication_interactions_items: [ListItem.create({
      item_description: "Confused",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Anxious",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Soft spoken",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Verbally Aggressive",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Physically Aggressive",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    communication_aids_items: [ListItem.create({
      item_description: "Reading glasses",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Glasses - full time",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hearing aid - left",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hearing aid - right",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hearing aid - both",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    communication_impairments_items: [ListItem.create({
      item_description: "Poor Eyesight",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Blind left",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Blind right",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Blind both",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Poor Hearing",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Deaf left",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Deaf right",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Deaf both",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('communication_methods');
      this.initCheckboxesList('communication_languages');
      this.initCheckboxesList('communication_interactions');
      this.initCheckboxesList('communication_aids');
      this.initCheckboxesList('communication_impairments');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('methodsWasChanged', 'communicationWasChanged', 'interactionsWasChanged', 'languagesWasChanged', 'aidsWasChanged', 'impairmentsWasChanged', function () {
      if (this.get('methodsWasChanged') || this.get('communicationWasChanged') || this.get('interactionsWasChanged') || this.get('languagesWasChanged') || this.get('aidsWasChanged') || this.get('impairmentsWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    methodsWasChanged: Ember.computed('model.communication_methods.[]', 'original_communication_methods', function () {
      return !_underscore.default.isEqual(this.get('model').get('communication_methods').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_communication_methods').sort());
    }),
    impairmentsWasChanged: Ember.computed('model.communication_impairments.[]', 'original_communication_impairments', function () {
      return !_underscore.default.isEqual(this.get('model').get('communication_impairments').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_communication_impairments').sort());
    }),
    aidsWasChanged: Ember.computed('model.communication_aids.[]', 'original_communication_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('communication_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_communication_aids').sort());
    }),
    languagesWasChanged: Ember.computed('model.communication_languages.[]', 'original_communication_languages', function () {
      return !_underscore.default.isEqual(this.get('model').get('communication_languages').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_communication_languages').sort());
    }),
    interactionsWasChanged: Ember.computed('model.communication_interactions.[]', 'original_communication_interactions', function () {
      return !_underscore.default.isEqual(this.get('model').get('communication_interactions').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_communication_interactions').sort());
    }),
    communicationWasChanged: Ember.computed('model.communication.hasDirtyAttributes', function () {
      return this.get('model.communication.hasDirtyAttributes');
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#communication-details').offset().top - 58);
      });
    },
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.communication').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
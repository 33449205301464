define("careshare/controllers/after-login/residents/list", ["exports", "careshare/utils/computed/search"], function (_exports, _search) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    queryParams: ["page", "perPage", "expand", "sortBy", "direction", "full_name"],
    expand: "inventory",
    sortBy: 'firstname',
    direction: 'ASC',
    searchQuery: null,
    residentsWithCount: (0, _search.default)('model', 'full_name', 'searchQuery'),
    residentsWithCountOld: Ember.computed.map('model', function (data, index) {
      return Ember.Object.create({
        data: data,
        count: index + 1
      });
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    actions: {
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      }
    }
  });

  _exports.default = _default;
});
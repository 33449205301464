define("careshare/components/message-recipient-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent; padding: 0',
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      var self = this;
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/messages/recipients', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (recipients) {
        _this.set('recipientList', recipients);

        Ember.run.schedule('afterRender', function () {
          var $recipientSelect = self.$('.recipient-select');
          var recipientId = self.get('recipientId');
          $recipientSelect.val(recipientId);
          self.onChange(recipientId);
          $recipientSelect.on('change', function () {
            self.onChange($(this).val());
          });

          if (1 == 0 && recipientId > 0) {
            $recipientSelect.attr('disabled', true);
          } else {
            $recipientSelect.attr('disabled', false);
          }

          $recipientSelect.chosen({
            width: '100%',
            placeholder_text_single: 'Select Recipient',
            allow_single_deselect: true
          });
        });
      });
    },
    onChange: function onChange(value) {
      this.set('model.to_id', value);
      var $messageInfoBlock = $('.message-info');
    }
  });

  _exports.default = _default;
});
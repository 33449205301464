define("careshare/routes/after-login/users/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('user', params.id);
    },
    beforeModel: function beforeModel() {
      Ember.run.later('afterRender', function () {
        Ember.$('.ui-tooltip').addClass('hidden');
      });
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      saveUser: function saveUser() {
        var user = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        user.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
            self.transitionTo('after-login.users');
          });
          alert('Successfully saved!');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        this.transitionTo('after-login.users');
      }
    }
  });

  _exports.default = _default;
});
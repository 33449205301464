define("careshare/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "II6x61O6",
    "block": "{\"symbols\":[],\"statements\":[[2,\" Form login \"],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"content-wrapper\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"form\"],[9,\"class\",\"login__form\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"login__fields-box\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"h2\"],[9,\"class\",\"text-center login-error\"],[7],[1,[18,\"message\"],false],[8],[0,\"\\n\\t\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[6,\"footer\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"footer-content\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"copyright\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"p\"],[9,\"class\",\"copyright__text\"],[7],[0,\"CareShare 2018 Ⓒ Copyright\"],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\\t\"],[6,\"nav\"],[9,\"class\",\"footer-nav\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"ul\"],[9,\"class\",\"footer-nav__list\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"li\"],[9,\"class\",\"footer-nav__item\"],[7],[6,\"a\"],[9,\"class\",\"footer-nav__link\"],[9,\"href\",\"\"],[7],[0,\"Home\"],[8],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"li\"],[9,\"class\",\"footer-nav__item\"],[7],[6,\"a\"],[9,\"class\",\"footer-nav__link\"],[9,\"href\",\"\"],[7],[0,\"About Us\"],[8],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"li\"],[9,\"class\",\"footer-nav__item\"],[7],[6,\"a\"],[9,\"class\",\"footer-nav__link\"],[9,\"href\",\"\"],[7],[0,\"Terms & Conditions\"],[8],[8],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"li\"],[9,\"class\",\"footer-nav__item\"],[7],[6,\"a\"],[9,\"class\",\"footer-nav__link\"],[9,\"href\",\"\"],[7],[0,\"Contact Us\"],[8],[8],[0,\"\\n\\t\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"],[2,\" Form login \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/error.hbs"
    }
  });

  _exports.default = _default;
});
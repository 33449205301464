define("careshare/routes/after-login/adverse-events/resident-new", ["exports", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('adverse-event');
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      var note = this.store.createRecord('adverse-event-note');
      note.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('adverse_notes').pushObject(note);
      this.controllerFor('after-login.adverse-events.resident-new').set('resident_id', transition.queryParams.resident_id);
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.adverse-events.resident-new').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    actions: {
      saveEvent: function saveEvent(resident) {
        var _this = this;

        var self = this;
        var model = this.modelFor(this.routeName);
        model.set('resident_id', resident);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        model.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.transitionTo('after-login.adverse-events.resident-index', resident, {
            queryParams: {
              resident_id: resident
            }
          });

          _this.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');

          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back(resident) {
        this.transitionTo('after-login.adverse-events.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "careshare/mixins/messages-and-notifications"], function (_exports, _authenticatedRouteMixin, _messagesAndNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_messagesAndNotifications.default, _authenticatedRouteMixin.default), {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('afterLoginController', this.controllerFor('after-login'));
    },
    beforeModel: function beforeModel(transition) {
      var self = this;

      if (transition.targetName === 'after-login.index') {
        this.transitionTo('after-login.residents');
      }

      Ember.$(document).ajaxError(function (event, jqXHR) {
        if (jqXHR.status === 403) {
          self.redirectToErrorPage('Permission denied');
        }
      });
      this.initNewMessagesCounterUpdate();
      this.initNewNotificationsCounterUpdate();

      this._super.apply(this, arguments);
    },
    redirectToErrorPage: function redirectToErrorPage(errorMessage) {
      this.transitionTo('error', {
        queryParams: {
          message: errorMessage
        }
      });
    },
    actions: {
      toggleActiveItem: function toggleActiveItem(item) {
        var menuItems = Ember.$('ul.sublink-ul');
        menuItems.each(function () {
          if ($(this).attr('id') !== item + '_item') {
            $(this).css('display', 'none');
          }
        });
        var menuItem = $('#' + item + '_item');

        if (menuItem.css("display") == 'none') {
          menuItem.css("display", "block");
          menuItem.prev('a').find('i').addClass("fa-angle-up");
          menuItem.prev('a').find('i').removeClass("fa-angle-down");
        } else {
          menuItem.css("display", "none");
          menuItem.prev('a').find('i').removeClass("fa-angle-up");
          menuItem.prev('a').find('i').addClass("fa-angle-down");
        }
      },
      reload: function reload() {
        this.refresh();
      },
      error: function error(result) {
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });

        if (result.errors && result.errors[0].status === '403') {
          this.redirectToErrorPage('Permission denied');
        }
      }
    }
  });

  _exports.default = _default;
});
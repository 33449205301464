define("careshare/models/end-of-life", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    paliative_care: _emberData.default.attr('string'),
    instruction_on_death: _emberData.default.attr('string'),
    eol_wishes: _emberData.default.attr('string'),
    eol_notes: _emberData.default.attr('string'),
    plan_agreed: _emberData.default.attr('string'),
    dnacpr: _emberData.default.attr('string'),
    hospital_admission: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
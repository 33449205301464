define("careshare/adapters/permission", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'permissions';
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      return {
        permission: {
          name: snapshot.adapterOptions.permission
        }
      };
    }
  });

  _exports.default = _default;
});
define("careshare/adapters/activity", ["exports", "ember-data", "careshare/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var errorsHashToArray = _emberData.default.errorsHashToArray;

  var _default = _application.default.extend({
    findRecord: function findRecord(store, type, id, snapshot) {
      if (Ember.get(snapshot.adapterOptions, 'expand')) {
        var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
        var query = {
          expand: Ember.get(snapshot.adapterOptions, 'expand')
        };
        return this.ajax(url, 'GET', {
          data: query
        });
      } else {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/helpers/ntobr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ntobr = ntobr;

  function ntobr(text) {
    return text.toString().replace(/\n/g, '<br>');
  }

  var _default = Ember.Helper.helper(ntobr);

  _exports.default = _default;
});
define("careshare/routes/after-login/contacts/resident-new", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _toConsumableArray2, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    queryParams: {
      resident_id: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        var textAreas = Ember.$('.report-textarea');
        var dates = Ember.$('.date-picker');
        var types = Ember.$('select.contact-type');
        var residents = Ember.$('select.resident-select');
        textAreas.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).text())) {
              $(this).removeClass('is-invalid');
            }
          });
        });
        dates.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).parent().removeClass('is-invalid');
            }
          });
        });
        types.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).next().removeClass('is-invalid');
            }
          });
        });
        residents.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).next().removeClass('is-invalid');
            }
          });
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.run(function () {
        return _this.get('store').unloadAll('contact');
      });
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      var store = this.store;
      var record = store.createRecord('contact', {
        user_id: this.get('session.data.authenticated.user_id')
      });
      this.controllerFor('after-login.contacts.resident-new').set('contacts', []);
      this.controllerFor('after-login.contacts.resident-new').get('contacts').pushObject(record);

      if (transition.queryParams.resident_id) {
        this.controllerFor('after-login.contacts.resident-new').get('contacts')[0].set('resident_id', transition.queryParams.resident_id);
      }

      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.contacts.resident-new').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    actions: {
      cancel: function cancel(resident) {
        this.transitionTo('after-login.contacts.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      saveData: function saveData(resident) {
        var retTransition = Ember.isEmpty(localStorage.getItem('previousOutcomeTransition')) ? 'outcomes' : localStorage.getItem('previousOutcomeTransition');
        var self = this;
        var contactModels = this.controllerFor('after-login.contacts.resident-new').get('contacts');
        var formatedRequestData = JSON.stringify(contactModels);
        var requestOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contacts',
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestData
        };
        var saveDetails = Ember.$.ajax(requestOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(saveDetails).done(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.contacts.resident-index', resident, {
            queryParams: {
              resident_id: resident
            }
          });
        }).catch(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });

          for (var i = 0; i < contactModels.length; i++) {
            var errors = response.responseJSON[i].errors;

            contactModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              contactModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.contacts.resident-new').set('contacts', (0, _toConsumableArray2.default)(contactModels));
        });
      },
      addRecord: function addRecord() {
        var store = this.store;

        var firstAdded = store.peekAll('contact').content[0]._attributes;

        var record = store.createRecord('contact', {
          resident_id: firstAdded.resident_id
        });
        this.controllerFor('after-login.contacts.resident-new').get('contacts').pushObject(record);
        Ember.$('.form-scroll').animate({
          scrollTop: $('.form-scroll').prop('scrollHeight')
        }, 1000);
      },
      removeRecord: function removeRecord(contact) {
        this.controllerFor('after-login.contacts.resident-new').get('contacts').removeObject(contact);
        contact.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});
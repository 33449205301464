define("careshare/routes/after-login/skin/resident-edit", ["exports", "careshare/config/environment", "careshare/mixins/graph", "moment"], function (_exports, _environment, _graph, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    existingActionsAmount: 0,
    newAdded: false,
    dialog: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('skin', params.id, {
        reload: true
      });
    },
    beforeModel: function beforeModel() {
      Ember.run.later('afterRender', function () {
        Ember.$('.ui-tooltip').addClass('hidden');
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.typeChanged(null, null);
    },
    afterModel: function afterModel(model, transition) {
      var controller = this.controllerFor('after-login.skin.resident-edit'),
          type = model.get('type'),
          self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      this.createEmptyAction(model, false);

      if (controller.get('newAdded')) {
        controller.set('newAdded', false);
      }

      Ember.$('.ui-tooltip').addClass('hidden');
      this.set('existingActionsAmount', model.get('skinActions').length);
      Ember.run.schedule('afterRender', function () {
        Ember.$('.ui-tooltip').addClass('hidden');
        Ember.$('.loader-wrapper').addClass('hidden');

        if (!Ember.isEmpty(type) && type !== 'select type') {
          Ember.$('#grade').prop("disabled", false);
        }
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.skin.resident-edit').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
          Ember.$(window).scrollTop(Ember.$('.skin-integrity-item').offset().top);
        });
      });
    },
    createEmptyAction: function createEmptyAction(model) {
      var healed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var today = new Date(),
          action = this.store.createRecord('skin-action'),
          h = today.getHours(),
          m = today.getMinutes(),
          date = (0, _moment.default)().format("DD.MM.YYYY");
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      action.set('time', h + ":" + m);
      action.set('date', date);

      if (healed) {
        action.set('action', 'Other');
      }

      model.get('skinActions').unshiftObject(action);
    },
    doSave: function doSave(skinActions, setAsHealed, model, gradeChanged, controller, resident) {
      var _this = this;

      skinActions.forEach(function (action) {
        if (action && !action.get('action') && !setAsHealed) {
          skinActions.removeObject('action');

          _this.get('store').deleteRecord(action);
        }
      });

      if (model.get('type') === 'Other' && !model.get('type_other').length) {
        this.controllerFor('after-login').set('dialogMessage', 'If Other is selected, then the other type needs to be filled in!');
        Ember.$('.modal-window.error').removeClass('hidden');
      } else {
        if (gradeChanged && !skinActions.get('firstObject').get('notes') && !setAsHealed) {
          this.controllerFor('after-login').set('dialogMessage', 'Notes are needed when changing the grade!');
          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          document.getElementById("location_x").focus();
          document.getElementById("location_y").focus();
          document.getElementById("location_img_x").focus();
          document.getElementById("location_img_y").focus();
          model.set('user_id', this.get('session.data.authenticated.user_id'));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          model.save().then(function (returnItem) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });

            _this.controllerFor('after-login').set('dialogMessage', setAsHealed ? 'Successfully saved and moved under healed section!' : 'Successfully saved!');

            Ember.$('.modal-window.success').removeClass('hidden');

            if (setAsHealed || gradeChanged) {
              _this.createEmptyAction(model, false);

              if (setAsHealed) {
                controller.set('isHealed', false);
              }

              Ember.$('#resident-edit-back').click(); //                        this.transitionTo('after-login.skin.resident-index', resident, { queryParams: { resident_id: resident }});
            } else {
              _this.createEmptyAction(model, false);

              _this.transitionTo('after-login.skin.resident-edit', model.id, {
                queryParams: {
                  resident_id: resident
                }
              });

              Ember.$(window).scrollTop(Ember.$('.skin-integrity-item').offset().top);
            }
          }).catch(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      }
    },
    actions: {
      saveData: function saveData(resident) {
        var self = this;
        var model = this.modelFor(this.routeName);
        var controller = self.controllerFor('after-login.skin.resident-edit');
        var changedAttributes = model.changedAttributes();
        var skinActions = model.get('skinActions');
        var typeChanged = false;
        var gradeChanged = false;
        var setAsHealed = false;

        if (Object.keys(changedAttributes).indexOf('grade') !== -1) {
          gradeChanged = true;
        }

        if (Object.keys(changedAttributes).indexOf('type') !== -1) {
          typeChanged = true;
        }

        if (controller.get('isHealed')) {
          var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
            "id": "oi/qGg3g",
            "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure you want to move this item to the Healed table, no further editing will be possible?\"]],\"hasEval\":false}",
            "meta": {}
          }));
          promise.then(function () {
            skinActions.forEach(function (action) {
              if (action && !action.get('action')) {
                skinActions.removeObject(action);
              }
            });
            self.createEmptyAction(model, true);
            model.set('grade', 'Healed');
            self.doSave(skinActions, true, model, true, controller, resident);
          }, function () {
            controller.set('isHealed', false);
          });
        } else {
          self.doSave(skinActions, false, model, gradeChanged, controller, resident);
        }
      },
      back: function back(resident) {
        var changedAttributes = this.modelFor(this.routeName).changedAttributes(),
            model = this.modelFor(this.routeName),
            controller = this.controllerFor(this.routeName);
        Object.keys(changedAttributes).forEach(function (key) {
          model.set(key, changedAttributes[key][0]);
        });

        if (controller.get('isHealed')) {
          controller.set('isHealed', false);
        }

        model.set('skinActions', []);
        this.transitionTo('after-login.skin.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      removeAction: function removeAction(model, action) {
        model.get('skinActions').removeObject(action);
        this.get('store').deleteRecord(action);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/residents/contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WwYiJZDX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"view\"]],\"1\"],null]],null,{\"statements\":[[0,\"\\t\"],[1,[25,\"contact-details-view\",null,[[\"chartData\",\"chartOptions\",\"originalContacts\",\"model\",\"goTo\",\"modelChanged\"],[[20,[\"chartData\"]],[20,[\"chartOptions\"]],[20,[\"originalContacts\"]],[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[25,\"contact-details\",null,[[\"originalContacts\",\"onChangeType\",\"model\",\"goTo\",\"warning\",\"back\",\"commit\",\"next\",\"save\"],[[20,[\"originalContacts\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"onChangeType\"],null],[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"warning\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"commit\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"next\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/contact-details.hbs"
    }
  });

  _exports.default = _default;
});
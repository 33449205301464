define("careshare/serializers/appointment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',
    attrs: {
      "appointment_items": {
        embedded: 'always'
      }
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var typeKey = primaryModelClass.modelName;
      var newPayload = {};
      newPayload[typeKey] = payload;
      return this._normalizeResponse(store, primaryModelClass, newPayload, id, requestType, true);
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/contact-family-details", ["exports", "careshare/config/environment", "careshare/mixins/admission-validate"], function (_exports, _environment, _admissionValidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_admissionValidate.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    queryParams: {
      view: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      this.controllerFor('after-login.residents.contact-family-details').set('originalFamily', Ember.A());
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      var store = this.store;
      return store.findRecord('resident', params.id, {
        backgroundReload: false,
        adapterOptions: {
          expand: 'inventory'
        }
      });
    },
    afterModel: function afterModel(model) {
      var store = this.store;
      var self = this;
      var originalFamily = Ember.A();
      model.set('contact_family_details', Ember.A());
      store.query('contact-family-detail', {
        resident_id: model.id
      }).then(function (contacts) {
        if (contacts.content.length > 0) {
          contacts.forEach(function (contact) {
            model.get('contact_family_details').pushObject(contact);
            originalFamily.pushObject(contact);
          });
        }

        self.controllerFor('after-login.residents.contact-family-details').set('originalFamily', originalFamily);
        Ember.run.schedule("afterRender", this, function () {
          if (model.get('submitted')) {
            Ember.$(".contact-family-content :input").prop("disabled", true);
            Ember.$(".form-button__add").on('click', function () {
              return false;
            });
            Ember.$(".remove-record").on('click', function () {
              return false;
            });
          }
        });
      });
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        model.set('weights', data[0]['weights']);
        model.set('weights_dates', data[0]['dates']);
        model.set('moods', data[1]['moods']);
        model.set('moods_dates', data[1]['dates']);
      });
    },
    actions: {
      save: function save(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          return false;
        }

        var formatedRequestFamilyContactsData = JSON.stringify(resident.get('contact_family_details'));
        var requestFamilyContactsOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contact-family-details?resident_id=' + resident.get('id'),
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestFamilyContactsData
        };
        var saveContactFamilyDetails = Ember.$.ajax(requestFamilyContactsOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.get('contact_family_details').filterBy('hasDirtyAttributes', true).forEach(function (item) {
          item.save();
        });
        Ember.$.when(saveContactFamilyDetails).done(function () {
          self.controllerFor('after-login.residents.contact-family-details').set('originalFamily', resident.get('contact_family_details'));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          resident.set('contact_family_details_valid', 100);
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved');
          Ember.$('.modal-window.success').removeClass('hidden');

          if (resident.get('submitted') == 1) {
            self.transitionTo('after-login.residents.contact-family-details', resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          }
        }).catch(function () {
          resident.set('contact_family_details_valid', 0);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      commit: function commit(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          if (this.isAllTabsValid(resident)) {
            var requestOptions = {
              url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/residents/submit?resident_id=' + resident.get('id') + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
              dataType: 'JSON',
              method: 'PUT',
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
              }
            };
            Ember.$.ajax(requestOptions).then(function (data) {
              resident.set('submitted', data.submitted);
            });
            self.controllerFor('after-login').set('dialogMessage', this.registerResident());
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.residents');
          } else {
            self.controllerFor('after-login').set('dialogMessage', this.notValidMessage());
            Ember.$('.modal-window.error').removeClass('hidden');
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSectionFirst());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      back: function back(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.contact-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      next: function next(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.medical-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      },
      goTo: function goTo(transition, modelSaved, view) {
        var self = this;
        var resident = this.modelFor(this.routeName);
        if (this.routeName == transition) transition = "after-login.residents.admission-details";

        if (modelSaved) {
          if (view == 1) {
            self.transitionTo(transition, resident.get('id'), {
              queryParams: {
                view: '1'
              }
            });
          } else {
            self.transitionTo(transition, resident.get('id'));
          }
        } else {
          self.controllerFor('after-login').set('dialogMessage', this.saveSection());
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      }
    }
  });

  _exports.default = _default;
});
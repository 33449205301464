define("careshare/components/radio-button-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'label',
    classNames: ['radio-label'],
    actions: {
      updateValue: function updateValue() {
        this.changed(this.value);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/role-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent; padding:0',
    didInsertElement: function didInsertElement() {
      var self = this;
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/roles', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (data) {
        self.set('roles', []);
        data.roles.forEach(function (role) {
          self.get('roles').pushObject(role);
        });
        Ember.run.schedule('afterRender', function () {
          var $roleSelect = self.$('select');
          $roleSelect.val(self.get('selected_role'));
          $roleSelect.chosen({
            width: '100%',
            placeholder_text_single: 'Select Role',
            allow_single_deselect: true
          });
        });
      });
    },
    actions: {
      change: function change() {
        var changeAction = this.get('action');
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var content = this.get('roles');
        var selectedValue = content[selectedIndex].name;
        this.set('selectedValue', selectedValue);
        this.set('user.role', selectedValue);
        changeAction(selectedValue);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/logs/resident-edit", ["exports", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('consent', params.id);
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.consent.resident-edit').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    actions: {
      saveData: function saveData(resident) {
        var model = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        model.save().then(function () {
          self.transitionTo('after-login.consent.resident-index', resident, {
            queryParams: {
              resident_id: resident
            }
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      cancel: function cancel(resident) {
        this.transitionTo('after-login.consent.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/authenticators/token", ["exports", "ember-simple-auth/authenticators/base", "careshare/config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    restore: function restore(data) {
      console.log('restore');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.host + '/v1/users/restore',
          method: 'GET',
          headers: {
            'Authorization': data.access_token
          },
          success: function success(data) {
            console.log('resolve');
            resolve(data);
          },
          error: function error(data) {
            console.log('reject');
            console.log(data);
            reject();
          }
        });
      });
    },
    authenticate: function authenticate(login, pass) {
      console.log('authenticate');
      Ember.$('.preloader').removeClass('hidden');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.ajax({
          url: _environment.default.host + '/v1/users/login',
          method: 'POST',
          data: {
            email: login,
            password: pass
          },
          success: function success(data) {
            console.log('resolve');
            Ember.$('.preloader').addClass('hidden');
            resolve(data);
          },
          error: function error(data) {
            console.log('reject');
            console.log(data);
            reject('Login or password is not valid');
            Ember.$('.login-error').removeAttr('hidden');
            Ember.$('.preloader').addClass('hidden');
          }
        });
      });
    },
    invalidate: function invalidate(data) {
      console.log('invalidate');
      console.log(data);
      return new Ember.RSVP.Promise(function (resolve) {
        resolve();
      });
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/appointments/resident-edit", ["exports", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    queryParams: {
      resident_id: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('appointment', params.id, {
        reload: true
      });
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.appointments.resident-edit').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.ui-tooltip').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    validate: function validate() {
      var serviceField = Ember.$('#service');
      var dateField = Ember.$('.date-picker');
      var professionalField = Ember.$('#professional');
      var timeFields = Ember.$('.time-picker');
      var valid = true;

      if (Ember.isEmpty(serviceField.val())) {
        serviceField.addClass('is-invalid');
        valid = false;
        return;
      } else {
        serviceField.removeClass('is-invalid');
        valid = true;
      }

      if (Ember.isEmpty(dateField.val())) {
        dateField.parent().addClass('is-invalid');
        valid = false;
        return;
      } else {
        dateField.parent().removeClass('is-invalid');
        valid = true;
      }

      if (Ember.isEmpty(professionalField.val())) {
        professionalField.next().addClass('is-invalid');
        valid = false;
        return;
      } else {
        professionalField.next().removeClass('is-invalid');
        valid = true;
      }

      timeFields.each(function (index, el) {
        if (Ember.isEmpty($(this).val())) {
          $(this).addClass('is-invalid');
          valid = false;
        }
      });
      return valid;
    },
    actions: {
      saveAppointment: function saveAppointment(resident) {
        var self = this;
        var model = this.modelFor(this.routeName);

        if (this.validate()) {
          var formatedRequestData = JSON.stringify(model.serialize());
          var requestOptions = {
            url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/appointments/' + model.get('id'),
            dataType: 'JSON',
            method: 'PUT',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            },
            data: formatedRequestData
          };
          var saveDetails = Ember.$.ajax(requestOptions);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(saveDetails).done(function (response) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.appointments.resident-index', resident, {
              queryParams: {
                resident_id: resident
              }
            });
          });
        }
      },
      returnToAppointments: function returnToAppointments(resident) {
        this.transitionTo('after-login.appointments.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      deleteAppointment: function deleteAppointment(resident) {
        var _this = this;

        var model = this.modelFor(this.routeName);
        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          Ember.run.later('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          model.destroyRecord().then(function () {
            _this.transitionTo('after-login.appointments.resident-index', resident, {
              queryParams: {
                resident_id: resident
              }
            });
          });
        });
        promise.catch(function () {});
      },
      print: function print() {
        $('.forPrint').printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h1>" + Ember.$('#service').val() + ", " + Ember.$('#professional').val() + ", " + Ember.$('.date-picker').val() + "</h1>"
        });
      }
    }
  });

  _exports.default = _default;
});
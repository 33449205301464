define("careshare/components/permission-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    checked: function () {
      var permission = this.get('permission.name');
      var rolePermissions = this.get('role.permissions').mapBy('name');
      return rolePermissions.contains(permission);
    }.property(),
    save: function () {
      var permissionChecked = this.get('checked');
      var role = this.get('role');
      var permission = this.get('permission');

      if (permissionChecked) {
        role.get('permissions').pushObject(permission);
      } else {
        role.get('permissions').removeObject(permission);
      }

      role.togglePermission({
        role: role.get('name'),
        permission: permission.get('name'),
        is_assign: permissionChecked
      });
    }.observes('checked')
  });

  _exports.default = _default;
});
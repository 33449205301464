define("careshare/controllers/after-login/appointments/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var today = (0, _moment.default)().format('DD.MM.YYYY');
  var period = (0, _moment.default)().add(60, 'days').format('DD.MM.YYYY');

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["date_from", "date_to", "service", "resident_id", "resident_name", "professional_id", "professional_name", "sortBy", "direction"],
    today: today,
    resident_id: '',
    resident_name: '',
    service: '',
    default_service: 'all',
    professional_id: '',
    professional_name: '',
    date_from: today,
    date_to: period,
    sortBy: 'date',
    direction: 'DESC',
    dateFromParam: Ember.computed('date_from', {
      get: function get(k) {
        var v = this.get('date_from');
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    dateToParam: Ember.computed('date_to', {
      get: function get(k) {
        var v = this.get('date_to');
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    actions: {
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      }
    }
  });

  _exports.default = _default;
});
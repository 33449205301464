define("careshare/components/contact-type-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent',
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contact-type-filter', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (types) {
        if (self.get('typeList').length == 0) {
          types.forEach(function (type) {
            self.get('typeList').pushObject(type);
          });
        }

        var $contactTypeSelect = self.$('.contact-type');
        Ember.run.schedule('afterRender', function () {
          $contactTypeSelect.val(self.get('type_id'));
          $contactTypeSelect.change(function (e) {
            if (!Ember.isEmpty($(this).val())) {
              self.set('typeName', $(this).find('option:selected').text());
              self.set('type_id', $(this).val());
            } else {
              self.set('typeName', '');
              self.set('type_id', '');
            }
          });
          $contactTypeSelect.chosen({
            width: '100%',
            placeholder_text_single: 'Select Type',
            allow_single_deselect: true,
            create_option: self.get('addOption') ? true : false,
            persistent_create_option: true,
            skip_no_results: true,
            disable_search_threshold: 100
          });
        });
      });
    }
  });

  _exports.default = _default;
});
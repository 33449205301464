define("careshare/utils/convert-number-to-time", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertNumberToTime;

  function convertNumberToTime(totalMinutes) {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60; // Pad with leading zeros if needed

    var hoursString = String(hours).padStart(2, '0');
    var minutesString = String(minutes).padStart(2, '0');
    return "".concat(hoursString, ":").concat(minutesString);
  }
});
define("careshare/mixins/messages-and-notifications", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    updateTimeInSeconds: 5000,
    increaseMessageCounter: function increaseMessageCounter() {
      var newMessagesCount = parseInt(this.controllerFor('after-login').get('newMessagesCount')) + 1;
      var afterLoginController = this.controllerFor('after-login');
      afterLoginController.set('newMessagesCount', newMessagesCount);

      if (newMessagesCount > 0) {
        afterLoginController.set('isShowNewMessagesBadge', true);
      }
    },
    decreaseMessageCounter: function decreaseMessageCounter() {
      var newMessagesCount = parseInt(this.controllerFor('after-login').get('newMessagesCount')) - 1;
      var afterLoginController = this.controllerFor('after-login');
      afterLoginController.set('newMessagesCount', newMessagesCount);

      if (newMessagesCount === 0) {
        afterLoginController.set('isShowNewMessagesBadge', false);
      }
    },
    decreaseNotificationsCounter: function decreaseNotificationsCounter() {
      var newNotificationsCount = parseInt(this.controllerFor('after-login').get('newNotificationsCount')) - 1;
      var afterLoginController = this.controllerFor('after-login');
      afterLoginController.set('newNotificationsCount', newNotificationsCount);

      if (newNotificationsCount === 0) {
        afterLoginController.set('isShowNewNotificationsBadge', false);
      }
    },
    updateNewMessagesCounter: function updateNewMessagesCounter() {
      var self = this;
      return Ember.$.ajax({
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/messages/get-new-messages-count',
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      });
    },
    updateNewNotificationsCounter: function updateNewNotificationsCounter() {
      var self = this;
      return Ember.$.ajax({
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/notifications/get-new-notifications-count',
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      });
    },
    initNewMessagesCounterUpdate: function initNewMessagesCounterUpdate() {
      var _this = this;

      this.updateNewMessagesCounter().then(function (count) {
        _this.controllerFor('after-login').set('isShowNewMessagesBadge', count > 0);

        _this.controllerFor('after-login').set('newMessagesCount', count);

        _this.controllerFor('after-login').set('isNewMessagesCounterChanged', true);
      });
      setInterval(function () {
        _this.updateNewMessagesCounter().then(function (count) {
          if (_this.controllerFor('after-login').get('newMessagesCount') !== count) {
            _this.controllerFor('after-login').set('isShowNewMessagesBadge', count > 0);

            _this.controllerFor('after-login').set('newMessagesCount', count);

            _this.controllerFor('after-login').set('isNewMessagesCounterChanged', true);
          } else {
            _this.controllerFor('after-login').set('isNewMessagesCounterChanged', false);
          }
        });
      }, this.updateTimeInSeconds);
    },
    initNewNotificationsCounterUpdate: function initNewNotificationsCounterUpdate() {
      var _this2 = this;

      this.updateNewNotificationsCounter().then(function (count) {
        _this2.controllerFor('after-login').set('isShowNewNotificationsBadge', count > 0);

        _this2.controllerFor('after-login').set('newNotificationsCount', count);
      });
      setInterval(function () {
        _this2.updateNewNotificationsCounter().then(function (count) {
          if (_this2.controllerFor('after-login').get('newNotificationsCount') !== count) {
            _this2.controllerFor('after-login').set('isShowNewNotificationsBadge', count > 0);

            _this2.controllerFor('after-login').set('newNotificationsCount', count);

            _this2.controllerFor('after-login').set('isNewNotificationCounterChanged', true);
          } else {
            _this2.controllerFor('after-login').set('isNewNotificationCounterChanged', false);
          }
        });
      }, this.updateTimeInSeconds);
    },
    initMessagesListUpdate: function initMessagesListUpdate() {
      var _this3 = this;

      setInterval(function () {
        var currentPage = _this3.controllerFor('after-login.messages.inbox').get('content.meta.currentPage');

        if (_this3.controllerFor('after-login').get('isNewMessagesCounterChanged') && currentPage <= 1) {
          _this3.refresh();
        }
      }, this.updateTimeInSeconds);
    },
    initNotificationsListUpdate: function initNotificationsListUpdate() {
      var _this4 = this;

      setInterval(function () {
        var currentPage = _this4.controllerFor('after-login.notifications.index').get('content.meta.currentPage');

        if (_this4.controllerFor('after-login').get('isNewNotificationCounterChanged') && currentPage <= 1) {
          _this4.refresh();
        }
      }, this.updateTimeInSeconds);
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    errorMessage: '',
    infoMessage: '',
    invalidEmail: false,
    actions: {
      login: function login() {
        var isError = false;

        var _this$getProperties = this.getProperties('login', 'password'),
            login = _this$getProperties.login,
            password = _this$getProperties.password;

        this.set('infoMessage', '');

        if (!login) {
          this.set('errorMessage', 'Error: All fields are required');
          isError = true;
        }

        if (!password) {
          this.set('errorMessage', 'Error: All fields are required');
          isError = true;
        }

        if (!isError) {
          this.get('session').authenticate('authenticator:token', login, password).then(function () {// this.get('EventBus').publish('trackEvent', 'Login', 'Successful Login', login);
          }).catch(function (reason) {
            console.log(reason); // this.set('errorMessage', 'Error: Invalid username or password');
            // this.get('EventBus').publish('trackEvent', 'Login', 'Unsuccessful Login', login);
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/case-notes/edit", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
        Ember.$(".ui-tooltip").addClass('hidden');
      });
      this.initListeners();
    },
    model: function model(params) {
      return this.store.findRecord('case-note', params.id);
    },
    afterModel: function afterModel(model) {
      var self = this;
      Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/current?id=' + model.get('resident_id') + '&outcome_type=' + model.get('notes_type'), {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        if (data && data[0].outcomes && data[0].outcomes.length && data[0].outcomes[0].notes) {
          Ember.$('.current-outcome').text(data[0].outcomes[0].notes);
        } else {
          Ember.$('.current-outcome').text('Current outcome does not exist');
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    initListeners: function initListeners() {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        var self = _this;
        var dates = Ember.$('.date-picker');
        var selects = Ember.$('.section-select');
        var residents = Ember.$('.resident-select');
        var descriptionDiv = $(_this).closest('.note-item').find('.current-outcome');
        var sectionSelect = $(_this).closest('.note-item').find('.section-select');
        selects.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
          $(this).on("change", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              var _descriptionDiv = $(this).closest('.note-item').find('.current-outcome');

              var residentSelect = $(this).closest('.note-item').find('.resident-select');

              if (!Ember.isEmpty(residentSelect.val())) {
                self.getCurrentOutcome(residentSelect.val(), $(this).val(), _descriptionDiv);
              }
            }
          });
        });
        residents.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
          $(this).on("change", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              var _descriptionDiv2 = $(this).closest('.note-item').find('.current-outcome');

              var _sectionSelect = $(this).closest('.note-item').find('.section-select');

              if (!Ember.isEmpty(_sectionSelect.val())) {
                self.getCurrentOutcome($(this).val(), _sectionSelect.val(), _descriptionDiv2);
              }
            }
          });
        });
      });
    },
    getCurrentOutcome: function getCurrentOutcome(resident, type, div) {
      var self = this;
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
      Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/current?id=' + resident + '&outcome_type=' + type, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (response) {
        if (response[0].outcomes[0]) {
          div.text(response[0].outcomes[0].notes);
        } else {
          div.text('Current outcome does not exist');
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    actions: {
      saveData: function saveData() {
        var model = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        model.save().then(function () {
          self.transitionTo('after-login.case-notes');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      cancel: function cancel() {
        this.transitionTo('after-login.case-notes.index');
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
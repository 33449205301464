define("careshare/mixins/checkboxes-behavior", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Mixin.create({
    initCheckboxesList: function initCheckboxesList(model_attr) {
      var self = this;
      self.set('selected_' + model_attr, Ember.A());
      var isSelectedNone = self.get('model').get(model_attr).findBy('item_description', 'None');

      if (isSelectedNone) {
        self.deleteCheckboxItems(model_attr);
      }

      if (self.get('model').get(model_attr).length) {
        self.get('model').get(model_attr).forEach(function (item) {
          self.get('selected_' + model_attr).push(item.get('item_description'));
        });
      }

      this.mergeCustomCheckboxes(model_attr);
    },
    mergeCustomCheckboxes: function mergeCustomCheckboxes(model_attr) {
      var sArray = this.get('model').get(model_attr).map(function (item) {
        return ListItem.create({
          item_description: item.get('item_description'),
          item_type: item.get('item_type')
        });
      });
      this.set(model_attr + '_items', this.get(model_attr + '_items').concat(sArray));

      var uniqueList = _underscore.default.uniq(this.get(model_attr + '_items'), function (item, key, a) {
        return item.item_description;
      });

      uniqueList = this.moveNoneToEnd(uniqueList);
      this.set(model_attr + '_items', uniqueList);
    },
    deleteCheckboxItems: function deleteCheckboxItems(modelFor) {
      var self = this;
      var attr = Ember.String.underscore(modelFor);
      var itemsToDelete = this.get('model').get(attr).toArray();
      itemsToDelete.forEach(function (item) {
        if (item.get('item_description') !== 'None') {
          var deletedRecord = self.get('model').get(attr).findBy('item_description', item.get('item_description'));
          self.get('model').get(attr).removeObject(deletedRecord);
          self.get('store').deleteRecord(deletedRecord);
        }
      });
    },
    deleteNoneItem: function deleteNoneItem(modelFor) {
      var attr = Ember.String.underscore(modelFor);
      this.get('selected_' + attr).removeObject('None');
    },
    moveNoneToEnd: function moveNoneToEnd(array) {
      for (var x in array) {
        array[x].item_description == "None" ? array.push(array.splice(x, 1)[0]) : 0;
      }

      return array;
    },
    updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
      var attr = Ember.String.underscore(modelFor);
      var self = this;

      if (operation === 'removed') {
        var deletedRecord = this.get('model').get(attr).findBy('item_description', value);
        this.get('model').get(attr).removeObject(deletedRecord);
        this.get('store').deleteRecord(deletedRecord);
      } else {
        if (value == "None") {
          self.deleteCheckboxItems(modelFor);
          self.set('selected_' + attr, Ember.A(['None']));
        } else {
          var noneElement = self.get('model').get(attr).findBy('item_description', 'None');

          if (noneElement) {
            self.get('model').get(attr).removeObject(noneElement);
            self.get('selected_' + attr).removeObject('None');
            self.get('store').deleteRecord(noneElement);
          }
        }

        var item = this.get('store').createRecord(modelFor, {
          item_description: value,
          item_type: "predefined"
        });
        this.get('model').get(attr).pushObject(item);
      }
    },
    addCheckboxItem: function addCheckboxItem(modelFor) {
      var attr = Ember.String.underscore(modelFor);
      var text = Ember.$('#new_' + attr).val();
      Ember.$('#new_' + attr).val('');

      if (Ember.isEmpty(text)) {
        alert('Please, specify text for item');
        return false;
      }

      var existedItems = this.get(attr + '_items');

      if (this.isItemExisted(existedItems, text)) {
        alert('This option is already existed!');
        return false;
      }

      var newItem = {
        item_description: text,
        item_type: "custom"
      };
      existedItems.pushObject(newItem);
    },
    isItemExisted: function isItemExisted(items, newItem) {
      for (var i = 0; i < items.length; i++) {
        if (items[i].item_description.toUpperCase() === newItem.toUpperCase()) {
          return true;
        }
      }

      return false;
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/discharge", ["exports", "careshare/mixins/set-belongs-to"], function (_exports, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_setBelongsTo.default, {
    session: Ember.inject.service('session'),
    model: function model(params) {
      var model = this.store.createRecord('discharge');
      model.resident = this.store.findRecord('residentlite', params.id, {
        backgroundReload: true
      });
      return model;
    },
    actions: {
      saveDischarge: function saveDischarge(res_id) {
        if (Ember.$('.btn').hasClass('disabled')) {
          return false;
        }

        var discharge = this.modelFor(this.routeName);
        var self = this;
        discharge.set('resident_id', res_id);
        discharge.set('location', discharge.get('location'));
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        discharge.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.transitionTo('after-login.residents.list');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        var self = this;
        self.transitionTo('after-login.residents');
      }
    }
  });

  _exports.default = _default;
});
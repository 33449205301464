define("careshare/routes/after-login/residents/change-avatar", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/set-belongs-to"], function (_exports, _environment, _serializeData, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_serializeData.default, _setBelongsTo.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        backgroundReload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('avatarsURL', _environment.default.host + '/uploads/avatars/');
    },
    afterModel: function afterModel(model) {
      var self = this;
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      save: function save() {
        var resident = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
            Ember.$('.modal-window.success').removeClass('hidden');
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved');
          });
          self.transitionTo('after-login.residents.dashboard', resident.get('id'));
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        var self = this;
        self.transitionTo('after-login.residents');
      },
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      },
      viewResidentFromObj: function viewResidentFromObj(res) {
        this.transitionTo('after-login.residents.dashboard', res.id);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/outcome-item", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'tr',
    report_item: null,
    origin_notes: '',
    initFields: function () {
      var self = this;
      this.$('textarea[name="notes"]').attr('readonly', true);
      this.set('origin_notes', this.get('report_item').get('notes'));
      this.$('textarea[name="notes"]').on("mousedown", function (e) {
        $(this).attr('readonly', false);
      });
      this.$('textarea[name="notes"]').on("focusout", function (e) {
        if (Ember.isEmpty($(this).val()) && !Ember.isEmpty(self.get('origin_notes')) || $(this).val() != self.get('origin_notes')) {
          if (!Ember.isEmpty($(this).val())) {
            $(this).removeClass('is-invalid');
          }

          self.send('save', self.get('report_item'));
        }

        $(this).attr('readonly', true);
      });
    }.on('didInsertElement'),
    save: function save(item) {
      var formatedRequestData = JSON.stringify(Ember.A([item]));
      var self = this;
      var outcomeId = item.get('id');
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/' + outcomeId,
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: formatedRequestData
      };
      var save = Ember.$.ajax(requestOptions);
      Ember.$.when(save).done(function () {
        self.send('success', 'Successfully saved!');
      });
    },
    actions: {
      save: function save() {
        this.set('origin_notes', this.get('report_item').get('notes'));
        this.save(this.get('report_item'));
      },
      selectValue: function selectValue(value) {
        this.get('report_item').set(Ember.$(event.target).attr('model-attr'), value);
      },
      success: function success(text) {
        this.sendAction('success', text);
      }
    }
  });

  _exports.default = _default;
});
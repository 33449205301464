define("careshare/utils/convert-time-to-number", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = convertTimeToNumber;

  function convertTimeToNumber(timeString) {
    var _timeString$split$map = timeString.split(':').map(Number),
        _timeString$split$map2 = (0, _slicedToArray2.default)(_timeString$split$map, 2),
        hours = _timeString$split$map2[0],
        minutes = _timeString$split$map2[1];

    return hours * 60 + minutes;
  }
});
define("careshare/models/communication", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    speech: _emberData.default.attr('string'),
    memory: _emberData.default.attr('string'),
    understanding: _emberData.default.attr('string'),
    plan_and_outcomes: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
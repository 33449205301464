define("careshare/templates/after-login/residents/mobility-nutrition-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iC7tCNRP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"mobility-nutrition-details\",null,[[\"model\",\"errors\",\"original_restraint_types\",\"original_nutritional_diagnosis\",\"original_type_of_diet\",\"goTo\",\"back\",\"next\",\"save\"],[[20,[\"model\"]],[20,[\"errors\"]],[20,[\"original_restraint_types\"]],[20,[\"original_nutritional_diagnosis\"]],[20,[\"original_type_of_diet\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"next\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"save\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/mobility-nutrition-details.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/news/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D7Z6V0Yy",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"Edit News\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\n\\t\"],[4,\"news-edit\",null,[[\"model\",\"saveNews\"],[[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveNews\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/news/edit.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/sleeping-percare-preferences-continence-details", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data"], function (_exports, _environment, _serializeData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_serializeData.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        adapterOptions: {
          expand: 'inventory'
        }
      });
    },
    afterModel: function afterModel(model) {
      var self = this;
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/sleeping-percare-preferences-continence-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        self.serializeNestedRecordFor(model, 'sleeping-details', data[0]['data']);
        self.serializeNestedRecordFor(model, 'personal-care', data[0]['data']);
        self.serializeNestedRecordFor(model, 'continence-details', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'bedroom-assistance', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'safety-equipments', data[0]['data']);
        self.serializeNestedRecordsFor(model, 'night-times', data[0]['data']);
        self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_bedroom_assistance', model.get('bedroom_assistance').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_safety_equipments', model.get('safety_equipments').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_night_times', model.get('night_times').map(function (item) {
          return "".concat(item.get('item_description'));
        }));
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    saveData: function saveData() {
      var self = this;
      var resident = this.modelFor(this.routeName);
      var formattedData = {};
      formattedData['sleeping_details'] = resident.get('sleeping_details');
      formattedData['personal_care'] = resident.get('personal_care');
      formattedData['continence_details'] = resident.get('continence_details');
      formattedData['bedroom_assistance'] = resident.get('bedroom_assistance');
      formattedData['safety_equipments'] = resident.get('safety_equipments');
      formattedData['night_times'] = resident.get('night_times');
      self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('errors', {});
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/sleeping-percare-preferences-continence-details/update/' + resident.get('id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: JSON.stringify(formattedData)
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      save: function save(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          return false;
        }

        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(this.saveData()).done(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          resident.get('sleeping_details').save();
          resident.get('personal_care').save();
          resident.get('continence_details').save();
          resident.set('sleeping_percare_preferences_continence_details_valid', 1);
          self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_bedroom_assistance', resident.get('bedroom_assistance').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_safety_equipments', resident.get('safety_equipments').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('original_night_times', resident.get('night_times').map(function (item) {
            return "".concat(item.get('item_description'));
          }));
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          resident.set('sleeping_percare_preferences_continence_details_valid', 0);
          self.controllerFor('after-login.residents.sleeping-percare-preferences-continence-details').set('errors', response.responseJSON);
        });
      },
      back: function back(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.mobility-nutrition-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      next: function next(modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo('after-login.residents.skin-allergies-discharge-details', resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      },
      goTo: function goTo(transition, modelSaved) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (modelSaved) {
          self.transitionTo(transition, resident.get('id'));
        } else {
          self.controllerFor('after-login').set('dialogMessage', 'Please, save the data!');
          Ember.$('.modal-window.error').removeClass('hidden');
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/infection/edit", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    originalDateStart: '',
    originalDateEnd: '',
    model: function model(params) {
      var self = this;
      var record = this.store.findRecord('infection', params.id, {
        adapterOptions: {
          expand: 'infectionResidents'
        }
      }).then(function (record) {
        self.originalDateStart = record.get('date_start');
        self.originalDateEnd = record.get('date_end');
        return record;
      });
      return record;
    },
    beforeModel: function beforeModel() {
      Ember.run.later('afterRender', function () {
        Ember.$('.ui-tooltip').addClass('hidden');
      });
    },
    actions: {
      saveInfection: function saveInfection() {
        var _this = this;

        Ember.$('.table-resident tr').removeClass('error-background');
        var infection = this.modelFor(this.routeName);
        var self = this;
        var invalidDates = [];
        var dateStart = moment(infection.get('date_start'), 'DD.MM.YYYY').format('YYYY-MM-DD');
        var dateEnd = moment(infection.get('date_end'), 'DD.MM.YYYY').format('YYYY-MM-DD');
        var ongoingInfection = !dateEnd;
        var infectionResidents = infection.get('infectionResidents');
        var wrongDate = dateStart > dateEnd && !ongoingInfection;
        var invalidDateResident = false;
        infectionResidents.toArray().forEach(function (resident, index) {
          var dateInfected = moment(resident.get('date_infected'), 'DD.MM.YYYY').format('YYYY-MM-DD');
          var dateRecovered = moment(resident.get('date_recovered'), 'DD.MM.YYYY').format('YYYY-MM-DD');
          var dateDeceased = moment(resident.get('date_deceased'), 'DD.MM.YYYY').format('YYYY-MM-DD');

          if (dateInfected && dateInfected < dateStart) {
            invalidDateResident = true;
            Ember.$('.table-resident tr:nth-child(' + (index + 2) + ')').addClass('error-background');
          }

          if (!ongoingInfection) {
            if (dateRecovered && (dateRecovered < dateStart || dateRecovered > dateEnd) || dateDeceased && (dateDeceased < dateStart || dateDeceased > dateEnd)) {
              invalidDateResident = true;
              Ember.$('.table-resident tr:nth-child(' + (index + 1) + ')').addClass('error-background');
            }
          }
        });

        if (wrongDate || invalidDateResident) {
          if (wrongDate) {
            this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
            infection.set('date_start', self.originalDateStart);
            infection.set('date_end', self.originalDateEnd);
          } else if (invalidDateResident) {
            this.controllerFor('after-login').set('dialogMessage', 'Invalid date selection for at least one resident!');
          }

          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          infection.save().then(function () {
            infection.set('infectionResidents', Array());
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
            Ember.$('.modal-window.success').removeClass('hidden');

            _this.set('invalidDates', Ember.A([]));

            self.transitionTo('after-login.infection');
          }).catch(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      },
      back: function back() {
        this.transitionTo('after-login.infection');
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
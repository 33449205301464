define("careshare/components/mobility-nutrition-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header',
    idsErrors: [],
    assistanceTransferItems: [ListItem.create({
      item_description: "One Person",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Two Person",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hoist",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Other",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    type_of_diet_items: [ListItem.create({
      item_description: "Dairy Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Food cut up",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Fortified drinks",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Gluten Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Halal",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Fibre",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Kosher",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Low salt",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Normal",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "PEG feed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pureed",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Soft",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Thickening fluids",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Vegan",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Vegetarian",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Wheat Free",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    nutritional_diagnosis_items: [ListItem.create({
      item_description: "Allergy",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Celiac",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Constipation",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Blood Pressure",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "High Cholestorol",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "IBS",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Low Blood Pressure",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    restraint_types_items: [ListItem.create({
      item_description: "Bed rail",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bed mat",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Chair mat",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Wheelchair",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Table",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Hoist",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('type_of_diet');
      this.initCheckboxesList('nutritional_diagnosis');
      this.initCheckboxesList('restraint_types');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {
          Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('model.mobility').get('mobile_aids')) {
        Ember.$('#mobile-aids-group-options').show();
      } else {
        Ember.$('#mobile-aids-group-options').hide();
      }

      if (this.get('model.mobility').get('restraint')) {
        Ember.$('#restraint-group').show();
      } else {
        Ember.$('#restraint-group').hide();
      }
    },
    modelWasChanged: Ember.observer('mobileAids', 'restraint', 'medicalWasChanged', 'nutritionWasChanged', 'reminiscenceWasChanged', 'restraintTypesWasChanged', 'nutritionalDiagnosisWasChanged', 'typeOfDietWasChanged', function () {
      if (this.get('medicalWasChanged') || this.get('nutritionWasChanged') || this.get('reminiscenceWasChanged') || this.get('restraintTypesWasChanged') || this.get('nutritionalDiagnosisWasChanged') || this.get('typeOfDietWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('mobileAids')) {
        Ember.$('#mobile-aids-group-options').show();
      } else {
        Ember.$('#mobile-aids-group-options').hide();
      }

      if (this.get('restraint')) {
        Ember.$('#restraint-group').show();
      } else {
        Ember.$('#restraint-group').hide();
      }
    }).on('init'),
    restraint: Ember.computed('model.mobility.restraint', function () {
      return this.get('model.mobility.restraint');
    }),
    mobileAids: Ember.computed('model.mobility.mobile_aids', function () {
      return this.get('model.mobility.mobile_aids');
    }),
    medicalWasChanged: Ember.computed('model.mobility.hasDirtyAttributes', function () {
      return this.get('model.mobility.hasDirtyAttributes');
    }),
    nutritionWasChanged: Ember.computed('model.nutrition.hasDirtyAttributes', function () {
      return this.get('model.nutrition.hasDirtyAttributes');
    }),
    reminiscenceWasChanged: Ember.computed('model.reminiscence.hasDirtyAttributes', function () {
      return this.get('model.reminiscence.hasDirtyAttributes');
    }),
    restraintTypesWasChanged: Ember.computed('model.restraint_types.[]', 'original_restraint_types', function () {
      return !_underscore.default.isEqual(this.get('model').get('restraint_types').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_restraint_types').sort());
    }),
    nutritionalDiagnosisWasChanged: Ember.computed('model.nutritional_diagnosis.[]', 'original_nutritional_diagnosis', function () {
      return !_underscore.default.isEqual(this.get('model').get('nutritional_diagnosis').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_nutritional_diagnosis').sort());
    }),
    typeOfDietWasChanged: Ember.computed('model.type_of_diet.[]', 'original_type_of_diet', function () {
      return !_underscore.default.isEqual(this.get('model').get('type_of_diet').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_type_of_diet').sort());
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.mobility').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
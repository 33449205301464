define("careshare/components/skin-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    skin_integrity_items: [ListItem.create({
      item_description: "Skin dry or fragile",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin breakdown",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Skin oedematous",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('skin_integrity');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('skinDetailsWasChanged', 'healthy', 'skinIntegrityWasChanged', function () {
      if (this.get('skinDetailsWasChanged') || this.get('skinIntegrityWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('healthy')) {
        Ember.$('#healthy-group').hide();
        Ember.$('#healthy-group-2').hide();
      } else {
        Ember.$('#healthy-group').show();
        Ember.$('#healthy-group-2').show();
      }
    }).on('init'),
    skinDetailsWasChanged: Ember.computed('model.skin_details.hasDirtyAttributes', function () {
      return this.get('model.skin_details.hasDirtyAttributes');
    }),
    skinIntegrityWasChanged: Ember.computed('model.skin_integrity.[]', 'original_skin_integrity', function () {
      return !_underscore.default.isEqual(this.get('model').get('skin_integrity').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_skin_integrity').sort());
    }),
    healthy: Ember.computed('model.skin_details.healthy', function () {
      return this.get('model.skin_details.healthy');
    }),
    didInsertElement: function didInsertElement() {
      if (this.get('model.skin_details').get('healthy')) {
        Ember.$('#healthy-group').hide();
        Ember.$('#healthy-group-2').hide();
      } else {
        Ember.$('#healthy-group').show();
        Ember.$('#healthy-group-2').show();
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#skin-details').offset().top - 58);
      });
    },
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.discharge').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/roles/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('role');
    },
    actions: {
      saveRole: function saveRole() {
        var role = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        role.set('name', role.get('name'));
        role.set('description', role.get('description'));
        role.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          Ember.$('.modal-window.success').removeClass('hidden');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          self.transitionTo('after-login.roles.index');
        }, function (error) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/key-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InventoryListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    yearRange: '',
    inventory_items: [InventoryListItem.create({
      item_description: "Reading Glasses",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Glasses",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Hearing Aid",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Walking Stick",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Walking Frame",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "Dentures",
      item_type: "predefined"
    }), InventoryListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('inventory');
      this.set('range', new Date().getFullYear() - 118 + ':' + (new Date().getFullYear() - 18));
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    modelWasChanged: Ember.observer('residentWasChanged', 'inventoryWasChanged', function () {
      if (this.get('residentWasChanged') || this.get('inventoryWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }
    }).on('init'),
    residentWasChanged: Ember.computed('model.hasDirtyAttributes', function () {
      return this.get('model.hasDirtyAttributes');
    }),
    inventoryWasChanged: Ember.computed('model.inventory.[]', 'original_inventory', function () {
      return !_underscore.default.isEqual(this.get('model').get('inventory').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_inventory').sort());
    }),
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#key-details').offset().top - 58);
      });
    },
    actions: {
      selectValue: function selectValue(gender) {
        this.get('model').set(Ember.$(event.target).attr('model-attr'), gender);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
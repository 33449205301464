define("careshare/components/aupac-control", ["exports", "ember-aupac-control/components/aupac-control"], function (_exports, _aupacControl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _aupacControl.default;
    }
  });
});
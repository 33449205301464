define("careshare/models/contact-family-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    contact_type: _emberData.default.attr('string'),
    contact_description: _emberData.default.attr('string'),
    contact_name: _emberData.default.attr('string'),
    contact_phone: _emberData.default.attr('string'),
    contact_email: _emberData.default.attr('string'),
    contact_nok: _emberData.default.attr('number'),
    contact_poa: _emberData.default.attr('number'),
    contact_time: _emberData.default.attr('string'),
    contact_day_to_day: _emberData.default.attr('number'),
    contact_financial_poa: _emberData.default.attr('number'),
    contact_welfare_poa: _emberData.default.attr('number'),
    contact_financial_guardian: _emberData.default.attr('number'),
    contact_welfare_guardian: _emberData.default.attr('number'),
    contact_type_other: _emberData.default.attr('string'),
    order: _emberData.default.attr('number')
  });

  _exports.default = _default;
});
define("careshare/models/activity", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    location: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),
    risk_assessment: _emberData.default.attr('number'),
    activityResidents: _emberData.default.hasMany('activity-resident', {
      async: false,
      embedded: 'always',
      polymorphic: false
    }),
    residents_cnt: Ember.computed('activityResidents.length', function () {
      return this.get('activityResidents.length');
    })
  });

  _exports.default = _default;
});
define("careshare/serializers/application", ["exports", "ember-data", "careshare/mixins/pagination"], function (_exports, _emberData, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_pagination.default, {
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'discharge-reasons':
          return 'discharge-reasons';

        case 'allergies':
          return 'allergies';

        case 'skin-details':
          return 'skin-details';

        case 'allergies-details':
          return 'allergies-details';

        case 'sleeping-details':
          return 'sleeping-details';

        case 'preferences-details':
          return 'preferences-details';

        case 'continence-details':
          return 'continence-details';

        case 'continence-urine-daytime-aids':
          return 'continence-urine-daytime-aids';

        case 'continence-urine-nightime-aids':
          return 'continence-urine-nightime-aids';

        case 'continence-faeces-daytime-aids':
          return 'continence-faeces-daytime-aids';

        case 'continence-faeces-nightime-aids':
          return 'continence-faeces-nightime-aids';

        case 'safety-equipments':
          return 'safety-equipments';

        case 'night-times':
          return 'night-times';

        case 'restraint-types':
          return 'restraint-types';

        case 'hoist-types':
          return 'hoist-types';

        case 'assistance-transfers':
          return 'assistance-transfers';

        case 'nutritional-diagnosis':
          return 'nutritional-diagnosis';

        case 'nutritional-aids':
          return 'nutritional-aids';

        case 'nutritional-assistance':
          return 'nutritional-assistance';

        case 'medical-conditions':
          return 'medical-conditions';

        case 'communication-methods':
          return 'communication-methods';

        case 'communication-languages':
          return 'communication-languages';

        case 'communication-interactions':
          return 'communication-interactions';

        case 'communication-aids':
          return 'communication-aids';

        case 'communication-impairments':
          return 'communication-impairments';

        case 'resident-filter':
          return 'resident-filter';

        case 'shift-summary-report':
          return 'shift-summary';

        default:
          return this._super(payloadKey);
      }
    },
    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },
    normalizeSingleResponse: function normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      var typeKey = primaryModelClass.modelName;
      var newPayload = {};
      newPayload[typeKey] = payload;
      return this._normalizeResponse(store, primaryModelClass, newPayload, id, requestType, true);
    }
  });

  _exports.default = _default;
});
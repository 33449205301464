define("careshare/components/news-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: 'modal-window',
    didInsertElement: function didInsertElement() {
      var self = this;
      self.$().addClass(self.get('type'));
    },
    actions: {
      close: function close() {
        this.$().addClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/helpers/format-time", ["exports", "@babel/runtime/helpers/esm/toArray"], function (_exports, _toArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatCurrency = formatCurrency;

  function formatCurrency(_ref) {
    var _ref2 = (0, _toArray2.default)(_ref),
        value = _ref2[0],
        rest = _ref2.slice(1);

    return value.slice(0, -3);
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});
define("careshare/routes/after-login/weight/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.ui-tooltip').addClass('hidden');
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('weight', params.id);
    },
    afterModel: function afterModel(model) {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    actions: {
      saveData: function saveData() {
        var model = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        model.save().then(function () {
          self.transitionTo('after-login.weight');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      cancel: function cancel() {
        this.transitionTo('after-login.weight');
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    resident: _emberData.default.belongsTo('resident'),
    notification: _emberData.default.attr('string'),
    reference: _emberData.default.attr('string'),
    parent_entity: _emberData.default.attr('string'),
    link_to_parent_entity: Ember.computed('parent_entity', function () {
      return 'after-login.' + this.get('parent_entity') + '.edit';
    }),
    parent_id: _emberData.default.attr('number'),
    created_by: _emberData.default.attr('number'),
    creator: _emberData.default.belongsTo('user'),
    creatorName: Ember.computed('creator', function () {
      return this.get('creator').content ? this.get('creator').get('full_name') : 'Auto generated';
    }),
    created_at: _emberData.default.attr('date-format'),
    is_read: _emberData.default.attr('boolean'),
    is_completed: _emberData.default.attr('boolean'),
    setRead: function setRead() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      this.set('is_read', true);
      return adapter.setRead(this.get('id'));
    },
    setCompleted: function setCompleted() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      this.set('is_completed', true);
      return adapter.setCompleted(this.get('id'));
    }
  });

  _exports.default = _default;
});
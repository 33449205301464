define("careshare/components/new-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service('store'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('model', this.get('store').createRecord('message'));
      var model = this.get('model');
      model.subject = this.get('subject');
      model.message = this.get('body');
    },
    actions: {
      sendMessage: function sendMessage() {
        var message = this.get('model');
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        var messageReceiverId = message.get('to_id') > 0 ? message.get('to_id') : null;
        message.set('to_id', messageReceiverId);
        message.set('resident_id', this.get('residentId'));
        message.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          Ember.$('.modal-window.success').removeClass('hidden');
          self.set('afterLoginController.dialogMessage', 'Successfully sent!');
          self.set('afterLoginController.newMessageModal', false);
          var currentRouteName = self.get('router.currentRouteName');

          if (typeof self.get('transitionTo') === 'undefined' && currentRouteName !== 'after-login.messages.new') {
            self.sendAction('refresh');
            return;
          }

          var transitionTo = typeof self.get('transitionTo') === 'undefined' ? 'after-login.messages.sent' : self.get('transitionTo');
          self.get('router').transitionTo(transitionTo);
        }, function (error) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/message-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lyErL5sB",
    "block": "{\"symbols\":[],\"statements\":[[6,\"ul\"],[9,\"class\",\"nav nav-tabs no-border\"],[7],[0,\"\\n\\t\"],[6,\"li\"],[7],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"after-login.messages.inbox\"],[[\"class\"],[\"nav-link\"]],{\"statements\":[[0,\"Inbox\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[6,\"li\"],[7],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"after-login.messages.sent\"],[[\"class\"],[\"nav-link\"]],{\"statements\":[[0,\"Sent\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/message-tabs.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/user-position-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ehl84we",
    "block": "{\"symbols\":[\"userPosition\"],\"statements\":[[6,\"label\"],[9,\"class\",\"title\"],[9,\"for\",\"position\"],[7],[0,\"Position\"],[8],[0,\"\\n\"],[6,\"select\"],[9,\"name\",\"position\"],[9,\"class\",\"form-control chosen position-select\"],[9,\"id\",\"position\"],[3,\"action\",[[19,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"constants\",\"user_positions\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"item_description\"]]]]],[7],[1,[19,1,[\"item_description\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/user-position-select.hbs"
    }
  });

  _exports.default = _default;
});
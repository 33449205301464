define("careshare/helpers/define", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          obj = _ref2[0],
          key = _ref2[1],
          val = _ref2[2];

      this.set('val', val);
      Ember.set(obj, key, val);
    },
    changed: Ember.observer('val', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/adverse-events/resident-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MEy/S8gm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"resident-adverse-event-edit\",null,[[\"model\",\"resident\",\"constants\",\"deleteEvent\",\"saveEvent\",\"title\",\"back\",\"print\"],[[20,[\"model\"]],[20,[\"resident\"]],[20,[\"constants\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"deleteEvent\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveEvent\"],null],\"New\",[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"print\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/adverse-events/resident-new.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/units/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["name", "description", "page", "perPage", "sortBy", "sortDirection"],
    sortBy: 'name',
    sortDirection: 'ASC',
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    actions: {
      sortBy: function sortBy(column) {
        var direction = this.get('sortDirection') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('sortDirection', direction);
      }
    }
  });

  _exports.default = _default;
});
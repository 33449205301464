define("careshare/components/swiper-slide", ["exports", "ember-cli-swiper/components/swiper-slide"], function (_exports, _swiperSlide) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _swiperSlide.default;
    }
  });
});
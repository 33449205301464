define("careshare/models/additional", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    middlename: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    maidenname: _emberData.default.attr('string'),
    previous_address: _emberData.default.attr('string'),
    dob: _emberData.default.attr('date-format'),
    room: _emberData.default.attr('string'),
    care_reason: _emberData.default.attr('string'),
    weight: _emberData.default.attr('string'),
    height: _emberData.default.attr('string'),
    eye_color: _emberData.default.attr('string'),
    hair_color: _emberData.default.attr('string'),
    last_review_date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    next_plan_date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    key_worker1_id: _emberData.default.attr('number'),
    key_worker1_lookup: Ember.computed('key_worker1_id', function () {
      return "".concat(this.get('key_worker1_id'));
    }),
    key_worker2_id: _emberData.default.attr('number'),
    key_worker2_lookup: Ember.computed('key_worker2_id', function () {
      return "".concat(this.get('key_worker2_id'));
    }),
    capacity: _emberData.default.attr('string'),
    s47: _emberData.default.attr('string'),
    financial_poa: _emberData.default.attr('string'),
    welfare_poa: _emberData.default.attr('string'),
    financial_guardian: _emberData.default.attr('string'),
    welfare_guardian: _emberData.default.attr('string'),
    fund_managed: _emberData.default.attr('string'),
    pocket_money: _emberData.default.attr('string'),
    sexuality: _emberData.default.attr('string'),
    nationality: _emberData.default.attr('string'),
    religion: _emberData.default.attr('string'),
    user_id: _emberData.default.attr('number'),
    practising: _emberData.default.attr('number'),
    key_details_valid: _emberData.default.attr('number'),
    additional_details_valid: _emberData.default.attr('number'),
    contact_details_valid: _emberData.default.attr('number'),
    contact_family_details_valid: _emberData.default.attr('number'),
    communication_details_valid: _emberData.default.attr('number'),
    avatar: _emberData.default.attr('string'),
    eol_details_valid: _emberData.default.attr('number'),
    medical_details_valid: _emberData.default.attr('number'),
    communication_eol_medical_details_valid: _emberData.default.attr('number'),
    mobility_details_valid: _emberData.default.attr('number'),
    nutrition_details_valid: _emberData.default.attr('number'),
    reminiscence_details_valid: _emberData.default.attr('number'),
    mobility_nutrition_details_valid: _emberData.default.attr('number'),
    sleeping_details_valid: _emberData.default.attr('number'),
    percare_details_valid: _emberData.default.attr('number'),
    preferences_details_valid: _emberData.default.attr('number'),
    continence_details_valid: _emberData.default.attr('number'),
    sleeping_percare_preferences_continence_details_valid: _emberData.default.attr('number'),
    skin_details_valid: _emberData.default.attr('number'),
    allergies_details_valid: _emberData.default.attr('number'),
    skin_allergies_discharge_details_valid: _emberData.default.attr('number'),
    submitted: _emberData.default.attr('number'),
    mood: _emberData.default.attr('string'),
    weights: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    weights_dates: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    moods: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    moods_dates: _emberData.default.attr('array', {
      defaultValue: Ember.A()
    }),
    weightChartData: Ember.computed('weights', function () {
      return {
        labels: this.get('weights_dates'),
        datasets: [{
          label: 'weight',
          data: this.get('weights'),
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    }),
    moodChartData: Ember.computed('moods', function () {
      return {
        labels: this.get('moods_dates'),
        datasets: [{
          label: 'mood',
          data: this.get('moods'),
          backgroundColor: ['rgba(255, 255, 255, 0.2)'],
          borderColor: ['rgba(215, 27, 121, 1)'],
          pointRadius: 2,
          lineTension: 0
        }]
      };
    }),
    chartOptions: {
      responsive: true,
      scaleShowLabels: false,
      legend: {
        display: false,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 1,
          fontSize: 12,
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false
          },
          ticks: {
            display: true,
            beginAtZero: false
          }
        }]
      }
    },
    moodChartOptions: {
      responsive: true,
      scaleShowLabels: false,
      legend: {
        display: false,
        position: 'top',
        align: 'start',
        labels: {
          boxWidth: 1,
          fontSize: 12,
          usePointStyle: true
        }
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            display: true
          }
        }],
        yAxes: [{
          gridLines: {
            drawBorder: false
          },
          ticks: {
            callback: function callback(value) {
              if (value === 1) return 'Angry';else if (value === 2) return 'Frown';else if (value === 3) return 'Sad';else if (value === 4) return 'Meh';else if (value === 5) return 'Happy';else if (value === 6) return 'Laughing';else return '';
            }
          }
        }]
      }
    }
  });

  _exports.default = _default;
});
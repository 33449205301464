define("careshare/components/resident-discharge", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    init: function init() {
      this.set('model', this.get('model'));

      this._super.apply(this, arguments);
    },
    actions: {
      statusChanged: function statusChanged(value) {
        this.get('model.status').set(Ember.$(event.target).attr('model-attr'), value);

        if (event.target.value == "Temporary") {
          Ember.$("#deceased").hide();
        } else {
          Ember.$("#deceased").show();
        }
      },
      locationChanged: function locationChanged(value) {
        this.get('model.location').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
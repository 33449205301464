define("careshare/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    from_id: _emberData.default.attr('number'),
    from: _emberData.default.belongsTo('user'),
    to_id: _emberData.default.attr('number'),
    to: _emberData.default.belongsTo('user'),
    subject: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date-format'),
    resident_id: _emberData.default.attr('number'),
    resident: _emberData.default.belongsTo('resident'),
    is_read: _emberData.default.attr('boolean'),
    setRead: function setRead() {
      var modelName = this.constructor.modelName;
      var adapter = this.store.adapterFor(modelName);
      this.set('is_read', true);
      return adapter.setRead(this.get('id'));
    }
  });

  _exports.default = _default;
});
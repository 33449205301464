define("careshare/components/details-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'div',
    residents: Ember.computed(function () {
      return this.get('store').query('resident', {
        sortBy: 'firstname',
        sortDirection: 'ASC'
      });
    }),
    actions: {
      slideUp: function slideUp() {
        var header_toggle = document.getElementById('resident-header-toggle');
        header_toggle.className = header_toggle.className.replace("slide-down", "slide-up");
        var resident_header = document.getElementById("resident_header");
        resident_header.style.display = "none";
      },
      slideDown: function slideDown() {
        var header_toggle = document.getElementById('resident-header-toggle');
        header_toggle.className = header_toggle.className.replace("slide-up", "slide-down");
        var resident_header = document.getElementById("resident_header");
        resident_header.style.display = "block";
      }
    }
  });

  _exports.default = _default;
});
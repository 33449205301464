define("careshare/templates/components/news-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bC2Xm9Po",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"news-popup\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"content\"],[7],[0,\"\\n\\t\\t\"],[6,\"h1\"],[7],[0,\"Latest News\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"reel\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"h2\"],[7],[1,[20,[\"news\",\"date_formatted\"]],false],[8],[0,\"\\n\\t\\t\\t\"],[6,\"p\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[1,[25,\"ntobr\",[[20,[\"news\",\"content\"]]],null],true],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n    \"],[8],[0,\"\\n    \"],[6,\"input\"],[9,\"class\",\"btn-ok\"],[9,\"value\",\"Ok\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"close\"]],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/news-popup.hbs"
    }
  });

  _exports.default = _default;
});
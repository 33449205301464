define("careshare/controllers/after-login/documents/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["page", "perPage", "category", "type", "resident_id", "sortBy", "direction", "refresh"],
    page: 1,
    perPage: 10,
    category: null,
    type: null,
    resident_id: null,
    sortBy: 'created_at',
    direction: 'DESC',
    mainController: Ember.inject.controller('after-login'),
    categories: [{
      name: 'CI DOCUMENTS',
      types: ['CI Reports', 'Recommendations', 'Requirements', 'Condition Notices', 'Improvement Notices', 'Enforcements', 'Emergency Condition Notices', 'Action Plans', 'Complaints', 'Miscellaneous']
    }, {
      name: 'ORGANISATION INFO',
      types: ['Organisational Chart', 'Newsletters', 'Minutes of  Resident Meetings', 'Questionniares', 'Fire Evacuation Plan', 'Safety Certificates', 'Menus', 'Miscellaneous']
    }, {
      name: 'STAFF DOCUMENTS',
      types: ['Outcomes', 'Case Notes', 'Staff Handbook', 'Internal Procedures', 'Minutes of Staff Meetings', 'Sector Guidelines', 'Health & Safety', 'Maintenance', 'Staff Rotas', 'Staff News', 'Audits', 'Miscellaneous', 'Shift Summaries']
    }, {
      name: 'RESIDENT DOCUMENTS',
      types: ['Care Plans', 'Case Notes', 'Outcomes', 'Risk Assessments', 'Admission Form', 'Occupational Health', 'SSKINS Plan', 'DNACPR', 'AWI', 'Consent to refuse hospital admission ', 'Consent to Restraints', 'PRN Purpose & Outcome Protocol', 'Nutritional Monitoring', 'Legal Authority', 'Personal Expenses Agreement', 'Expenditure/Funds Record', 'Duty of Candour']
    }],
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    documentsUpdate: function (sender, key) {
      if (key === 'content.page') {
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
      }

      if (key === 'content.length') {
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      }
    }.observes('content.page', 'content.length'),
    categoryChanged: function (sender, key) {
      var self = this;
      Ember.run.schedule("afterRender", this, function () {
        Ember.$('#category').val(this.get('category'));

        if (this.get('category') === 'RESIDENT DOCUMENTS') {
          Ember.$('#document-list-header').removeClass('document-select-left-two');
          Ember.$('#document-list-header').addClass('document-select-left');
        } else {
          Ember.$('#document-list-header').addClass('document-select-left-two');
          Ember.$('#document-list-header').removeClass('document-select-left');
          Ember.$('#search').val('');
          this.set('resident_id', '');
          Ember.$('.resident-select').val(this.get('resident_id')).trigger('chosen:updated');
        }
      });

      if (!Ember.isEmpty(this.get('category'))) {
        var types = this.get('categories').filterBy('name', this.get('category'));
        this.set('currentTypes', types[0].types);
        setTimeout(function () {
          Ember.$('#type').val(self.get('type'));
        }, 100);
      }
    }.observes('category'),
    actions: {
      selectCategory: function selectCategory(value) {
        if (!Ember.isEmpty(value)) {
          var types = this.get('categories').filterBy('name', value);
          this.set('currentTypes', types[0].types);
          this.set('category', value);
        } else {
          this.set('selectedType', '');
          this.set('category', '');
          this.set('currentTypes', []);
          this.set('resident_id', '');
        }

        this.set('type', '');
      },
      selectType: function selectType(value) {
        if (!Ember.isEmpty(value)) {
          this.set('selectedType', value);
          this.set('type', value);
        } else {
          this.set('selectedType', '');
          this.set('type', '');
        }
      },
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      }
    }
  });

  _exports.default = _default;
});
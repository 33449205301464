define("careshare/templates/after-login/residents/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PQobwTIV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"resident-edit\",null,[[\"units\",\"model\",\"back\",\"saveResident\",\"changeUnit\"],[[20,[\"units\"]],[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveResident\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"setBelongsTo\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/new.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/logs/resident-index", ["exports", "moment", "ember-cli-pagination/remote/route-mixin", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _moment, _routeMixin, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      event_type: {
        refreshModel: true
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('consent', params);
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.consent.resident-index').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
        });
      });
    },
    actions: {
      addConsent: function addConsent() {
        var resident = this.controllerFor('after-login.consent.resident-index').get('resident_id');
        this.transitionTo('after-login.consent.resident-new', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      editConsent: function editConsent(id, resident) {
        this.transitionTo('after-login.consent.resident-edit', id, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      print: function print() {
        var resident = this.controllerFor('after-login.consent.index').get('resident_name');
        var dateFromFormatted = this.controllerFor('after-login.consent.index').get('date_from');
        var dateToFormatted = this.controllerFor('after-login.consent.index').get('date_to');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/skin/resident-new", ["exports", "careshare/config/environment", "careshare/mixins/graph", "moment"], function (_exports, _environment, _graph, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('skin');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.typeChanged(null, null);
    },
    afterModel: function afterModel(model, transition) {
      var type = model.get('type');
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      }),
          self = this;

      if (transition.queryParams.resident_id) {
        model.set('resident_id', transition.queryParams.resident_id);
      }

      Ember.run.schedule('afterRender', function () {
        if (!Ember.isEmpty(type) && type !== 'select type') {
          Ember.$('#grade').prop("disabled", false);
        }
      });
      this.controllerFor('after-login.skin.resident-new').set('resident_id', transition.queryParams.resident_id);
      this.createEmptyAction(model);
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.skin.resident-new').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    createEmptyAction: function createEmptyAction(model) {
      var today = new Date(),
          action = this.store.createRecord('skin-action'),
          h = today.getHours(),
          m = today.getMinutes(),
          date = (0, _moment.default)().format("DD.MM.YYYY");
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      action.set('time', h + ":" + m);
      action.set('date', date);
      model.get('skinActions').unshiftObject(action);
    },
    actions: {
      saveData: function saveData(resident) {
        var _this = this;

        var model = this.modelFor(this.routeName);
        var controller = this.controllerFor('after-login.skin.resident-edit');
        var someActionNotSet = false;
        model.get('skinActions').forEach(function (action) {
          if (!action.get('action')) {
            someActionNotSet = true;
          }
        });

        if (!model.get('resident_id')) {
          this.controllerFor('after-login').set('dialogMessage', 'Resident is required!');
          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          if (someActionNotSet) {
            this.controllerFor('after-login').set('dialogMessage', 'Some action has not been selected!');
            Ember.$('.modal-window.error').removeClass('hidden');
          } else {
            document.getElementById("location_x").focus();
            document.getElementById("location_y").focus();
            document.getElementById("location_img_x").focus();
            document.getElementById("location_img_y").focus();
            model.set('user_id', this.get('session.data.authenticated.user_id'));
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').removeClass('hidden');
            });
            model.save().then(function (returnItem) {
              Ember.run.schedule('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });

              _this.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');

              Ember.$('.modal-window.success').removeClass('hidden');
              Ember.$('.ui-tooltip').addClass('hidden');
              controller.set('newAdded', true);

              _this.transitionTo('after-login.skin.resident-edit', model.id, {
                queryParams: {
                  resident_id: resident
                }
              });
            }).catch(function () {
              Ember.run.schedule('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
            });
          }
        }
      },
      back: function back(resident) {
        var model = this.modelFor(this.routeName);
        model.set('skinActions', []);
        this.transitionTo('after-login.skin.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      removeAction: function removeAction(model, action) {
        model.get('skinActions').removeObject(action);
        this.get('store').deleteRecord(action);
      }
    }
  });

  _exports.default = _default;
});
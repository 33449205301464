define("careshare/templates/components/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zV3Wy/iw",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"type\",\"file\"],[10,\"id\",[26,[[18,\"fileUploadID\"]]]],[10,\"class\",[26,[[18,\"fileUploadClass\"]]]],[9,\"multiple\",\"\"],[7],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/file-upload.hbs"
    }
  });

  _exports.default = _default;
});
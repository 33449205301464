define("careshare/components/staff-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'select',
    className: 'chosen form-control contact-type',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/staff-filter/all', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (staffs) {
        self.set('staffList', []);
        staffs.forEach(function (staff) {
          self.get('staffList').pushObject(staff);
        });
        Ember.run.schedule('afterRender', function () {
          self.$(this).val(self.get('staffId'));
          self.$(this).change(function (e) {
            if (!Ember.isEmpty($(this).val())) {
              self.set('staffName', $(this).find('option:selected').text());
              self.set('staffId', $(this).val());
            } else {
              self.set('staffName', '');
              self.set('staffId', '');
            }
          });
          self.$(this).chosen({
            width: '100%',
            placeholder_text_single: 'Select Staff',
            allow_single_deselect: false,
            create_option: self.get('addOption') ? true : false,
            persistent_create_option: true,
            skip_no_results: true,
            disable_search_threshold: 100
          });
        });
      });
    }
  });

  _exports.default = _default;
});
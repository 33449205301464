define("careshare/models/nutrition", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    appetite: _emberData.default.attr('string'),
    monitoring: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    risk_of_choking: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    notes: _emberData.default.attr('string'),
    plan_and_outcomes: _emberData.default.attr('string'),
    monitoring_fluid: _emberData.default.attr('boolean', {
      allowNull: true
    }),
    monitoring_food: _emberData.default.attr('boolean', {
      allowNull: true
    })
  });

  _exports.default = _default;
});
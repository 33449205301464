define("careshare/templates/after-login/reports/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6KLq7UZH",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"Reports\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[6,\"form\"],[9,\"class\",\"form-scroll form-scroll_footer form-scroll_table\"],[9,\"novalidate\",\"\"],[7],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"row form-wrapper\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"col-lg-12 form-inner-column\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"p\"],[7],[6,\"a\"],[9,\"class\",\"btn btn-primary btn-pink\"],[9,\"href\",\"#\"],[3,\"action\",[[19,0,[]],\"residentDetailsReport\"]],[7],[0,\"Resident details \"],[6,\"i\"],[9,\"class\",\"fa fa-file-excel fa-fw\"],[9,\"aria-hidden\",\"true\"],[7],[8],[8],[8],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/reports/index.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/resident-logs-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nQgBAtBr",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"admin-header\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col\"],[7],[0,\"\\n\"],[4,\"if\",[[25,\"can\",[\"/v1/logs/index\"],null]],null,{\"statements\":[[0,\"                \"],[6,\"ul\"],[9,\"class\",\"admin-pages\"],[7],[0,\"\\n                    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[25,\"eq\",[[20,[\"active\"]],\"expenses\"],null],\"active\"],null]]]],[7],[0,\"\\n                        \"],[6,\"a\"],[9,\"class\",\"nav-link\"],[10,\"href\",[26,[\"/consent/\",[20,[\"model\",\"id\"]],\"/resident-index?resident_id=\",[20,[\"model\",\"id\"]]]]],[7],[0,\"Expenses\"],[8],[0,\"\\n                    \"],[8],[0,\"\\n                    \"],[6,\"li\"],[10,\"class\",[26,[[25,\"if\",[[25,\"eq\",[[20,[\"active\"]],\"complaints\"],null],\"active\"],null]]]],[7],[0,\"\\n                        \"],[6,\"a\"],[9,\"class\",\"nav-link\"],[10,\"href\",[26,[\"/consent/\",[20,[\"model\",\"id\"]],\"/resident-index?resident_id=\",[20,[\"model\",\"id\"]]]]],[7],[0,\"Complaints\"],[8],[0,\"\\n                    \"],[8],[0,\"\\n                \"],[8],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[8],[0,\"\\n    \"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/resident-logs-header.hbs"
    }
  });

  _exports.default = _default;
});
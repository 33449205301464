define("careshare/helpers/capitalize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(string) {
    if (string[0] !== null) {
      return string[0].charAt(0).toUpperCase() + string[0].slice(1);
      ;
    }
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});
define("careshare/routes/after-login/outcomes/index", ["exports", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin"], function (_exports, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      outcome_type: {
        refreshModel: true
      },
      resident_name: {
        refreshModel: false
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      localStorage.setItem('previousOutcomeTransition', 'index');
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.expand = 'resident';
      params.current = 1;
      return this.findPaged('outcome', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.outcomes.index').get('sortBy');
        var direction = self.controllerFor('after-login.outcomes.index').get('direction') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.table-responsive').find('a').removeClass('top');
        Ember.$('.table-responsive').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    },
    generatePdf: function generatePdf(resident, outcomeType) {
      var self = this;
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/pdf?resident_id=' + resident + '&outcome_type=' + outcomeType + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      addUpdate: function addUpdate() {
        var resident = this.controllerFor('after-login.outcomes.index').get('resident_id');
        var outcomeType = this.controllerFor('after-login.outcomes.index').get('outcome_type');
        this.transitionTo('after-login.outcomes.new', {
          queryParams: {
            resident_id: resident,
            outcome_type: outcomeType
          }
        });
      },
      editOutcome: function editOutcome(id) {
        this.transitionTo('after-login.outcomes.edit', id);
      },
      applyFilter: function applyFilter() {
        var emptyOutcomeType = Ember.isEmpty(this.controllerFor('after-login.outcomes.index').get('outcome_type'));

        if (emptyOutcomeType) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!emptyOutcomeType) {
          this.refresh();
        }
      },
      createPdf: function createPdf() {
        var self = this;
        var resident = this.controllerFor('after-login.outcomes.index').get('resident_id');
        var outcomeType = this.controllerFor('after-login.outcomes.index').get('outcome_type') ? this.controllerFor('after-login.outcomes.index').get('outcome_type') : this.controllerFor('after-login.outcomes.index').get('default_outcome_type');

        if (Ember.isEmpty(outcomeType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(outcomeType)) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(self.generatePdf(resident, outcomeType)).done(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Document successfully saved to documents!');
            Ember.$('.modal-window.success').removeClass('hidden');
          });
        }
      },
      print: function print() {
        var self = this;
        var resident_id = this.controllerFor('after-login.outcomes.index').get('resident_id');
        var resident = this.controllerFor('after-login.outcomes.index').get('resident_name');
        var outcomeType = this.controllerFor('after-login.outcomes.index').get('outcome_type') ? this.controllerFor('after-login.outcomes.index').get('outcome_type') : this.controllerFor('after-login.outcomes.index').get('default_outcome_type');
        var sortBy = this.controllerFor('after-login.outcomes.index').get('sortBy');
        var direction = this.controllerFor('after-login.outcomes.index').get('direction');

        if (Ember.isEmpty(outcomeType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(outcomeType)) {
          return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/print?resident_id=' + resident_id + '&outcome_type=' + outcomeType + '&sortBy=' + sortBy + '&direction=' + direction, {
            method: 'GET',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            }
          })]).then(function (data) {
            self.controllerFor('after-login.outcomes.index').set('outcomesForPrint', data[0].outcomes);
            $('.forPrint').printThis({
              importCSS: true,
              loadCSS: "",
              header: "<h1>Current " + (outcomeType && outcomeType !== 'all' ? outcomeType + ' outcomes' : 'outcomes') + (resident && resident !== 'all' ? ' for ' + resident : '') + "</h1>"
            });
            Ember.run.next(function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/units/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('unit');
    },
    actions: {
      saveUnit: function saveUnit() {
        var unit = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        unit.set('name', unit.get('name'));
        unit.set('residents_count', unit.get('residents_count'));
        unit.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          Ember.$('.modal-window.success').removeClass('hidden');
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          self.transitionTo('after-login.units.index');
        }, function (error) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
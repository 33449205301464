define("careshare/controllers/after-login/activities/resident-index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var past = (0, _moment.default)().subtract(30, 'd').format('DD.MM.YYYY');
  var today = (0, _moment.default)().add(30, 'd').format('DD.MM.YYYY');

  var _default = Ember.Controller.extend({
    queryParams: ["page", "perPage", "expand", "sortBy", "direction", "resident_id", "date_from", "date_to"],
    today: today,
    resident_id: '',
    date_from: past,
    date_to: today,
    resident_name: '',
    expand: "activityResidents",
    sortBy: 'date',
    direction: 'DESC',
    dateFromParam: Ember.computed('date_from', {
      get: function get(k) {
        var v = this.get('date_from');
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    dateToParam: Ember.computed('date_to', {
      get: function get(k) {
        var v = this.get('date_to');
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    pagedContent: Ember.observer('content.[]', function () {
      Ember.run.later('afterRender', function () {
        Ember.$('.resident-cnt').attr('title', function () {
          return $(this).find('.residents-list').remove().html();
        });
        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    }),
    actions: {
      sortBy: function sortBy(column) {
        var direction = this.get('direction') == 'DESC' ? 'ASC' : 'DESC';
        this.set('sortBy', column);
        this.set('direction', direction);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/new-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    store: Ember.inject.service('store'),
    session: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('model', this.get('store').createRecord('notification'));
      var model = this.get('model');
      model.reference = this.get('reference');
      model.notification = this.get('notification');
    },
    actions: {
      sendNotification: function sendNotification() {
        var notification = this.get('model');
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        notification.set('created_by', this.get('session').get('data.authenticated.user_id'));
        notification.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          Ember.$('.modal-window.success').removeClass('hidden');
          self.set('afterLoginController.dialognotification', 'Notification created!');
          self.set('afterLoginController.newnotificationModal', false);
          var currentRouteName = self.get('router.currentRouteName');

          if (typeof self.get('transitionTo') === 'undefined' && currentRouteName !== 'after-login.notifications.new') {
            self.sendAction('refresh');
            return;
          }

          var transitionTo = typeof self.get('transitionTo') === 'undefined' ? 'after-login.notifications' : self.get('transitionTo');
          self.get('router').transitionTo(transitionTo);
        }, function (error) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      }
    }
  });

  _exports.default = _default;
});
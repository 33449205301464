define("careshare/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    full_name: Ember.computed('firstname', 'lastname', function () {
      return "".concat(this.get('firstname'), " ").concat(this.get('lastname'));
    }),
    password: _emberData.default.attr('string'),
    position: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    last_news: _emberData.default.attr('number')
  });

  _exports.default = _default;
});
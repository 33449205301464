define("careshare/templates/components/radio-button-with-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EtYk411L",
    "block": "{\"symbols\":[],\"statements\":[[6,\"input\"],[9,\"type\",\"radio\"],[10,\"name\",[18,\"name\"],null],[10,\"value\",[18,\"value\"],null],[10,\"checked\",[25,\"eq\",[[20,[\"groupValue\"]],[20,[\"value\"]]],null],null],[10,\"onchange\",[25,\"action\",[[19,0,[]],\"updateValue\"],null],null],[7],[8],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"radio-label-text\"],[7],[1,[18,\"label\"],false],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/radio-button-with-label.hbs"
    }
  });

  _exports.default = _default;
});
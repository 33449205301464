define("careshare/routes/after-login/appointments/new", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    dialog: Ember.inject.service(),
    beforeModel: function beforeModel() {},
    model: function model() {
      return this.store.createRecord('appointment');
    },
    afterModel: function afterModel(model) {
      this.createItem(model);
    },
    createItem: function createItem(model) {
      var item = this.store.createRecord('appointment-item');
      item.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      var note = this.store.createRecord('appointment-item-note');
      note.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      item.get('item_notes').pushObject(note);
      model.get('appointment_items').pushObject(item);
    },
    validate: function validate() {
      var serviceField = Ember.$('#service');
      var dateField = Ember.$('.date-picker');
      var professionalField = Ember.$('#professional');
      var timeFields = Ember.$('.time-picker');
      var valid = true;

      if (Ember.isEmpty(serviceField.val())) {
        serviceField.addClass('is-invalid');
        valid = false;
        return;
      } else {
        serviceField.removeClass('is-invalid');
        valid = true;
      }

      if (Ember.isEmpty(dateField.val())) {
        dateField.parent().addClass('is-invalid');
        valid = false;
        return;
      } else {
        dateField.parent().removeClass('is-invalid');
        valid = true;
      }

      if (Ember.isEmpty(professionalField.val())) {
        professionalField.next().addClass('is-invalid');
        valid = false;
        return;
      } else {
        professionalField.next().removeClass('is-invalid');
        valid = true;
      }

      timeFields.each(function (index, el) {
        if (Ember.isEmpty($(this).val())) {
          $(this).addClass('is-invalid');
          valid = false;
        }
      });
      return valid;
    },
    actions: {
      saveAppointment: function saveAppointment() {
        var self = this;
        var model = this.modelFor(this.routeName);

        if (this.validate()) {
          var formatedRequestData = JSON.stringify(model.serialize());
          var requestOptions = {
            url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/appointments',
            dataType: 'JSON',
            method: 'POST',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            },
            data: formatedRequestData
          };
          var saveDetails = Ember.$.ajax(requestOptions);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(saveDetails).done(function (response) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
            Ember.$('.modal-window.success').removeClass('hidden');
            self.transitionTo('after-login.appointments');
          }).catch(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      },
      returnToAppointments: function returnToAppointments() {
        this.transitionTo('after-login.appointments');
      },
      deleteAppointment: function deleteAppointment() {
        var _this = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          _this.transitionTo('after-login.appointments');
        });
        promise.catch(function () {});
      },
      print: function print() {
        $('.forPrint').printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h1>" + Ember.$('#service').val() + ", " + Ember.$('#professional').val() + ", " + Ember.$('.date-picker').val() + "</h1>"
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/shift-summary-report/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0WkWe06f",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"shift-summary-report\",null,[[\"model\",\"date\",\"shift\",\"unit\",\"resident_id\",\"residentName\",\"unitName\",\"submit\",\"createPdf\",\"title\",\"sort\",\"sortDirection\",\"sortField\"],[[20,[\"sortedShiftElements\"]],[20,[\"date\"]],[20,[\"shift\"]],[20,[\"unit\"]],[20,[\"resident_id\"]],[20,[\"residentName\"]],[20,[\"unitName\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"submit\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"createPdf\"],null],[20,[\"getTitle\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"sort\"],null],[20,[\"sortDirection\"]],[20,[\"sortField\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/shift-summary-report/index.hbs"
    }
  });

  _exports.default = _default;
});
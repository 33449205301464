define("careshare/routes/after-login/notifications/index", ["exports", "ember-cli-pagination/remote/route-mixin", "careshare/mixins/messages-and-notifications"], function (_exports, _routeMixin, _messagesAndNotifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_routeMixin.default, _messagesAndNotifications.default), {
    session: Ember.inject.service('session'),
    page: 1,
    perPage: 20,
    beforeModel: function beforeModel() {
      this.initNotificationsListUpdate();
    },
    model: function model(params) {
      params.expand = 'resident,creator';
      return this.findPaged('notification', params);
    },
    actions: {
      setRead: function setRead(notification) {
        notification.setRead();
        this.decreaseNotificationsCounter();
      },
      setCompleted: function setCompleted(notification) {
        notification.setCompleted();
      },
      openMessageModal: function openMessageModal(recipientId, residentId) {
        var afterLoginController = this.controllerFor('after-login');
        afterLoginController.set('newMessageModal', true);
        afterLoginController.set('recipientId', recipientId);
        afterLoginController.set('residentId', residentId);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/details-header", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/set-belongs-to"], function (_exports, _environment, _serializeData, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_serializeData.default, _setBelongsTo.default), {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    actions: {
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      }
    }
  });

  _exports.default = _default;
});
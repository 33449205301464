define("careshare/routes/after-login/contacts/index", ["exports", "moment", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin"], function (_exports, _moment, _environment, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    dialog: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      source: {
        refreshModel: true
      },
      resident_name: {
        refreshModel: false
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      localStorage.setItem('previousOutcomeTransition', 'index');
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('contact', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.contacts.index').get('sortBy');
        var direction = self.controllerFor('after-login.contacts.index').get('direction') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.table-responsive').find('a').removeClass('top');
        Ember.$('.table-responsive').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.table-responsive').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    actions: {
      addNew: function addNew() {
        this.transitionTo('after-login.contacts.new');
      },
      delete: function _delete(id) {
        var _this = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        promise.then(function () {
          Ember.run.later('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });

          _this.get('store').findRecord('contact', id, {
            backgroundReload: false
          }).then(function (contact) {
            contact.destroyRecord().then(function () {
              Ember.run.later('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
            });
          });
        });
      },
      print: function print() {
        var resident_id = this.controllerFor('after-login.contacts.index').get('resident_id');
        var resident = this.controllerFor('after-login.contacts.index').get('resident_name');
        var type = this.controllerFor('after-login.contacts.index').get('type');
        var dateFromFormatted = this.controllerFor('after-login.contacts.index').get('date_from');
        var dateToFormatted = this.controllerFor('after-login.contacts.index').get('date_to');
        $('.forPrint').printThis({
          importCSS: true,
          loadCSS: "",
          header: "<h3>" + (type && type !== 'all' ? type + ' ' : '') + "Contact List " + (resident_id && resident_id !== 'all' ? ' for ' + resident : '') + ' ' + dateFromFormatted + '-' + dateToFormatted + "</h3>"
        });
      }
    }
  });

  _exports.default = _default;
});
define("careshare/serializers/shift-summary", ["exports", "careshare/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    attrs: {
      unit: {
        embedded: "always"
      },
      shift: {
        embedded: "always"
      },
      resident: {
        embedded: "always"
      }
    },
    normalize: function normalize(type, hash) {
      var json = this._super(type, hash);

      json.data.id = typeof hash.id === 'undefined' ? Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2) : hash.id;
      return json;
    },
    extractMeta: function extractMeta(store, type, payload) {
      if (payload && payload.hasOwnProperty('_meta')) {
        return payload._meta;
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/abbey-pain-scale-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.onClose();
      }
    },
    painScaleItems: Ember.computed('painScale', function () {
      return [{
        label: 'Vocalisation',
        value: this.get('painScale.vocalisation')
      }, {
        label: 'Facial Expression',
        value: this.get('painScale.facial_expression')
      }, {
        label: 'Body Language',
        value: this.get('painScale.body_language')
      }, {
        label: 'Behavioural Change',
        value: this.get('painScale.behavioural_change')
      }, {
        label: 'Physiological Change',
        value: this.get('painScale.physiological_change')
      }, {
        label: 'Physical Changes',
        value: this.get('painScale.physical_changes')
      }];
    }),
    totalPainLevel: Ember.computed('painScale.total_score', function () {
      var score = this.get('painScale.total_score');
      return this.getPainLevel(score);
    }),
    getPainLevel: function getPainLevel(score) {
      if (score <= 2) return 0;
      if (score <= 7) return 1;
      if (score <= 13) return 2;
      return 3;
    }
  });

  _exports.default = _default;
});
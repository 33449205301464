define("careshare/templates/after-login/contacts/resident-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bchM4b5N",
    "block": "{\"symbols\":[],\"statements\":[[4,\"resident-contact-edit\",null,[[\"contacts\",\"additionalContacts\",\"contactTypeList\",\"sourceList\",\"saveData\",\"addRecord\",\"removeRecord\",\"cancel\",\"print\",\"resident\"],[[20,[\"contacts\"]],[20,[\"additionalContacts\"]],[20,[\"contactTypeList\"]],[20,[\"sourceListAll\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveData\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"addRecord\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"removeRecord\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"cancel\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"print\"],null],[20,[\"resident\"]]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/contacts/resident-edit.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/skin/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    model: function model() {
      return this.store.createRecord('skin');
    },
    afterModel: function afterModel(model, transition) {
      var type = model.get('type');
      this.createEmptyAction(model);

      if (transition.queryParams.resident_id) {
        model.set('resident_id', transition.queryParams.resident_id);
      }

      Ember.run.schedule('afterRender', function () {
        if (!Ember.isEmpty(type) && type !== 'select type') {
          Ember.$('#grade').prop("disabled", false);
        }
      });
    },
    createEmptyAction: function createEmptyAction(model) {
      var today = new Date(),
          action = this.store.createRecord('skin-action'),
          h = today.getHours(),
          m = today.getMinutes(),
          date = (0, _moment.default)().format("DD.MM.YYYY");
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      action.set('time', h + ":" + m);
      action.set('date', date);
      model.get('skinActions').unshiftObject(action);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.typeChanged(null, null);
    },
    actions: {
      saveData: function saveData(resident) {
        var _this = this;

        var someActionNotSet = false;
        var model = this.modelFor(this.routeName);
        var controller = this.controllerFor('after-login.skin.edit');
        model.get('skinActions').forEach(function (action) {
          if (!action.get('action')) {
            someActionNotSet = true;
          }
        });

        if (!model.get('resident_id')) {
          this.controllerFor('after-login').set('dialogMessage', 'Resident is required!');
          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          if (someActionNotSet) {
            this.controllerFor('after-login').set('dialogMessage', 'Some action has not been selected!');
            Ember.$('.modal-window.error').removeClass('hidden');
          } else {
            model.set('user_id', this.get('session.data.authenticated.user_id'));
            document.getElementById("location_x").focus();
            document.getElementById("location_y").focus();
            document.getElementById("location_img_x").focus();
            document.getElementById("location_img_y").focus();
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').removeClass('hidden');
            });
            model.save().then(function (returnItem) {
              Ember.run.schedule('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
              controller.set('newAdded', true);

              _this.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');

              Ember.$('.modal-window.success').removeClass('hidden');

              _this.transitionTo('after-login.skin.edit', model.id);
            }).catch(function () {
              Ember.run.schedule('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
            });
          }
        }
      },
      back: function back(resident) {
        var model = this.modelFor(this.routeName);
        model.set('skinActions', []);
        this.transitionTo('after-login.skin', {
          queryParams: {
            resident_id: resident
          }
        });
      },
      removeAction: function removeAction(model, action) {
        model.get('skinActions').removeObject(action);
        this.get('store').deleteRecord(action);
      }
    }
  });

  _exports.default = _default;
});
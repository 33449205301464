define("careshare/routes/after-login/roles/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        roles: this.store.query('role', {
          filter: {
            isNew: false
          }
        }),
        permissions: this.store.findAll('permission')
      });
    }
  });

  _exports.default = _default;
});
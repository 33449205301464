define("careshare/templates/after-login/documents/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kGljMm8o",
    "block": "{\"symbols\":[],\"statements\":[[4,\"document-edit\",null,[[\"warning\",\"cancel\",\"model\",\"previousType\",\"previousCategory\",\"previousResident\"],[[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"warning\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"cancel\"],null],[20,[\"model\"]],[20,[\"previousType\"]],[20,[\"previousCategory\"]],[20,[\"previousResident\"]]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/documents/new.hbs"
    }
  });

  _exports.default = _default;
});
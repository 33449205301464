define("careshare/controllers/after-login/logs/resident-index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var past = (0, _moment.default)().subtract(90, 'd').format('DD.MM.YYYY');
  var today = (0, _moment.default)().format('DD.MM.YYYY');

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["page", "perPage", "resident_id", "resident_name", "date_from", "date_to"],
    today: today,
    resident_id: '',
    default_note_type: "all",
    date_from: past,
    date_to: today,
    resident_name: '',
    dateFromParam: Ember.computed('date_from', {
      get: function get(k) {
        var v = this.get('date_from');
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_from', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    dateToParam: Ember.computed('date_to', {
      get: function get(k) {
        var v = this.get('date_to');
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date_to', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    isPaginationNeeded: Ember.computed('content.meta.pageCount', function () {
      return this.get('content.meta.pageCount') > 1;
    })
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/consent/index", ["exports", "moment", "ember-cli-pagination/remote/route-mixin", "careshare/config/environment"], function (_exports, _moment, _routeMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      event_type: {
        refreshModel: true
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('consent', params);
    },
    afterModel: function afterModel() {
      var self = this;
      Ember.run.next(function () {
        var sortBy = self.controllerFor('after-login.consent.index').get('sortBy');
        var direction = self.controllerFor('after-login.consent.index').get('direction') === 'ASC' ? 'top' : 'bottom';
        Ember.$('.inc-table2').find('a').removeClass('top');
        Ember.$('.inc-table2').find('a').removeClass('bottom');

        if (direction === 'top') {
          Ember.$('.inc-table2').find('#' + sortBy + '_sort').addClass('top');
          Ember.$('.inc-table2').find('#' + sortBy + '_sort').removeClass('bottom');
        } else {
          Ember.$('.inc-table2').find('#' + sortBy + '_sort').removeClass('top');
          Ember.$('.inc-table2').find('#' + sortBy + '_sort').addClass('bottom');
        }

        Ember.$('.loader-wrapper').addClass('hidden');
        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    },
    actions: {
      addConsent: function addConsent() {
        var resident = this.controllerFor('after-login.consent.index').get('resident_id');
        this.transitionTo('after-login.consent.new', {
          queryParams: {
            resident_id: resident
          }
        });
      },
      editConsent: function editConsent(id) {
        this.transitionTo('after-login.consent.edit', id);
      },
      print: function print() {
        var resident = this.controllerFor('after-login.consent.index').get('resident_name');
        var dateFromFormatted = this.controllerFor('after-login.consent.index').get('date_from');
        var dateToFormatted = this.controllerFor('after-login.consent.index').get('date_to');
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/residents/admission-details", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/set-belongs-to", "careshare/mixins/admission-validate"], function (_exports, _environment, _serializeData, _setBelongsTo, _admissionValidate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(Ember.Mixin.create(_serializeData.default, _admissionValidate.default, _setBelongsTo.default), {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.store.findRecord('residentlite', params.id, {
        backgroundReload: true
      });
    },
    afterModel: function afterModel(model) {
      var store = this.store;
      var self = this;
      store.findAll('user');
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        model.set('weights', data[0]['weights']);
        model.set('weights_dates', data[0]['dates']);
        model.set('moods', data[1]['moods']);
        model.set('moods_dates', data[1]['dates']);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (Ember.get(transition, 'targetName') == 'after-login.residents.index') {
          this.get('store').peekAll('resident').forEach(function (entry) {
            return entry.unloadRecord();
          });
        }
      },
      goTo: function goTo(transition, modelSaved, view) {
        var self = this;
        var resident = this.modelFor(this.routeName);

        if (view == 1) {
          self.transitionTo(transition, resident.get('id'), {
            queryParams: {
              view: '1'
            }
          });
        } else {
          self.transitionTo(transition, resident.get('id'));
        }
      },
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      }
    }
  });

  _exports.default = _default;
});
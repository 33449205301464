define("careshare/controllers/after-login/residents/new", ["exports", "careshare/mixins/set-belongs-to"], function (_exports, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({});

  _exports.default = _default;
});
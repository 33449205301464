define("careshare/routes/after-login/activities/resident-new", ["exports", "moment", "careshare/config/environment", "ember-cli-pagination/remote/route-mixin", "careshare/mixins/graph"], function (_exports, _moment, _environment, _routeMixin, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model() {
      return this.store.createRecord('activity');
    },
    afterModel: function afterModel(model, transition) {
      this.createResident(model, true, transition.queryParams.resident_id);
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.activities.resident-new').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie, .mood-label').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    createResident: function createResident(model) {
      var first = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var residentId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var resident = this.store.createRecord('activity-resident');

      if (first) {
        resident.set('resident_id', residentId);
      }

      resident.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      model.get('activityResidents').pushObject(resident);
    },
    actions: {
      addResident: function addResident(model) {
        this.createResident(model);
        Ember.$('.scroll-cell').animate({
          scrollTop: $('.scroll-cell').prop('scrollHeight')
        }, 1000);
        Ember.run.later('afterRender', function () {
          Ember.$('.mood-label').find('i').removeClass('fa').addClass('fas');
        });
      },
      saveActivity: function saveActivity(resident) {
        var self = this;
        var activity = this.modelFor(this.routeName);
        var formatedRequestData = JSON.stringify(activity.serialize());
        var requestOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/activities',
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestData
        };
        var saveDetails = Ember.$.ajax(requestOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(saveDetails).done(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.activities.resident-index', resident, {
            queryParams: {
              resident_id: resident
            }
          });
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back(resident) {
        this.transitionTo('after-login.activities.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      }
    }
  });

  _exports.default = _default;
});
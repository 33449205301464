define("careshare/helpers/variable", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    variables: {},
    compute: function compute(_ref) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 3),
          mode = _ref2[0],
          name = _ref2[1],
          _ref2$ = _ref2[2],
          value = _ref2$ === void 0 ? '' : _ref2$;

      var variables = this.get('variables');

      if (mode === 'set') {
        variables[name] = value;
      } else if (mode === 'get') {
        return variables[name];
      } else if (mode === 'eq') {
        return variables[name] === value;
      } else if (mode === 'ne') {
        return variables[name] !== value;
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/message-recipient-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "edHYqoT1",
    "block": "{\"symbols\":[\"recipient\"],\"statements\":[[6,\"select\"],[9,\"class\",\"form-control chosen recipient-select\"],[7],[0,\"\\n\\t\"],[6,\"option\"],[9,\"value\",\"\"],[7],[0,\"Select Recipient\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"recipientList\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"user_id\"]]]]],[7],[0,\"\\n\\t\\t\\t\"],[1,[19,1,[\"full_name\"]],false],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/message-recipient-select.hbs"
    }
  });

  _exports.default = _default;
});
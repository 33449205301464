define("careshare/routes/after-login/admin/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    perPage: 10,
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.findPaged('user', params);
    },
    afterModel: function afterModel() {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    actions: {
      lock: function lock(id) {
        this.controllerFor('after-login').set('dialogMessage', 'Action temporarily forbidden!');
        Ember.$('.modal-window.error').removeClass('hidden');
      },
      addUser: function addUser() {
        this.transitionTo('after-login.users.new');
      },
      editUser: function editUser(id) {
        this.transitionTo('after-login.users.edit', id);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/continence-details", ["exports", "careshare/mixins/checkboxes-behavior", "underscore"], function (_exports, _checkboxesBehavior, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend(_checkboxesBehavior.default, {
    store: Ember.inject.service(),
    tagName: 'div',
    idsErrors: [],
    night_times_items: [ListItem.create({
      item_description: "Independent",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Requires Assistance",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "None",
      item_type: "predefined"
    })],
    continence_urine_daytime_aids_items: [ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pads",
      item_type: "predefined"
    })],
    continence_urine_nightime_aids_items: [ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pads",
      item_type: "predefined"
    })],
    continence_faeces_daytime_aids_items: [ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pads",
      item_type: "predefined"
    })],
    continence_faeces_nightime_aids_items: [ListItem.create({
      item_description: "Commode",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Bottle",
      item_type: "predefined"
    }), ListItem.create({
      item_description: "Pads",
      item_type: "predefined"
    })],
    init: function init() {
      this.initCheckboxesList('night_times');
      this.initCheckboxesList('continence_urine_daytime_aids');
      this.initCheckboxesList('continence_urine_nightime_aids');
      this.initCheckboxesList('continence_faeces_daytime_aids');
      this.initCheckboxesList('continence_faeces_nightime_aids');
      this.set('model', this.get('model'));
      Ember.run.schedule("afterRender", this, function () {
        if (this.get('model.submitted')) {//Ember.$(".form-inner-column :input").prop("disabled", true);
        }
      });

      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      if (this.get('model.continence_details').get('urine_continent_daytime_required')) {
        Ember.$('#continence-urine-daytime-aids-group-options').show();
      } else {
        Ember.$('#continence-urine-daytime-aids-group-options').hide();
      }

      if (this.get('model.continence_details').get('urine_continent_nightime_required')) {
        Ember.$('#continence-urine-nightime-aids-group-options').show();
      } else {
        Ember.$('#continence-urine-nightime-aids-group-options').hide();
      }

      if (this.get('model.continence_details').get('faeces_continent_daytime_required')) {
        Ember.$('#continence-faeces-daytime-aids-group-options').show();
      } else {
        Ember.$('#continence-faeces-daytime-aids-group-options').hide();
      }

      if (this.get('model.continence_details').get('faeces_continent_nightime_required')) {
        Ember.$('#continence-faeces-nightime-aids-group-options').show();
      } else {
        Ember.$('#continence-faeces-nightime-aids-group-options').hide();
      }

      Ember.run.scheduleOnce('afterRender', this, function () {
        window.scrollTo(0, $('#continence-details').offset().top - 58);
      });
    },
    modelWasChanged: Ember.observer('urineContinentDaytimeRequired', 'urineContinentNightimeRequired', 'faecesContinentDaytimeRequired', 'faecesContinentNightimeRequired', 'continenceDetailsWasChanged', 'nightimesWasChanged', 'continenceUrineDaytimeAidsWasChanged', 'continenceUrineNightimeAidsWasChanged', 'continenceFaecesDaytimeAidsWasChanged', 'continenceFaecesNightimeAidsWasChanged', function () {
      if (this.get('continenceDetailsWasChanged') || this.get('nightimesWasChanged') || this.get('continenceUrineDaytimeAidsWasChanged') || this.get('continenceUrineNightimeAidsWasChanged') || this.get('continenceFaecesDaytimeAidsWasChanged') || this.get('continenceFaecesNightimeAidsWasChanged')) {
        Ember.$("input.save[type=button]").removeClass('disabled');
        this.set('modelChanged', false);
      } else {
        Ember.$("input.save[type=button]").addClass('disabled');
        this.set('modelChanged', true);
      }

      if (this.get('urineContinentDaytimeRequired')) {
        Ember.$('#continence-urine-daytime-aids-group-options').show();
      } else {
        Ember.$('#continence-urine-daytime-aids-group-options').hide();
      }

      if (this.get('urineContinentNightimeRequired')) {
        Ember.$('#continence-urine-nightime-aids-group-options').show();
      } else {
        Ember.$('#continence-urine-nightime-aids-group-options').hide();
      }

      if (this.get('faecesContinentDaytimeRequired')) {
        Ember.$('#continence-faeces-daytime-aids-group-options').show();
      } else {
        Ember.$('#continence-faeces-daytime-aids-group-options').hide();
      }

      if (this.get('faecesContinentNightimeRequired')) {
        Ember.$('#continence-faeces-nightime-aids-group-options').show();
      } else {
        Ember.$('#continence-faeces-nightime-aids-group-options').hide();
      }
    }).on('init'),
    urineContinentDaytimeRequired: Ember.computed('model.continence_details.urine_continent_daytime_required', function () {
      return this.get('model.continence_details.urine_continent_daytime_required');
    }),
    urineContinentNightimeRequired: Ember.computed('model.continence_details.urine_continent_nightime_required', function () {
      return this.get('model.continence_details.urine_continent_nightime_required');
    }),
    faecesContinentDaytimeRequired: Ember.computed('model.continence_details.faeces_continent_daytime_required', function () {
      return this.get('model.continence_details.faeces_continent_daytime_required');
    }),
    faecesContinentNightimeRequired: Ember.computed('model.continence_details.faeces_continent_nightime_required', function () {
      return this.get('model.continence_details.faeces_continent_nightime_required');
    }),
    continenceDetailsWasChanged: Ember.computed('model.continence_details.hasDirtyAttributes', function () {
      return this.get('model.continence_details.hasDirtyAttributes');
    }),
    nightimesWasChanged: Ember.computed('model.night_times.[]', 'original_night_times', function () {
      return !_underscore.default.isEqual(this.get('model').get('night_times').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_night_times').sort());
    }),
    continenceUrineDaytimeAidsWasChanged: Ember.computed('model.continence_urine_daytime_aids.[]', 'original_continence_urine_daytime_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('continence_urine_daytime_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_continence_urine_daytime_aids').sort());
    }),
    continenceUrineNightimeAidsWasChanged: Ember.computed('model.continence_urine_nightime_aids.[]', 'original_continence_urine_nightime_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('continence_urine_nightime_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_continence_urine_nightime_aids').sort());
    }),
    continenceFaecesDaytimeAidsWasChanged: Ember.computed('model.continence_faeces_daytime_aids.[]', 'original_continence_faeces_daytime_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('continence_faeces_daytime_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_continence_faeces_daytime_aids').sort());
    }),
    continenceFaecesNightimeAidsWasChanged: Ember.computed('model.continence_faeces_nightime_aids.[]', 'original_continence_faeces_nightime_aids', function () {
      return !_underscore.default.isEqual(this.get('model').get('continence_faeces_nightime_aids').map(function (item) {
        return "".concat(item.get('item_description'));
      }).sort(), this.get('original_continence_faeces_nightime_aids').sort());
    }),
    actions: {
      selectValue: function selectValue(value) {
        this.get('model.personal_care').set(Ember.$(event.target).attr('model-attr'), value);
      },
      selectValue2: function selectValue2(value) {
        this.get('model.continence_details').set(Ember.$(event.target).attr('model-attr'), value);
      },
      updateCheckboxList: function updateCheckboxList(modelFor, newSelection, value, operation) {
        this.updateCheckboxList(modelFor, newSelection, value, operation);
      },
      addCheckboxItem: function addCheckboxItem(modelFor) {
        this.addCheckboxItem(modelFor);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/skin/edit", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    existingActionsAmount: 0,
    newAdded: false,
    dialog: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('skin', params.id, {
        reload: true
      });
    },
    beforeModel: function beforeModel() {
      Ember.run.later('afterRender', function () {
        $(".ui-tooltip").addClass('hidden');
      });
    },
    afterModel: function afterModel() {
      var model = this.modelFor(this.routeName),
          type = model.get('type'),
          controller = this.controllerFor('after-login.skin.edit');
      this.createEmptyAction(model, false);

      if (controller.get('newAdded')) {
        controller.set('newAdded', false);
      }

      this.set('existingActionsAmount', model.get('skinActions').length);
      Ember.run.schedule('afterRender', function () {
        Ember.$(window).scrollTop(Ember.$('.skin-integrity-item').offset().top);
        Ember.$('.ui-tooltip').addClass('hidden');
        Ember.$('.loader-wrapper').addClass('hidden');

        if (!Ember.isEmpty(type) && type !== 'select type') {
          Ember.$('#grade').prop("disabled", false);
        }
      });
    },
    createEmptyAction: function createEmptyAction(model) {
      var healed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var today = new Date(),
          action = this.store.createRecord('skin-action'),
          h = today.getHours(),
          m = today.getMinutes(),
          date = (0, _moment.default)().format("DD.MM.YYYY");
      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;
      action.set('time', h + ":" + m);
      action.set('date', date);

      if (healed) {
        action.set('action', 'Other');
      }

      model.get('skinActions').unshiftObject(action);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.typeChanged(null, null);
    },
    doSave: function doSave(skinActions, setAsHealed, model, gradeChanged, controller) {
      var _this = this;

      skinActions.forEach(function (action) {
        if (action && !action.get('action') && !setAsHealed) {
          skinActions.removeObject('action');

          _this.get('store').deleteRecord(action);
        }
      });

      if (model.get('type') === 'Other' && !model.get('type_other').length) {
        this.controllerFor('after-login').set('dialogMessage', 'If Other is selected, then the other type needs to be filled in!');
        Ember.$('.modal-window.error').removeClass('hidden');
      } else {
        if (gradeChanged && !skinActions.get('firstObject').get('notes') && !setAsHealed) {
          this.controllerFor('after-login').set('dialogMessage', 'Notes are needed when changing the grade!');
          Ember.$('.modal-window.error').removeClass('hidden');
        } else {
          model.set('user_id', this.get('session.data.authenticated.user_id'));
          document.getElementById("location_x").focus();
          document.getElementById("location_y").focus();
          document.getElementById("location_img_x").focus();
          document.getElementById("location_img_y").focus();
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          model.save().then(function (returnItem) {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });

            _this.controllerFor('after-login').set('dialogMessage', setAsHealed ? 'Successfully saved and moved under the healed section!' : 'Successfully saved!');

            if (setAsHealed) {
              controller.set('isHealed', false);
            }

            Ember.$('.modal-window.success').removeClass('hidden');

            if (setAsHealed || gradeChanged) {
              _this.transitionTo('after-login.skin');
            } else {
              _this.createEmptyAction(model, false);

              _this.transitionTo('after-login.skin.edit', model.id);

              Ember.$(window).scrollTop(Ember.$('.skin-integrity-item').offset().top);
            }
          }).catch(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      }
    },
    actions: {
      saveData: function saveData() {
        var _this2 = this;

        var self = this;
        var model = this.modelFor(this.routeName);
        var controller = self.controllerFor('after-login.skin.edit');
        var changedAttributes = model.changedAttributes();
        var skinActions = model.get('skinActions');
        var typeChanged = false;
        var gradeChanged = false;
        var setAsHealed = false;

        if (Object.keys(changedAttributes).indexOf('grade') !== -1) {
          gradeChanged = true;
        }

        if (Object.keys(changedAttributes).indexOf('type') !== -1) {
          typeChanged = true;
        }

        if (controller.get('isHealed')) {
          var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
            "id": "oi/qGg3g",
            "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure you want to move this item to the Healed table, no further editing will be possible?\"]],\"hasEval\":false}",
            "meta": {}
          }));
          promise.then(function () {
            skinActions.forEach(function (action) {
              if (action && !action.get('action')) {
                skinActions.removeObject(action);

                _this2.get('store').deleteRecord(action);
              }
            });
            self.createEmptyAction(model, true);
            model.set('grade', 'Healed');
            self.doSave(skinActions, true, model, true, controller);
          }, function () {
            controller.set('isHealed', false);
          });
        } else {
          self.doSave(skinActions, false, model, gradeChanged, controller);
        }
      },
      back: function back(resident) {
        var changedAttributes = this.modelFor(this.routeName).changedAttributes(),
            model = this.modelFor(this.routeName),
            controller = this.controllerFor('after-login.skin.edit');
        Object.keys(changedAttributes).forEach(function (key) {
          model.set(key, changedAttributes[key][0]);
        });

        if (controller.get('isHealed')) {
          controller.set('isHealed', false);
        }

        model.set('skinActions', []);
        this.transitionTo('after-login.skin', {
          queryParams: {
            resident_id: resident
          }
        });
      },
      removeAction: function removeAction(model, action) {
        model.get('skinActions').removeObject(action);
        this.get('store').deleteRecord(action);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/services/resident-tabs-states", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.isPresent(localStorage.getItem('key_details_valid')) ? this.set('key_details_valid', parseInt(localStorage.getItem('key_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('additional_details_valid')) ? this.set('additional_details_valid', parseInt(localStorage.getItem('additional_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('contact_details_valid')) ? this.set('contact_details_valid', parseInt(localStorage.getItem('contact_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('contact_family_details_valid')) ? this.set('contact_family_details_valid', parseInt(localStorage.getItem('contact_family_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('communication_details_valid')) ? this.set('communication_details_valid', parseInt(localStorage.getItem('communication_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('eol_details_valid')) ? this.set('eol_details_valid', parseInt(localStorage.getItem('eol_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('medical_details_valid')) ? this.set('medical_details_valid', parseInt(localStorage.getItem('medical_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('communication_eol_medical_details_valid')) ? this.set('communication_eol_medical_details_valid', parseInt(localStorage.getItem('communication_eol_medical_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('mobility_details_valid')) ? this.set('mobility_details_valid', parseInt(localStorage.getItem('mobility_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('nutrition_details_valid')) ? this.set('nutrition_details_valid', parseInt(localStorage.getItem('nutrition_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('reminiscence_details_valid')) ? this.set('reminiscence_details_valid', parseInt(localStorage.getItem('reminiscence_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('mobility_nutrition_details_valid')) ? this.set('mobility_nutrition_details_valid', parseInt(localStorage.getItem('mobility_nutrition_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('sleeping_details_valid')) ? this.set('sleeping_details_valid', parseInt(localStorage.getItem('sleeping_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('percare_details_valid')) ? this.set('percare_details_valid', parseInt(localStorage.getItem('percare_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('preferences_details_valid')) ? this.set('preferences_details_valid', parseInt(localStorage.getItem('preferences_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('continence_details_valid')) ? this.set('continence_details_valid', parseInt(localStorage.getItem('continence_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('sleeping_percare_preferences_continence_details_valid')) ? this.set('sleeping_percare_preferences_continence_details_valid', parseInt(localStorage.getItem('sleeping_percare_preferences_continence_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('skin_details_valid')) ? this.set('skin_details_valid', parseInt(localStorage.getItem('skin_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('allergies_details_valid')) ? this.set('allergies_details_valid', parseInt(localStorage.getItem('allergies_details_valid'))) : 0;
      Ember.isPresent(localStorage.getItem('skin_allergies_discharge_details_valid')) ? this.set('skin_allergies_discharge_details_valid', parseInt(localStorage.getItem('skin_allergies_discharge_details_valid'))) : 0;
    },
    saveValidations: function saveValidations(resident) {
      var self = this;
      var formatedData = {
        key_details_valid: this.get('key_details_valid'),
        additional_details_valid: this.get('additional_details_valid'),
        contact_details_valid: this.get('contact_details_valid'),
        contact_family_details_valid: this.get('contact_family_details_valid'),
        communication_details_valid: this.get('communication_details_valid'),
        eol_details_valid: this.get('eol_details_valid'),
        medical_details_valid: this.get('medical_details_valid'),
        communication_eol_medical_details_valid: this.get('communication_eol_medical_details_valid'),
        mobility_details_valid: this.get('mobility_details_valid'),
        nutrition_details_valid: this.get('nutrition_details_valid'),
        reminiscence_details_valid: this.get('reminiscence_details_valid'),
        mobility_nutrition_details_valid: this.get('mobility_nutrition_details_valid'),
        sleeping_details_valid: this.get('sleeping_details_valid'),
        percare_details_valid: this.get('percare_details_valid'),
        preferences_details_valid: this.get('preferences_details_valid'),
        continence_details_valid: this.get('continence_details_valid'),
        sleeping_percare_preferences_continence_details_valid: this.get('sleeping_percare_preferences_continence_details_valid'),
        skin_valid: this.get('skin_valid'),
        allergies_valid: this.get('allergies_valid'),
        skin_allergies_discharge_details_valid: this.get('skin_allergies_discharge_details_valid')
      };
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/residents/validations?resident_id=' + resident.get('id'),
        dataType: 'JSON',
        method: 'POST',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        },
        data: JSON.stringify(formatedData)
      };
      return Ember.$.ajax(requestOptions);
    }
  });

  _exports.default = _default;
});
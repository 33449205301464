define("careshare/templates/components/role-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RKf+9z+7",
    "block": "{\"symbols\":[\"role\"],\"statements\":[[6,\"select\"],[9,\"class\",\"form-control chosen roles-select\"],[3,\"action\",[[19,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"roles\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"name\"]]]]],[7],[1,[19,1,[\"description\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/role-select.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/templates/components/date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6j4XaEWv",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"input\",null,[[\"name\",\"class\"],[[20,[\"name\"]],\"form-control date-picker input-group\"]]],false],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"input-group-addon2\"],[7],[0,\"\\n\\t\"],[6,\"i\"],[9,\"class\",\"fa fa-calendar-alt\"],[9,\"aria-hidden\",\"true\"],[7],[8],[0,\"\\n\"],[8],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/date-picker.hbs"
    }
  });

  _exports.default = _default;
});
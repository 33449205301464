define("careshare/controllers/after-login/shift-summary-report/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var today = (0, _moment.default)().format('DD.MM.YYYY');

  var _default = Ember.Controller.extend({
    queryParams: ["date", "unit", "shift", "resident_id", "residentName"],
    today: today,
    date: today,
    unit: '',
    shift: '',
    resident_id: '',
    residentName: '',
    sortFields: Ember.computed('sortDirection', 'sortField', function () {
      return [this.get('sortField') + ':' + this.get('sortDirection')];
    }),
    sortDirection: Ember.computed(function () {
      return 'asc';
    }),
    sortField: Ember.computed(function () {
      return 'resident.firstname';
    }),
    dateParam: Ember.computed('date', {
      get: function get(k) {
        var v = this.get('date');
        this.set('date', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      },
      set: function set(k, v) {
        this.set('date', (0, _moment.default)(v).format('DD.MM.YYYY'));
        return v;
      }
    }),
    getTitle: Ember.computed('content.meta.shift_title', function () {
      return this.get('content.meta.shift_title');
    }),
    sortedShiftElements: Ember.computed.sort('content', 'sortFields'),
    actions: {
      updateDate: function updateDate(date) {
        this.set('dateParam', date);
      },
      sort: function sort(field) {
        var sortDirection = this.get('sortDirection') === 'asc' ? 'desc' : 'asc';
        this.set('sortDirection', sortDirection);
        this.set('sortField', field);
      }
    }
  });

  _exports.default = _default;
});
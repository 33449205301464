define("careshare/templates/components/professional-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4sdGDCzc",
    "block": "{\"symbols\":[\"professional\"],\"statements\":[[6,\"select\"],[10,\"id\",[26,[[18,\"id\"]]]],[9,\"class\",\"chosen form-control\"],[10,\"onchange\",[25,\"action\",[[19,0,[]],[25,\"mut\",[[20,[\"modelValue\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[7],[0,\"\\n    \"],[6,\"option\"],[9,\"value\",\"\"],[7],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"professionalList\"]]],null,{\"statements\":[[0,\"        \"],[6,\"option\"],[10,\"value\",[26,[[19,1,[\"name\"]]]]],[7],[1,[19,1,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/professional-select.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/adapters/user", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      return this.buildURL(modelName, id, snapshot) + '?expand=role';
    }
  });

  _exports.default = _default;
});
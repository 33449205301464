define("careshare/routes/after-login/residents/removed", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    perPage: 20,
    queryParams: {
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.findPaged('resident', params);
    },
    afterModel: function afterModel(model) {
      Ember.run.later('afterRender', function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    actions: {
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      }
    }
  });

  _exports.default = _default;
});
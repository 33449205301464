define("careshare/routes/after-login/skin/resident-healed", ["exports", "moment", "ember-cli-pagination/remote/route-mixin", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _moment, _routeMixin, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, _graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 10,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      note_type: {
        refreshModel: true
      },
      resident_name: {
        refreshModel: false
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.expand = 'skinActions,skinGradesDetails';
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('skin', params);
    },
    afterModel: function afterModel(model, transition) {
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.skin.resident-healed').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    generatePdf: function generatePdf(resident, noteType, dateFrom, dateTo) {
      var self = this;
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/skins/pdf?healed=1&resident_id=' + resident + '&note_type=' + noteType + '&date_from=' + dateFrom + '&date_to=' + dateTo + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      index: function index() {
        var resident = this.controllerFor('after-login.skin.resident-healed').get('resident_id');
        this.transitionTo('after-login.skin.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      createPdf: function createPdf() {
        var self = this;
        var resident = this.controllerFor('after-login.skin.healed').get('resident_id');
        var noteType = this.controllerFor('after-login.skin.healed').get('note_type') ? this.controllerFor('after-login.skin.healed').get('note_type') : this.controllerFor('after-login.skin.healed').get('default_note_type');
        var dateFrom = this.controllerFor('after-login.skin.healed').get('date_from');
        var dateTo = this.controllerFor('after-login.skin.healed').get('date_to');
        dateFrom = new Date(dateFrom.split(".").reverse().join("-")).getTime() / 1000;
        dateTo = new Date(dateTo.split(".").reverse().join("-")).getTime() / 1000;

        if (Ember.isEmpty(noteType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(noteType)) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(self.generatePdf(resident, noteType, dateFrom, dateTo)).done(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Document successfully saved to documents!');
            Ember.$('.modal-window.success').removeClass('hidden');
          });
        }
      },
      print: function print() {
        var self = this;
        var resident = this.controllerFor('after-login.skin.healed').get('resident_name');
        var resident_id = this.controllerFor('after-login.skin.healed').get('resident_id');
        var noteType = this.controllerFor('after-login.skin.healed').get('note_type') ? this.controllerFor('after-login.skin.healed').get('note_type') : this.controllerFor('after-login.skin.healed').get('default_note_type');
        var date_from = this.controllerFor('after-login.skin.healed').get('date_from');
        var date_to = this.controllerFor('after-login.skin.healed').get('date_to');
        var dateFromReq = new Date(date_from.split(".").reverse().join("-")).getTime() / 1000;
        var dateToReq = new Date(date_to.split(".").reverse().join("-")).getTime() / 1000;

        if (Ember.isEmpty(noteType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(noteType)) {
          return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/skins/print?resident_id=' + resident_id + '&healed=1&date_from=' + dateFromReq + '&date_to=' + dateToReq, {
            method: 'GET',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            }
          })]).then(function (data) {
            self.controllerFor('after-login.skin.healed').set('skinsForPrint', data[0].skins);
            $('.forPrint').printThis({
              importCSS: true,
              loadCSS: "",
              header: "<h1>" + 'skins ' + (resident && resident !== '' ? ' for ' + resident : '') + " " + date_from + " - " + date_to + "</h1>"
            });
            Ember.run.next(function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});
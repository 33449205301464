define("careshare/mixins/set-belongs-to", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      setBelongsTo: function setBelongsTo(model, property, event) {
        var controller = this.get('controller');

        if (event.target.value) {
          controller.get(model).set(property, this.store.peekRecord(property, event.target.value));
          controller.get(model).set(property + '_id', event.target.value);
        } else {
          controller.get(model).set(property, null);
          controller.get(model).set(property + '_id', event.target.value);
        }
      }
    }
  });

  _exports.default = _default;
});
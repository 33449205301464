define("careshare/templates/components/alert-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ScbeTBn1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,\"div\"],[9,\"class\",\"alert-popup\"],[7],[0,\"\\n    \"],[11,1],[0,\"\\n    \"],[6,\"input\"],[9,\"class\",\"btn-ok\"],[9,\"value\",\"Ok\"],[9,\"type\",\"button\"],[3,\"action\",[[19,0,[]],\"close\"]],[7],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/alert-window.hbs"
    }
  });

  _exports.default = _default;
});
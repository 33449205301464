define("careshare/templates/after-login/residents/discharge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l10U3/Ha",
    "block": "{\"symbols\":[],\"statements\":[[4,\"resident-discharge\",null,[[\"model\",\"saveDischarge\"],[[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveDischarge\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/discharge.hbs"
    }
  });

  _exports.default = _default;
});
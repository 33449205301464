define("careshare/templates/after-login/activities/resident-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UhXWk/0m",
    "block": "{\"symbols\":[],\"statements\":[[1,[25,\"details-search-header\",null,[[\"model\",\"variable\",\"value\"],[[20,[\"resident\"]],\"resident_id\",1]]],false],[0,\"\\n\"],[6,\"div\"],[9,\"class\",\"screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header details\"],[7],[0,\"\\n    \"],[6,\"div\"],[9,\"class\",\"row\"],[7],[0,\"\\n        \"],[6,\"div\"],[9,\"class\",\"col resident-admission\"],[7],[0,\"\\n            \"],[1,[25,\"resident-header\",null,[[\"model\",\"weightChartData\",\"moodChartData\",\"chartOptions\",\"moodChartOptions\"],[[20,[\"resident\"]],[20,[\"resident\",\"weightChartData\"]],[20,[\"resident\",\"moodChartData\"]],[20,[\"resident\",\"chartOptions\"]],[20,[\"resident\",\"moodChartOptions\"]]]]],false],[0,\"\\n            \"],[1,[25,\"details-header\",null,[[\"model\",\"active\"],[[20,[\"resident\"]],\"activities\"]]],false],[0,\"\\n\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"New Activity\"],[8],[0,\"\\n\\t\\t\\t\\t\"],[8],[0,\"\\n\\n\\t\\t\\t\\t\"],[4,\"resident-activity-edit\",null,[[\"model\",\"resident\",\"back\",\"saveActivity\"],[[20,[\"model\"]],[20,[\"resident\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveActivity\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/activities/resident-new.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/models/personal-care", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    personal_care_assistance: _emberData.default.attr('string'),
    independent: _emberData.default.attr('number'),
    oral_status: _emberData.default.attr('string'),
    oral_assistance: _emberData.default.attr('string'),
    bathing_status: _emberData.default.attr('string'),
    bathing_assistance: _emberData.default.attr('string'),
    arising_status: _emberData.default.attr('string'),
    arising_assistance: _emberData.default.attr('string'),
    dressing_status: _emberData.default.attr('string'),
    dressing_assistance: _emberData.default.attr('string'),
    shaving_status: _emberData.default.attr('string'),
    shaving_assistance: _emberData.default.attr('string'),
    toileting_day_status: _emberData.default.attr('string'),
    toileting_day_assistance: _emberData.default.attr('string'),
    toileting_night_status: _emberData.default.attr('string'),
    toileting_night_assistance: _emberData.default.attr('string'),
    shaving: _emberData.default.attr('number'),
    dentures: _emberData.default.attr('number'),
    smoker: _emberData.default.attr('number'),
    notes: _emberData.default.attr('string'),
    presentation: _emberData.default.attr('string'),
    important_rituals: _emberData.default.attr('string'),
    emotional_triggers: _emberData.default.attr('string'),
    food_drink: _emberData.default.attr('string'),
    plan_and_outcomes: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
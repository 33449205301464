define("careshare/models/adverse-event", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    resident: _emberData.default.attr('string'),
    date: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    time: _emberData.default.attr('time'),
    location: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    severity: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    reported_by: _emberData.default.attr('string'),
    gp: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    is_gp: Ember.computed('gp', function () {
      return this.get('gp') == 1;
    }),
    ra: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    is_ra: Ember.computed('ra', function () {
      return this.get('ra') == 1;
    }),
    ci: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    is_ci: Ember.computed('ci', function () {
      return this.get('ci') == 1;
    }),
    dc: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    is_dc: Ember.computed('dc', function () {
      return this.get('dc') == 1;
    }),
    staff_present: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    adverse_notes: _emberData.default.hasMany('adverse-event-note', {
      async: false,
      embedded: 'always',
      polymorphic: false
    }),
    adverse_users: _emberData.default.hasMany('adverse-event-user', {
      async: false,
      embedded: 'always',
      polymorphic: false
    })
  });

  _exports.default = _default;
});
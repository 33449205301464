define("careshare/templates/components/role-permissions-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "86VcIV58",
    "block": "{\"symbols\":[\"permission\",\"role\",\"role\"],\"statements\":[[6,\"table\"],[9,\"class\",\"table table-bordered\"],[7],[0,\"\\n\\t\"],[6,\"thead\"],[7],[0,\"\\n\\t\\t\"],[6,\"th\"],[7],[0,\"Permissions\"],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"roles\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,\"th\"],[7],[1,[19,3,[\"name\"]],false],[8],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\t\"],[8],[0,\"\\n\\t\"],[6,\"tbody\"],[7],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"permissions\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[6,\"tr\"],[7],[0,\"\\n\\t\\t\\t\\t\"],[6,\"td\"],[7],[1,[19,1,[\"description\"]],false],[8],[0,\"\\n\"],[4,\"each\",[[20,[\"model\",\"roles\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\\t\"],[6,\"td\"],[7],[0,\"\\n\\t\\t\\t\\t\\t\\t\"],[1,[25,\"permission-checkbox\",null,[[\"permission\",\"role\"],[[19,1,[]],[19,2,[]]]]],false],[0,\"\\n\\t\\t\\t\\t\\t\"],[8],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\t\\t\\t\"],[8],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[8],[0,\"\\n\"],[8],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/role-permissions-edit.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/helpers/can", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    session: Ember.inject.service('session'),
    compute: function compute(permissions
    /*, hash*/
    ) {
      permissions = Ember.isArray(permissions) ? permissions : [permissions];
      var currentUserPermissions = this.get('session.data.authenticated.permissions');
      var hasAccess = false;

      for (var i = 0; i <= permissions.length - 1; i++) {
        hasAccess = currentUserPermissions.includes(permissions[i]);
        break;
      }

      return hasAccess;
    }
  });

  _exports.default = _default;
});
define("careshare/helpers/age", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.age = age;
  _exports.default = void 0;

  function age(dob) {
    var startDate = new Date(dob.toString().substring(6) + "-" + dob.toString().substring(3, 5) + "-" + dob.toString().substring(0, 2));
    var endDate = new Date();
    return _moment.default.duration(endDate - startDate).years();
  }

  var _default = Ember.Helper.helper(age);

  _exports.default = _default;
});
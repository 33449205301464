define("careshare/controllers/after-login/adverse-events/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants')
  });

  _exports.default = _default;
});
define("careshare/components/unit-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service('store'),
    tagName: 'select',
    class: 'form-control units-select',
    units: Ember.computed(function () {
      return this.get('store').query('unit', {
        sortBy: 'name',
        sortDirection: 'ASC'
      });
    })
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/case-notes/index", ["exports", "moment", "ember-cli-pagination/remote/route-mixin", "careshare/config/environment"], function (_exports, _moment, _routeMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    page: 1,
    perPage: 10,
    queryParams: {
      resident_id: {
        refreshModel: true
      },
      note_type: {
        refreshModel: true
      },
      resident_name: {
        refreshModel: false
      },
      date_from: {
        refreshModel: true
      },
      date_to: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      params.expand = 'user';
      params.date_from = (0, _moment.default)(params.date_from, 'DD.MM.YYYY').format('YYYY-MM-DD');
      params.date_to = (0, _moment.default)(params.date_to, 'DD.MM.YYYY').format('YYYY-MM-DD');

      if (params.date_from > params.date_to) {
        this.controllerFor('after-login').set('dialogMessage', 'Start date should be less than End date!');
        Ember.$('.modal-window.error').removeClass('hidden');
        return false;
      }

      return this.findPaged('case-note', params);
    },
    afterModel: function afterModel(model) {
      var self = this;

      if (model.content) {
        var resident = model.content.query.resident_id;
        var type = model.content.query.note_type;

        if (resident && type && resident !== 'all') {
          return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes/current?id=' + resident + '&outcome_type=' + type, {
            method: 'GET',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            }
          })]).then(function (data) {
            if (data[0].outcomes[0] && data[0].outcomes[0].notes) {
              self.controllerFor('after-login.case-notes.index').set('current_outcome', data[0].outcomes[0]);
            }

            Ember.run.next(function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        } else {
          self.controllerFor('after-login.case-notes.index').set('current_outcome', '');
          Ember.run.next(function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        }
      }

      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden');
      });
    },
    generatePdf: function generatePdf(resident, noteType, dateFrom, dateTo) {
      var self = this;
      var requestOptions = {
        url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/case-notes/pdf?resident_id=' + resident + '&note_type=' + noteType + '&date_from=' + dateFrom + '&date_to=' + dateTo + '&user_id=' + self.get('session').get('data.authenticated.user_id'),
        dataType: 'JSON',
        method: 'PUT',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      };
      return Ember.$.ajax(requestOptions);
    },
    actions: {
      addUpdate: function addUpdate() {
        var resident = this.controllerFor('after-login.case-notes.index').get('resident_id');
        var noteType = this.controllerFor('after-login.case-notes.index').get('note_type');
        this.transitionTo('after-login.case-notes.new', {
          queryParams: {
            resident_id: resident,
            note_type: noteType
          }
        });
      },
      viewOutcome: function viewOutcome(res_id, section, name) {
        this.transitionTo('after-login.outcomes.index', {
          queryParams: {
            resident_id: res_id,
            outcome_type: section,
            resident_name: name
          }
        });
      },
      editNote: function editNote(id) {
        this.transitionTo('after-login.case-notes.edit', id);
      },
      applyFilter: function applyFilter() {
        var emptyNoteType = Ember.isEmpty(this.controllerFor('after-login.case-notes.index').get('note_type'));

        if (emptyNoteType) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!emptyNoteType) {
          this.refresh();
        }
      },
      createPdf: function createPdf() {
        var self = this;
        var resident = this.controllerFor('after-login.case-notes.index').get('resident_id');
        var noteType = this.controllerFor('after-login.case-notes.index').get('note_type') ? this.controllerFor('after-login.case-notes.index').get('note_type') : this.controllerFor('after-login.case-notes.index').get('default_note_type');
        var dateFrom = this.controllerFor('after-login.case-notes.index').get('date_from');
        var dateTo = this.controllerFor('after-login.case-notes.index').get('date_to');
        dateFrom = new Date(dateFrom.split(".").reverse().join("-")).getTime() / 1000;
        dateTo = new Date(dateTo.split(".").reverse().join("-")).getTime() / 1000;

        if (Ember.isEmpty(noteType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(noteType)) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });
          Ember.$.when(self.generatePdf(resident, noteType, dateFrom, dateTo)).done(function () {
            Ember.run.schedule('afterRender', function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
            self.controllerFor('after-login').set('dialogMessage', 'Document successfully saved to documents!');
            Ember.$('.modal-window.success').removeClass('hidden');
          });
        }
      },
      print: function print() {
        var self = this;
        var resident = this.controllerFor('after-login.case-notes.index').get('resident_name');
        var resident_id = this.controllerFor('after-login.case-notes.index').get('resident_id');
        var noteType = this.controllerFor('after-login.case-notes.index').get('note_type') ? this.controllerFor('after-login.case-notes.index').get('note_type') : this.controllerFor('after-login.case-notes.index').get('default_note_type');
        var date_from = this.controllerFor('after-login.case-notes.index').get('date_from');
        var date_to = this.controllerFor('after-login.case-notes.index').get('date_to');
        var dateFromReq = new Date(date_from.split(".").reverse().join("-")).getTime() / 1000;
        var dateToReq = new Date(date_to.split(".").reverse().join("-")).getTime() / 1000;

        if (Ember.isEmpty(noteType)) {
          Ember.$('#section').addClass('is-invalid');
        } else {
          Ember.$('#section').removeClass('is-invalid');
        }

        if (!Ember.isEmpty(noteType)) {
          return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/case-notes/print?resident_id=' + resident_id + '&note_type=' + noteType + '&date_from=' + dateFromReq + '&date_to=' + dateToReq, {
            method: 'GET',
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
            }
          })]).then(function (data) {
            self.controllerFor('after-login.case-notes.index').set('notesForPrint', data[0].notes);
            $('.forPrint').printThis({
              importCSS: true,
              loadCSS: "",
              header: "<h1>" + (noteType && noteType !== 'all' ? noteType : 'All Sections Case Notes ') + (resident && resident !== '' ? ' Case Notes for ' + resident : '') + " " + date_from + "-" + date_to + "</h1>"
            });
            Ember.run.next(function () {
              Ember.$('.loader-wrapper').addClass('hidden');
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/resident-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    type: 'all',
    placeholder: 'Select Resident',
    didInsertElement: function didInsertElement() {
      var self = this;

      if (!Ember.isEmpty(this.get('resident_type'))) {
        this.set('type', this.get('resident_type'));
      }

      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/resident-filter?type=' + self.get('type'), {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (residents) {
        self.set('residentList', []);
        residents.forEach(function (resident) {
          self.get('residentList').pushObject(resident);
        });
        var $residentSelect = self.$('.resident-select');
        Ember.run.schedule('afterRender', function () {
          $residentSelect.val(self.get('resident_id'));
          $residentSelect.change(function (e) {
            var residentId = Ember.$(this).val();
            var residentName = Ember.$(this).find('option:selected').text();

            if (!Ember.isEmpty(residentId)) {
              self.set('selected_id', residentId);
              self.sendAction('onSelect', residentId);
              self.set('resident_id', residentId);
              self.set('residentName', residentName);
            } else {
              self.set('selected_id', '');
              self.sendAction('onSelect', null);
              self.set('residentName', '');
              self.set('modelValue', 'all');
              self.set('resident_id', '');
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});
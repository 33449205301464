define("careshare/templates/after-login/residents/communication-eol-medical-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iOkvTNxj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"communication-eol-medical-details\",null,[[\"model\",\"errors\",\"original_communication_methods\",\"original_communication_languages\",\"original_communication_interactions\",\"original_communication_aids\",\"original_communication_impairments\",\"original_medical_conditions\",\"goTo\",\"back\",\"next\",\"save\"],[[20,[\"model\"]],[20,[\"errors\"]],[20,[\"original_communication_methods\"]],[20,[\"original_communication_languages\"]],[20,[\"original_communication_interactions\"]],[20,[\"original_communication_aids\"]],[20,[\"original_communication_impairments\"]],[20,[\"original_medical_conditions\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"next\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"save\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/communication-eol-medical-details.hbs"
    }
  });

  _exports.default = _default;
});
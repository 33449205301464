define("careshare/components/bs-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    attributeBindings: ['style'],
    style: 'border: none !important; background: transparent; padding: 0',
    isMobile: false,
    isMobileDevice: function isMobileDevice() {
      var isIOS = /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase());
      var isAndroid = /android/.test(navigator.userAgent.toLowerCase());
      return isIOS || isAndroid;
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      this.set('isMobile', this.isMobileDevice());

      if (!self.isMobile) {
        this.$("input").datetimepicker({
          format: 'HH:mm'
        }).datepicker("setDate", this.value);
        this.$('span.fa').click(function (e) {
          e.preventDefault();
          self.$("input").datetimepicker("show");
        });
      }
    }
  });

  _exports.default = _default;
});
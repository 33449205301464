define("careshare/routes/after-login/residents/dashboard", ["exports", "careshare/config/environment", "careshare/mixins/serialize-data", "careshare/mixins/set-belongs-to", "moment"], function (_exports, _environment, _serializeData, _setBelongsTo, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_serializeData.default, _setBelongsTo.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('resident', params.id, {
        backgroundReload: true
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controller.set('avatarsURL', _environment.default.host + '/uploads/avatars/');
    },
    afterModel: function afterModel(model) {
      var self = this;
      var formattedDate = (0, _moment.default)().format('YYYY-MM-DD');
      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/mobility-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/allergies-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/feed/resident?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contact-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/contact-family-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/news/latest', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), self.store.findRecord('user', self.get('session').get('data.authenticated.user_id')), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/nutrition-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/continence-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/monitorings?date_from=2013-03-30&date_to=' + formattedDate + '&id=15&&page=1&per_page=10&slug=pain&resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/communication-eol-medical-details?resident_id=' + model.id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        var mobilityData = data[2]['data'];
        model.set('weights', data[0]['weights']);
        model.set('weights_dates', data[0]['dates']);
        model.set('moods', data[1]['moods']);
        model.set('moods_dates', data[1]['dates']);
        self.serializeNestedRecordFor(model, 'mobility', mobilityData);
        model.set('assistance_transfers', mobilityData['assistance-transfers']);
        self.serializeNestedRecordsFor(model, 'allergies', data[3]['data']);
        model.set('feeds', data[4]);
        model.set('contact_details', data[5]['contact-detail']);
        model.set('contact_family_details', data[6]['contact-family-detail']); //model.set('latest_news', data[7]['news']);

        model.set('news', data[7]['news']);
        model.set('user', data[8]);
        model.set('nutrition', data[9].data.nutrition);
        model.set('continence', data[10].data['continence-details']);
        var positionMonitoringData = data[2].data['bedroom-assistance'];
        var hasPositionMonitoring = positionMonitoringData.some(function (item) {
          return item.item_description === "Position Monitoring - Day" || item.item_description === "Position Monitoring - Night";
        });
        model.set('position_monitoring', hasPositionMonitoring);
        model.set('pain_monitoring', data[11].monitorings.length > 0);
        model.set('observing', data[12].data['medical-conditions'].length > 0);

        if (model.get('user')) {
          if (model.get('user').get('last_news') != model.get('news').id) {
            model.set('s47', model.get('user').get('s47'));
            model.set('show_news', 'show');
            model.get('user').set('last_news', model.news.id);
            user.save();
          }
        }

        Ember.run.later('afterRender', function () {
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    deactivate: function deactivate() {
      if (this.currentModel.get('isNew')) {
        this.currentModel.deleteRecord();
      } else if (this.currentModel.get('isDirty')) {
        this.currentModel.rollback();
      }
    },
    actions: {
      saveResident: function saveResident() {
        if (Ember.$('.btn').hasClass('disabled')) {
          return false;
        }

        var resident = this.modelFor(this.routeName);
        var self = this;
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        resident.save().then(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.transitionTo('after-login.residents');
        }).catch(function () {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        var self = this;
        self.transitionTo('after-login.residents');
      },
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      },
      viewResidentFromObj: function viewResidentFromObj(res) {
        this.transitionTo('after-login.residents.dashboard', res.id);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/contacts/resident-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    contactTypeList: [],
    sourceListAll: [],
    additionalContacts: Ember.computed('contacts.[]', function () {
      var contacts = _.rest(this.get('contacts'));

      if (this.get('contacts').length > 0) {
        return contacts;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/messages/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    type: null,
    model: function model(params) {
      return this.store.findRecord('message', params.id, {
        adapterOptions: {
          expand: 'resident,from,to'
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      this._super.apply(this, arguments);

      var sessionService = this.get('session');
      var currentUserId = sessionService.get('data.authenticated.user_id');

      if (!model.get('is_read') && currentUserId !== model.get('from_id')) {
        model.setRead();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var currentUserId = this.get('session').get('data.authenticated.user_id');

      if (currentUserId === model.get('to_id')) {
        controller.set('type', 'inbox');
        controller.set('backToUrl', 'after-login.messages.inbox');
      } else {
        controller.set('type', 'sent');
        controller.set('backToUrl', 'after-login.messages.sent');
      }
    },
    actions: {
      openMessageModal: function openMessageModal(recipientId, messageModel) {
        var afterLoginController = this.controllerFor('after-login');
        var type = this.controller.get('type');
        afterLoginController.set('newMessageModal', true);

        if (type === 'inbox') {
          afterLoginController.set('messageModalTitle', 'Reply');
          afterLoginController.set('recipientId', recipientId);
        } else {
          afterLoginController.set('messageModalTitle', 'Forward');
          afterLoginController.set('messageSubject', messageModel.get('subject'));
          afterLoginController.set('messageBody', messageModel.get('message'));
          afterLoginController.set('isForwardMessage', true);
        }
      }
    }
  });

  _exports.default = _default;
});
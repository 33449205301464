define("careshare/routes/after-login/units/index", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    dialog: Ember.inject.service(),
    page: 1,
    perPage: 20,
    queryParams: {
      name: {
        refreshModel: true
      },
      description: {
        refreshModel: true
      },
      sortBy: {
        refreshModel: true
      },
      sortDirection: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.findPaged('unit', params);
    },
    actions: {
      add: function add() {
        this.transitionTo('after-login.units.new');
      },
      edit: function edit(id) {
        this.transitionTo('after-login.units.edit', id);
      },
      delete: function _delete(id) {
        var _this = this;

        var promise = this.get('dialog').confirm(Ember.HTMLBars.template({
          "id": "voifl9Q9",
          "block": "{\"symbols\":[],\"statements\":[[0,\"Are you sure?\"]],\"hasEval\":false}",
          "meta": {}
        }));
        var self = this;
        promise.then(function () {
          Ember.run.later('afterRender', function () {
            Ember.$('.loader-wrapper').removeClass('hidden');
          });

          _this.get('store').findRecord('unit', id, {
            backgroundReload: false
          }).then(function (unit) {
            unit.destroyRecord().then(function () {
              self.refresh();
              Ember.run.later('afterRender', function () {
                Ember.$('.loader-wrapper').addClass('hidden');
              });
            });
          });
        });
        promise.catch(function () {});
      }
    }
  });

  _exports.default = _default;
});
define("careshare/routes/after-login/activities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    model: function model(params) {
      return this.store.findRecord('activity', params.id, {
        adapterOptions: {
          expand: 'activityResidents'
        }
      });
    },
    createResidentIdentifier: function createResidentIdentifier(resident) {
      // Combine the resident's first name, last name, and birthdate to form a unique identifier
      return "".concat(resident.get('firstname'), "-").concat(resident.get('lastname'), "}");
    },
    beforeModel: function beforeModel() {
      Ember.run.later('afterRender', function () {
        $(".ui-tooltip").addClass('hidden');
      });
    },
    afterModel: function afterModel() {
      Ember.run.later('afterRender', function () {
        Ember.$('.mood-label').find('i').removeClass('fa').addClass('fas');
      });
      Ember.run.next(function () {
        Ember.$('.mood-label').find('i').removeClass('fa').addClass('fas');
      });
    },
    actions: {
      saveActivity: function saveActivity() {
        var activity = this.modelFor(this.routeName);
        var self = this;
        var residents = activity.get('activityResidents');
        var uniqueResidents = [];
        var identifiers = new Set();
        residents.forEach(function (resident) {
          var id = resident.get('resident_id');
          var identifier = id;
          console.log(id);

          if (!identifiers.has(identifier)) {
            identifiers.add(identifier);
            uniqueResidents.push(resident);
          } else {
            // Optionally handle the logic for what happens when a duplicate is found
            console.log('Duplicate resident found:', resident);
          }
        }); // Set the unique residents back to the activity

        activity.set('activityResidents', uniqueResidents);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
          Ember.$('.mood-label').find('i').removeClass('fa').addClass('fas');
        });
        activity.save().then(function () {
          activity.set('activityResidents', Array());
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.activities');
        }).catch(function (error) {
          console.log(error);
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      back: function back() {
        this.transitionTo('after-login.activities');
      },
      warning: function warning(text) {
        this.controllerFor('after-login').set('dialogMessage', text);
        Ember.$('.modal-window.error').removeClass('hidden');
      }
    }
  });

  _exports.default = _default;
});
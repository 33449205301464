define("careshare/routes/after-login/residents/list", ["exports", "ember-cli-pagination/remote/route-mixin"], function (_exports, _routeMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeMixin.default, {
    session: Ember.inject.service('session'),
    perPage: 20,
    queryParams: {
      sortBy: {
        refreshModel: true
      },
      direction: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.next(function () {
        var menuItems = Ember.$('ul.sublink-ul').css('display', 'none');
        menuItems.each(function (item) {
          $(this).css('display', 'none');
        });
        Ember.$('.loader-wrapper').removeClass('hidden');
      });
    },
    model: function model(params) {
      return this.findPaged('resident', params);
    },
    afterModel: function afterModel(model) {
      Ember.run.next(function () {
        Ember.$('.loader-wrapper').addClass('hidden'); // Tooltip cleanup and reinitialization

        if (Ember.$(document).tooltip()) {
          Ember.$(document).tooltip('destroy');
        }

        Ember.$(document).tooltip({
          content: function content() {
            return $(this).prop('title');
          },
          position: {
            my: "center bottom",
            at: "center top"
          }
        });
      });
    },
    actions: {
      removeResident: function removeResident(id) {
        this.get('store').findRecord('resident', id, {
          backgroundReload: false
        }).then(function (resident) {
          resident.destroyRecord();
        });
      },
      addResident: function addResident() {
        this.transitionTo('after-login.residents.new');
      },
      editResident: function editResident(id) {
        this.transitionTo('after-login.residents.admission-details', id);
      },
      dischargeResident: function dischargeResident(id) {
        this.transitionTo('after-login.residents.discharge', id);
      },
      viewResident: function viewResident(id) {
        this.transitionTo('after-login.residents.dashboard', id);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/details-search-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: 'div',
    residents: Ember.computed(function () {
      return this.get('store').query('resident', {
        sortBy: 'firstname',
        sortDirection: 'ASC'
      });
    }),
    actions: {
      viewResidentFromObj: function viewResidentFromObj(res) {
        var variable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

        if (variable && value) {
          var params = new Object();
          params[variable] = value; // horrible I know

          if (variable == "resident_id") params[variable] = res.id;
          this.get('router').transitionTo(null, res.id, {
            queryParams: params
          });
        } else {
          this.get('router').transitionTo(null, res.id);
        }
      }
    }
  });

  _exports.default = _default;
});
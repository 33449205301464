define("careshare/models/continence-details", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    resident_id: _emberData.default.attr('number'),
    toileting: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    stoma: _emberData.default.attr('number'),
    catheter: _emberData.default.attr('number'),
    urine_continent_daytime: _emberData.default.attr('string'),
    faeces_continent_daytime: _emberData.default.attr('string'),
    urine_continent_nightime: _emberData.default.attr('string'),
    faeces_continent_nightime: _emberData.default.attr('string'),
    urine_continent_daytime_required: _emberData.default.attr('number'),
    faeces_continent_daytime_required: _emberData.default.attr('number'),
    urine_continent_nightime_required: _emberData.default.attr('number'),
    faeces_continent_nightime_required: _emberData.default.attr('number'),
    plan_and_outcomes: _emberData.default.attr('string'),
    urine_monitoring: _emberData.default.attr('string'),
    bowel_monitoring: _emberData.default.attr('string')
  });

  _exports.default = _default;
});
define("careshare/models/appointment-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    time: _emberData.default.attr('time'),
    location: _emberData.default.attr('string', {
      defaultValue: 'Internal'
    }),
    resident_id: _emberData.default.attr('number'),
    resident: _emberData.default.attr('string'),
    reason: _emberData.default.attr('string', {
      defaultValue: 'Planned'
    }),
    item_notes: _emberData.default.hasMany('appointment-item-note', {
      async: false,
      embedded: 'always',
      polymorphic: false
    })
  });

  _exports.default = _default;
});
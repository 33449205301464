define("careshare/templates/after-login/residents/communication-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uvYqHzrm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,\"eq\",[[20,[\"view\"]],\"1\"],null]],null,{\"statements\":[[0,\"        \"],[1,[25,\"communication-details-view\",null,[[\"model\",\"errors\",\"original_communication_methods\",\"original_communication_languages\",\"original_communication_interactions\",\"original_communication_aids\",\"original_communication_impairments\",\"goTo\",\"modelChanged\"],[[20,[\"model\"]],[20,[\"errors\"]],[20,[\"original_communication_methods\"]],[20,[\"original_communication_languages\"]],[20,[\"original_communication_interactions\"]],[20,[\"original_communication_aids\"]],[20,[\"original_communication_impairments\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],1]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[25,\"communication-details\",null,[[\"model\",\"errors\",\"original_communication_methods\",\"original_communication_languages\",\"original_communication_interactions\",\"original_communication_aids\",\"original_communication_impairments\",\"goTo\",\"back\",\"commit\",\"next\",\"save\"],[[20,[\"model\"]],[20,[\"errors\"]],[20,[\"original_communication_methods\"]],[20,[\"original_communication_languages\"]],[20,[\"original_communication_interactions\"]],[20,[\"original_communication_aids\"]],[20,[\"original_communication_impairments\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"goTo\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"commit\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"next\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/residents/communication-details.hbs"
    }
  });

  _exports.default = _default;
});
define("careshare/adapters/message", ["exports", "careshare/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function pathForType() {
      return 'messages';
    },
    urlForFindRecord: function urlForFindRecord(id, type, snapshot) {
      var url = this._super.apply(this, arguments);

      if (snapshot.adapterOptions) {
        var queryParams = Ember.$.param(snapshot.adapterOptions);
        return "".concat(url, "?").concat(queryParams);
      }

      return url;
    },
    setRead: function setRead(messageId) {
      var url = this.buildURL(this.pathForType()) + '/set-read';
      return this.ajax(url, "POST", {
        data: {
          id: messageId
        }
      });
    }
  });

  _exports.default = _default;
});
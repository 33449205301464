define("careshare/components/appointment-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tagName: 'div',
    classNames: 'screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header',
    services: ["Audiologist", "Community Team", "Consultant", "Dentist", "Dietician", "Doctor", "Hairdresser", "Hospital", "Mental Health Officer", "Nutritionist", "Occupational Health", "Opticians", "Physiotherapist", "Podiatrist", "Public Guardian Review", "Review", "Social Worker", "Specialist Nurse", "Speech Therapist"],
    init: function init() {
      this._super.apply(this, arguments);
    },
    createItem: function createItem(model) {
      var item = this.get('store').createRecord('appointment-item');
      item.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      var note = this.get('store').createRecord('appointment-item-note');
      note.set('id', Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2));
      item.get('item_notes').pushObject(note);
      model.get('appointment_items').pushObject(item);
    },
    actions: {
      addItem: function addItem(model) {
        this.createItem(model);
        Ember.$('.scroll-cell').animate({
          scrollTop: $('.scroll-cell').prop('scrollHeight')
        }, 1000);
      },
      removeItem: function removeItem(model, item) {
        var _this = this;

        item.get('item_notes').forEach(function (note) {
          _this.get('store').deleteRecord(note);
        });
        model.get('appointment_items').removeObject(item);
        this.get('store').deleteRecord(item);
      },
      addNote: function addNote(item) {
        var note = this.get('store').createRecord('appointment-item-note');
        var newId = Math.random().toString(32).slice(2) + Date.now() + Math.random().toString(32).slice(2);
        note.set('id', newId);
        item.get('item_notes').pushObject(note);
        Ember.run.next(function () {
          Ember.$('.scroll-cell').animate({
            scrollTop: Ember.$("#" + newId).offset().top
          }, 1000);
        });
      },
      removeNote: function removeNote(item, note) {
        item.get('item_notes').removeObject(note);
        this.store.deleteRecord(note);
      }
    }
  });

  _exports.default = _default;
});
define("careshare/components/professional-select", ["exports", "careshare/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    className: 'form-control',
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      var self = this;
      Ember.$.ajaxSetup({
        headers: {
          'Authorization': 'Bearer ' + this.get('session').get('data.authenticated.access_token')
        }
      });
      this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/professional-filter', {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }).then(function (professionals) {
        self.set('professionalList', []);
        professionals.forEach(function (professional) {
          self.get('professionalList').pushObject(professional);
        });
        Ember.run.schedule('afterRender', function () {
          Ember.$('#professional').val(self.get('professionalName'));
          Ember.$('#professional').change(function (e) {
            if (!Ember.isEmpty($(this).val())) {
              self.set('professionalName', $(this).find('option:selected').text());
            } else {
              self.set('professionalName', '');
            }
          });
          Ember.$('#professional').chosen({
            width: '100%',
            placeholder_text_single: 'Select Professional',
            allow_single_deselect: true,
            create_option: self.get('addOption') ? true : false,
            persistent_create_option: true,
            skip_no_results: true
          });
        });
      });
    }
  });

  _exports.default = _default;
});
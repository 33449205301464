define("careshare/components/fancy-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['time-picker'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$('input').clockTimePicker({
        colors: {
          selectorColor: '#f06292'
        }
      });
      this.$('input').on('change', function (event) {
        Ember.run(function () {
          _this.set('value', event.target.value);

          if (_this.onChange) {
            _this.onChange(event.target.value);
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('input').clockTimePicker('dispose');
    }
  });

  _exports.default = _default;
});
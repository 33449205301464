define("careshare/routes/after-login/outcomes/resident-new", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "moment", "careshare/config/environment", "careshare/mixins/graph"], function (_exports, _toConsumableArray2, _moment, _environment, _graph) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_graph.default, {
    session: Ember.inject.service('session'),
    ajax: Ember.inject.service(),
    queryParams: {
      resident_id: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      Ember.run.schedule('afterRender', function () {
        var textAreas = Ember.$('.report-textarea');
        var dates = Ember.$('.date-picker');
        var selects = Ember.$('select.form-control');
        var residents = Ember.$('.ui-autocomplete-input');
        textAreas.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).text())) {
              $(this).removeClass('is-invalid');
            }
          });
        });
        dates.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).parent().removeClass('is-invalid');
            }
          });
        });
        selects.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
        });
        residents.each(function (index, el) {
          $(this).on("focusout", function (e) {
            if (!Ember.isEmpty($(this).val())) {
              $(this).removeClass('is-invalid');
            }
          });
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var _this = this;

      Ember.run(function () {
        return _this.get('store').unloadAll('outcome');
      });
      var store = this.store;
      var record = store.createRecord('outcome', {
        user_id: this.get('session.data.authenticated.user_id')
      });
      this.controllerFor('after-login.outcomes.resident-new').set('outcomes', []);
      this.controllerFor('after-login.outcomes.resident-new').get('outcomes').pushObject(record);
      var self = this;
      var resObj = this.store.findRecord('resident', transition.queryParams.resident_id, {
        backgroundReload: false
      });

      if (transition.queryParams.resident_id) {
        this.controllerFor('after-login.outcomes.resident-new').get('outcomes')[0].set('resident_id', transition.queryParams.resident_id);
      }

      if (transition.queryParams.outcome_type) {
        this.controllerFor('after-login.outcomes.resident-new').get('outcomes')[0].set('outcome_type', transition.queryParams.outcome_type);
      }

      return Ember.RSVP.Promise.all([this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/weights/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      }), this.get('ajax').request((_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/moods/raw?resident_id=' + transition.queryParams.resident_id, {
        method: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
        }
      })]).then(function (data) {
        resObj.weightChartData = self.weightData(data[0]['weights'], data[0]['dates']);
        resObj.moodChartData = self.moodData(data[1]['moods'], data[1]['dates']);
        self.controllerFor('after-login.outcomes.resident-new').set('resident', resObj);
        Ember.run.next(function () {
          Ember.$('.loader-wrapper').addClass('hidden');
          Ember.$('.avatar-smilie').find('i').removeClass('fa').addClass('fas');
        });
      });
    },
    actions: {
      cancel: function cancel(resident) {
        this.transitionTo('after-login.outcomes.resident-index', resident, {
          queryParams: {
            resident_id: resident
          }
        });
      },
      saveData: function saveData(resident) {
        var retTransition = Ember.isEmpty(localStorage.getItem('previousOutcomeTransition')) ? 'outcomes' : localStorage.getItem('previousOutcomeTransition');
        var self = this;
        var outcomeModels = this.controllerFor('after-login.outcomes.resident-new').get('outcomes');
        var formatedRequestData = JSON.stringify(outcomeModels);
        var requestOptions = {
          url: (_environment.default.host !== undefined ? _environment.default.host : this.get('host')) + '/v1/outcomes',
          dataType: 'JSON',
          method: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + self.get('session').get('data.authenticated.access_token'));
          },
          data: formatedRequestData
        };
        var saveDetails = Ember.$.ajax(requestOptions);
        Ember.run.schedule('afterRender', function () {
          Ember.$('.loader-wrapper').removeClass('hidden');
        });
        Ember.$.when(saveDetails).done(function (response) {
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
          self.controllerFor('after-login').set('dialogMessage', 'Successfully saved!');
          self.controllerFor('after-login.outcomes.index').set('outcome_type', '');
          Ember.$('.modal-window.success').removeClass('hidden');
          self.transitionTo('after-login.outcomes.resident-index', resident, {
            queryParams: {
              resident_id: resident
            }
          });
        }).catch(function (response) {
          for (var i = 0; i < outcomeModels.length; i++) {
            var errors = response.responseJSON[i].errors;

            outcomeModels[i]._internalModel.clearErrorMessages();

            if (errors) {
              outcomeModels[i]._internalModel.adapterDidInvalidate(errors);
            }
          }

          self.controllerFor('after-login.outcomes.resident-new').set('outcomes', (0, _toConsumableArray2.default)(outcomeModels));
          Ember.run.schedule('afterRender', function () {
            Ember.$('.loader-wrapper').addClass('hidden');
          });
        });
      },
      addRecord: function addRecord() {
        var self = this;

        var firstAddedOutcome = self.store.peekAll('outcome').content[0]._attributes;

        var firstAddedResidentId = firstAddedOutcome.resident_id;
        var firstAddedResident = firstAddedOutcome.resident;
        var firstAddedDate = firstAddedOutcome.date;

        if (Ember.isEmpty(firstAddedDate)) {
          firstAddedDate = (0, _moment.default)().format("DD.MM.YYYY");
        }

        var store = this.store;
        var record = store.createRecord('outcome', {
          user_id: self.get('session.data.authenticated.user_id'),
          resident_id: firstAddedResidentId,
          resident: firstAddedResident,
          date: firstAddedDate
        });
        this.controllerFor('after-login.outcomes.resident-new').get('outcomes').pushObject(record);
        Ember.$('.form-scroll').animate({
          scrollTop: $('.form-scroll').prop('scrollHeight')
        }, 1000);
      },
      removeRecord: function removeRecord(outcome) {
        this.controllerFor('after-login.outcomes.resident-new').get('outcomes').removeObject(outcome);
      }
    }
  });

  _exports.default = _default;
});
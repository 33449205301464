define("careshare/components/user-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JobListItem = Ember.Object.extend({
    item_description: null,
    item_type: null
  });

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header new-user',
    selectedInventoryItems: Ember.A(),
    inventoryItems: [JobListItem.create({
      item_description: "Activities Co-ordinator",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Admin Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Administrator",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Care Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Carer",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Catering Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Chef/Cook",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Clinical Lead",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Clinical Services Manager",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Deputy Manager",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Domiciliary Care Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Healthcare Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Housekeeper",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Kitchen Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Maintenance",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Manager",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Nursing Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Receptionist",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Registered General Nurse",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Registered Nurse",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Secretary",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Senior Care Assistant",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Senior Care Practioner",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Staff Nurse",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Unit Manager",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Wellbeing Co-ordinator",
      item_type: "predefined"
    }), JobListItem.create({
      item_description: "Other",
      item_type: "predefined"
    })],
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      selectValue: function selectValue(gender) {
        this.get('model').set(Ember.$(event.target).attr('model-attr'), gender);
      },
      updateInventory: function updateInventory(newSelection, value, operation) {
        if (operation === 'removed') {
          var deletedRecord = this.get('model').get('inventory').findBy('item_description', value);
          this.get('model').get('inventory').removeObject(deletedRecord);
          this.get('store').deleteRecord(deletedRecord);
        } else {
          var inventoryItem = this.get('store').createRecord('inventory', {
            item_description: value,
            item_type: "predefined"
          });
          this.get('model').get('inventory').pushObject(inventoryItem);
        }
      }
    }
  });

  _exports.default = _default;
});
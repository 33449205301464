define("careshare/mixins/serialize-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serializeNestedRecordsFor: function serializeNestedRecordsFor(resident, modelFor, data) {
      var payload = {};
      var property = Ember.String.underscore(modelFor);
      payload[modelFor] = data[modelFor];
      var modelClass = this.store.modelFor(modelFor);
      var serializer = this.store.serializerFor(modelFor);
      var json = serializer.normalizeQueryResponse(this.store, modelClass, payload, null, 'query');
      var records = this.store.push(json);
      resident.set(property, records);
    },
    serializeNestedRecordFor: function serializeNestedRecordFor(resident, modelFor, data) {
      var modelClass = this.store.modelFor(modelFor);
      var property = Ember.String.underscore(modelFor);
      var serializer = this.store.serializerFor(modelFor);
      var json = serializer.normalizeSingleResponse(this.store, modelClass, data[modelFor], data[modelFor]['id']);
      var record = this.store.push(json);
      resident.set(property, record);
    }
  });

  _exports.default = _default;
});
define("careshare/components/add-monitoring-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedType: null,
    formData: Ember.computed(function () {
      return {
        abbeyPainScale: {}
      };
    }),
    slugChanged: Ember.observer('slug', function () {
      this.send('resetFormData');
    }),
    actions: {
      resetFormData: function resetFormData() {
        this.set('formData', {
          date: '',
          abbeyPainScale: {}
        });
      },
      submitForm: function submitForm() {
        var _this = this;

        // Collect form data and pass it to the parent component
        var formData = this.get('formFields').reduce(function (data, field) {
          data[field.name] = _this.get("formData.".concat(field.name));
          return data;
        }, {});

        if (this.get('slug') === 'pain') {
          formData.abbeyPainScale = {
            vocalisation: this.get('formData.abbeyPainScale.vocalisation'),
            facial_expression: this.get('formData.abbeyPainScale.facial_expression'),
            body_language: this.get('formData.abbeyPainScale.body_language'),
            behavioural_change: this.get('formData.abbeyPainScale.behavioural_change'),
            physiological_change: this.get('formData.abbeyPainScale.physiological_change'),
            physical_changes: this.get('formData.abbeyPainScale.physical_changes')
          }; // Calculate total pain score

          formData.value = Object.values(formData.abbeyPainScale).reduce(function (sum, val) {
            return sum + parseInt(val, 10);
          }, 0);
        }

        this.sendAction('onSubmit', formData);
        this.sendAction('resetType');
      },
      cancelForm: function cancelForm() {
        this.sendAction('onCancel');
      },
      updateField: function updateField(fieldName, value) {
        this.set("formData.".concat(fieldName), value);
      },
      selectType: function selectType(type) {
        this.set('selectedType', type);
        this.sendAction('selectType', type);
      }
    }
  });

  _exports.default = _default;
});
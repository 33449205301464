define("careshare/templates/components/drag-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3iyv08WI",
    "block": "{\"symbols\":[],\"statements\":[[6,\"li\"],[9,\"class\",\"draggable\"],[10,\"style\",[26,[\"left: calc(\",[18,\"left\"],\"px - 1vw); top: calc(\",[18,\"top\"],\"px - 1vw);\"]]],[7],[1,[18,\"letter\"],false],[0,\" (\"],[1,[18,\"x\"],false],[0,\",\"],[1,[18,\"y\"],false],[0,\")\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/drag-box.hbs"
    }
  });

  _exports.default = _default;
});
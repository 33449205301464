define("careshare/adapters/resident", ["exports", "ember-data", "careshare/adapters/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var InvalidError = _emberData.default.InvalidError;

  var _default = _application.default.extend({// updateRecord: function(store, type, snapshot) {
    //     return {id: snapshot.id};
    // }
  });

  _exports.default = _default;
});
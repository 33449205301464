define("careshare/helpers/format-hour", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatHour = formatHour;

  function formatHour(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        hour = _ref2[0];

    return hour < 10 ? "0".concat(hour) : "".concat(hour);
  }

  var _default = Ember.Helper.helper(formatHour);

  _exports.default = _default;
});
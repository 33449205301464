define("careshare/controllers/after-login/activities/resident-edit", ["exports", "underscore"], function (_exports, _underscore) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    constants: Ember.inject.service('constants'),
    queryParams: ["resident_id"],
    resident_id: '',
    additionalWeights: Ember.computed('weights.[]', function () {
      var weights = _underscore.default.rest(this.get('weights'));

      if (this.get('weights').length > 0) {
        return weights;
      } else {
        return Ember.A();
      }
    })
  });

  _exports.default = _default;
});
define("careshare/controllers/after-login/residents/dashboard", ["exports", "careshare/mixins/set-belongs-to"], function (_exports, _setBelongsTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    staffs: Ember.computed(function () {
      return this.get('store').query('user', {
        sortBy: 'firstname',
        sortDirection: 'ASC'
      });
    }),
    residents: Ember.computed(function () {
      return this.get('store').query('resident', {
        sortBy: 'firstname',
        sortDirection: 'ASC'
      });
    }),
    actions: {
      uploadFile: function uploadFile(event) {
        var self = this;
        var reader = new FileReader();
        var file = event.target.files[0];
        var imageData;

        reader.onload = function () {
          imageData = reader.result;
          self.set('image', imageData);
        };

        if (file) {
          reader.readAsDataURL(file);
        }
      }
    }
  });

  _exports.default = _default;
});
define("careshare/templates/after-login/activities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rmzY7M5S",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[9,\"class\",\"screen-custom-size white screen-custom-size_inc-footer screen-custom-size_inc-header\"],[7],[0,\"\\n\\t\"],[6,\"div\"],[9,\"class\",\"section-header\"],[7],[0,\"\\n\\t\\t\"],[6,\"p\"],[9,\"class\",\"section-title section-title_inline\"],[7],[0,\"Activity\"],[8],[0,\"\\n\\t\\t\"],[6,\"div\"],[9,\"class\",\"header-button\"],[7],[0,\"\\n\\t\\t\\t\"],[6,\"a\"],[9,\"href\",\"#\"],[9,\"title\",\"Not available in test mode\"],[7],[6,\"i\"],[9,\"class\",\"fa fa-print fa-fw\"],[9,\"aria-hidden\",\"true\"],[7],[8],[8],[0,\"\\n\\t\\t\"],[8],[0,\"\\n\\t\"],[8],[0,\"\\n\\t\"],[4,\"activity-edit\",null,[[\"model\",\"warning\",\"back\",\"saveActivity\"],[[20,[\"model\"]],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"warning\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"back\"],null],[25,\"action\",[[19,0,[]],[20,[\"send\"]],\"saveActivity\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/after-login/activities/edit.hbs"
    }
  });

  _exports.default = _default;
});
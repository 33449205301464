define("careshare/services/monitoring-readings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getReadingsByType: function getReadingsByType(typeSlug, _ref) {
      var dateFrom = _ref.dateFrom,
          dateTo = _ref.dateTo,
          residentId = _ref.residentId;
      return this.store.query('monitoring-reading', {
        slug: typeSlug,
        date_from: dateFrom,
        date_to: dateTo,
        resident_id: residentId
      });
    }
  });

  _exports.default = _default;
});
define("careshare/models/infection", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    date_notified: _emberData.default.attr('date-format'),
    date_start: _emberData.default.attr('date-format', {
      defaultValue: (0, _moment.default)().format("DD.MM.YYYY")
    }),
    date_end: _emberData.default.attr('date-format'),
    notified: _emberData.default.attr('string'),
    recovered_cnt: _emberData.default.attr('number'),
    deceased_cnt: _emberData.default.attr('number'),
    infectionResidents: _emberData.default.hasMany('infection-resident', {
      async: false,
      embedded: 'always',
      polymorphic: false
    })
  });

  _exports.default = _default;
});
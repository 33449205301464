define("careshare/components/case-note-edit", ["exports", "moment", "careshare/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: 'div',
    classNames: 'screen-custom-size screen-custom-size_inc-footer screen-custom-size_inc-header',
    residentList: Ember.A(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {},
    actions: {}
  });

  _exports.default = _default;
});
define("careshare/templates/components/tooltip-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "seLLiVN7",
    "block": "{\"symbols\":[],\"statements\":[[1,[18,\"label\"],false],[0,\"\\n\"],[6,\"span\"],[9,\"class\",\"tooltip\"],[7],[1,[18,\"tooltip\"],false],[8]],\"hasEval\":false}",
    "meta": {
      "moduleName": "careshare/templates/components/tooltip-label.hbs"
    }
  });

  _exports.default = _default;
});